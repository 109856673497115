import {Card, Col, Form, Input, Row, Select} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {QuestionFormContext} from "../QuestionFormContext";
import RAQAClient from "../../../network/api/RaqaClient";

const {Option} = Select;

const QuestionEditorCatalogsAndCategories = () => {

    // Context API
    const {reduxCategories, reduxCatalogs, availableCatalogs, availableCategories, setAvailableCatalogs, setAvailableCategories} = useContext(QuestionFormContext);

    useEffect(() => {
        if (reduxCategories !== undefined) {
            const selectOptions = [];
            reduxCategories.forEach(
                element => {
                    if(element !== undefined && element !==null) {
                        selectOptions.push(
                            <Select.Option key={element} value={element}>
                                {element}
                            </Select.Option>)
                    }
                }
            );
            setAvailableCategories(selectOptions);
        }

        if (reduxCatalogs !== undefined) {
            const selectOptions = [];
            selectOptions.push(<Option key="DEFAULT_CATALOG" value="DEFAULT_CATALOG">
                Allgemeiner Fragenkatalog
            </Option>);

            reduxCatalogs.forEach(element => {
                selectOptions.push(<Option key={element.uuid} value={element.uuid}>
                    {element.title}
                </Option>)
            })
            setAvailableCatalogs(selectOptions);
        }

    }, [reduxCategories, reduxCatalogs]);

    return (
        <Row
            justify="start"
            align="middle"
            gutter={[28, 24]}
            className="todos-container"
            style={{marginTop:"25px"}}
        >
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}}>
            <Card title="Kataloge und Kategorien" style={{borderRadius: 15}}
                  headStyle={{
                      backgroundColor: 'rgba(245, 245, 245, 1)',
                      borderTopLeftRadius: 15,
                      borderTopRightRadius: 15
                  }}
                  bodyStyle={{backgroundColor: 'rgba(255, 255, 255, 0)', border: 1}}
            >

                {/* Select for the different catalogs */}
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}}>
                    <Form.Item
                        label="Frage in folgende Kataloge aufnehmen"
                        name="catalogs"
                        id="catalogs"
                    >
                        <Select
                            mode="multiple"
                            size={"large"}
                            placeholder="Kataloge wählen"
                            style={{width: '100%'}}
                        >
                            {availableCatalogs}
                        </Select>
                    </Form.Item>
                </Col>

                {/* Select for the different categories */}
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}}>
                    <Form.Item
                        label="Zugeordnete Kategorien"
                        name="categories"
                        id="categories"
                    >
                        <Select
                            mode="tags"
                            size={"large"}
                            placeholder="Kategorie wählen"
                            style={{width: '100%'}}
                        >
                            {availableCategories}
                        </Select>
                    </Form.Item>
                </Col>
            </Card>
        </Col>
        </Row>

    )
}

export default QuestionEditorCatalogsAndCategories;
