import QuestionList from "../../pages/admin/question/question.list";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useQuery} from "react-query";
import RAQAClient from "../../network/api/RaqaClient";
import {CheckOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {
    Button, Card,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    PageHeader,
    Popconfirm,
    Row, Select,
    Space,
    Spin,
    Table,
    Tag,
    Typography
} from "antd";
import QuestionView from "./QuestionView";
import {history} from "../../utils/history";
import {Option} from "antd/es/mentions";
import UpdateVisibilityIcon from "../other/UpdateIcon";

const GeneralQuestionList = ({props}) => {

    const authRole = useSelector(state => state.auth?.user?.user?.role);

    const [tableData, setTableData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = React.useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);

    // Search & Category Filtering
    const [searchTerm, setSearchTerm] = React.useState("")
    const [filteredCategories, setFilteredCategories] = useState([])
    //const [filteredCategoryQuestions, setFilteredCategoryQuestions] = useState([])
    const [availableCategories, setAvailableCategories] = useState([])

    // useState for display modal dialog (to many answers for single dialog)
    const [isModalVisible, setIsModalVisible] = useState(false);

    // Redux State
    const reduxCatalogs = useSelector(state => state.questions?.catalogs);
    const reduxCategories = useSelector(state => state.questions?.categories);

    // Catalogs and Categories for Table Filtering
    const [catalogListForFilter, setCatalogsForFilter] = useState([]);


    // Search Form
    const [form] = Form.useForm();

    /// New question data (or error) received
    const onQuestionsSettled = (data, error) => {
        console.log('onQuestionsSettled: ' + data);
        setTableData(data);
    };

    const {
        data: questions,
        refetch,
        isLoading: isFetching,
    }
        = useQuery(['questions', currentPage], () =>
            fetchGeneralQuestions(currentPage, searchTerm, filteredCategories), {
            keepPreviousData: true,
            onSettled: (data, error) => onQuestionsSettled(data, error)
        }
    )


    // Refetch data when values change
    useEffect(() => {
       // refetch();
    }, [currentPage, pageSize]);

    useEffect(() => {

        // Get all catalogs from the user
        let catalogList = reduxCatalogs
            .map(catalog => {
                return {
                    text: catalog.title,
                    value: catalog.uuid,
                    key: catalog.uuid,
                }
            },[])
        setCatalogsForFilter(catalogList);


        if (reduxCategories !== undefined) {
            const selectOptions = [];
            reduxCategories.forEach(
                element => selectOptions.push(
                    <Select.Option key={element} value={element}>
                        {element}
                    </Select.Option>)
            );
            setAvailableCategories(selectOptions);
        }

    }, [reduxCatalogs, reduxCategories])

    const fetchGeneralQuestions = async (pageNumber, searchTerm, filteredCategories) => {

        setCurrentPage(pageNumber);
        console.log('fetchGeneralQuestions pageNumber: ' + pageNumber);
        console.log('fetchGeneralQuestions searchString: ' + searchTerm);
        console.log('fetchGeneralQuestions filteredCategories: ' + filteredCategories);

        return RAQAClient.listGeneralCatalogQuestions(pageNumber, searchTerm, pageSize, filteredCategories).then(response => {

            console.log('General Questions received: ' + response.data);

            const remoteQuestions = response.data.questions.map(q => {
                const question = {...q};
                question.key = q.uuid;

                question.catalogNames = [];

                question.catalogUuids.forEach(uuid => {
                    const catalog = reduxCatalogs.find(x => x.uuid === uuid)
                    if (catalog !== undefined) {
                        question.catalogNames.push(catalog.title);
                    }
                });
                return question;
            });

            setPageSize(() => response.data.pageSize);
            setCurrentPage(() => response.data.page);
            setTotalPages(() => response.data.totalPages);
            setTotalItems(() => response.data.totalItems);

            //console.log("Page Size:", response.data.pageSize)
            //console.log("Current Page:", response.data.page)
            //console.log("Total Pages:", response.data.totalPages)
            //console.log("Total Items:", response.data.totalItems)
            //console.log("Remote general questions: ", remoteQuestions)
            setTableData(remoteQuestions);

            return remoteQuestions;

        });
    };


    // OK Click Handler for  single answer mode alert is display
    // Modal is displayed when more than one answer in single answer mode is set to correct
    function handleOk() {
        setIsModalVisible(false);
    }

    // User clicked the 'edit' link of a questions
    const editQuestion = (questionId) => {
        console.log('Edit Question clicked');
        history.push(`/question/${questionId}`);
    };


    const handleCategoryChange = (selectedCategories: string[]) => {
        console.log(`selected categories ${selectedCategories}`)
        setFilteredCategories(selectedCategories);
    };

    const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('pagination', pagination);
        console.log('filters', filters);
        console.log('sorter', sorter);
        console.log('extra', extra);
    };

    const onSearchFormFinish = (values: any) => {

        const searchString = values["searchterm"]
        console.log('Received values of form-components for search: ', searchString);

        if(searchString !== undefined) {
            if(searchString.length >= 3) {
                setSearchTerm(searchString);
            }
            else if (searchString.length > 1 && searchString.length < 3) {
                setIsModalVisible(true);
                return
            }
            else {
                setSearchTerm("")
            }
        }
        else {
            setSearchTerm("")
        }

       fetchGeneralQuestions(currentPage, searchString, filteredCategories).then(r => {
           console.log("Got results from search ")
       })
    };


    const actionColumn =  {
        title: 'Aktion',
            dataIndex: '',
        key: 'x',
        width: '5%',
        render: (text, record) => (
        <Space size="middle" split={<Divider type="vertical"/>}>
            <a onClick={e => {
                e.stopPropagation();
                editQuestion(record.uuid)
            }}><EditOutlined /></a>
        </Space>
    )}

    const columns = [
        {

            title: (<EyeOutlined/>), dataIndex: 'isPublished', key: 'isPublished', width: '5%',  render: (isPublished, record) => {
                return <UpdateVisibilityIcon questionId={record.uuid} publishState={isPublished} />
            },
        },
        {
            title: 'Frage',
            dataIndex: 'text',
            key: 'text',
            width: '50%',

        },
        {
            title: 'Alle Kataloge',
            dataIndex: 'catalogNames',
            key: 'catalogs',
            width: '20%',
            render: catalogNames => (
                <div style={{lineHeight: 2}}>
                    {catalogNames.map(tag => {
                        return (
                            <Tag color={tag === "Allgemein" ? "red" : "blue"} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })
                    }
                </div>),
            filters: catalogListForFilter,
            filterMultiple: true,
            onFilter: (value, record) => {
                /*console.log("Value: ", value)
                console.log("Record: ", record)
                if (value === "0000-0001" && record.isGeneral === true) {
                    return true
                }
                return record.catalogUuids.includes(value)
                 */
            },
            filterSearch: true,
        },
        {
            title: 'Kategorien',
            dataIndex: 'categories',
            key: 'categories',
            width: '20%',
            render: categories => (
                <div style={{lineHeight: 2}}>
                    {categories.map(tag => {
                        return (
                            <Tag size="small" color="green" key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })
                    }
                </div>),
            filters: availableCategories,
            filterMultiple: true,
            onFilter: (value, record) => {
                if (value === "0000-0001" && record.isGeneral === true) {
                    return true
                }
                return record.catalogUuids.includes(value)
            },
            filterSearch: true,

        },
        actionColumn
    ];

    return (
        <div>
            <Spin spinning={isFetching}>

                    <Card title="Suche mit Suchbegriff und Kategorien" style={{ width: '100%' }}>
                        <Form
                                form={form}
                                onFinish={onSearchFormFinish}
                                initialValues={{
                                    ["Kategorien"]: filteredCategories
                                }}
                        >
                        <Row type="flex" gutter={24} style={{marginBottom: 0}}>
                            <Col span={8} key={123}>
                                <Form.Item name={"searchterm"}  label={"Suchbegriff"}>
                                    <Input placeholder="Hier Suchbegriff eingeben" allowClear/>
                                </Form.Item>
                            </Col>

                            <Col span={16} key={234}>
                                <Form.Item name={"Kategorien"}  label={"Kategorien"}>
                                    <Select
                                        allowClear={true}
                                        mode="tags"
                                        size={"medium"}
                                        //defaultValue={filteredCategories}
                                        placeholder="Kategorie wählen"
                                        onChange={handleCategoryChange}
                                        style={{width: '100%'}}
                                    >
                                        {availableCategories}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                            <Row>
                                <Col span={24} style={{ textAlign: 'right' }}>
                                    <Button type="primary" htmlType="submit">
                                        Nach Suchbegriff in ausgewählten Kategorien suchen
                                    </Button>
                                </Col>
                            </Row>

                        </Form>
                    </Card>


                <div style={{"marginBottom": 30}}></div>

                <Col span={24}>

                    <PageHeader
                        title="Allgemeiner Fragenkatalog"
                        id="pageHeader"
                        subTitle="Liste aller Fragen im allgemeinen Fragenkatalog"
                    />

                    <Table
                        loading={isFetching}
                        columns={columns}
                        dataSource={tableData}
                        rowKey={question => question.uuid}
                        onChange={onChange}
                        pagination={{
                            defaultPageSize: pageSize,
                            total: totalItems,
                            // ADD: additional props for pagination component
                            onChange: (page) => {
                                setCurrentPage(() => page);
                            },
                            currentPage,
                            onShowSizeChange: (current, size) => {
                                setPageSize(() => size);
                            },
                        }}
                        expandable={{
                            expandedRowRender: (record) => (
                                <QuestionView showQuestion={false} question={record}/>
                            )
                        }}
                    ></Table>
                </Col>
                <Modal title="Suchbegriff ist zu kurz"
                       open={isModalVisible}
                       onOk={handleOk}
                       onCancel={handleOk}
                       closable={true}
                       cancelButtonProps={{style: {display: 'none'}}}>
                    <p>Bitte verwenden Sie einen Suchbegriff der mindestens drei Zeichen lang ist.</p>
                </Modal>
            </Spin>
        </div>
    )
}

export default GeneralQuestionList;
