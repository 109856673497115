import {Descriptions} from "antd";
import styles from './editor.detail.module.css';

const EditorDetailView = ({editor}) => {

    const createdDate = new Date(editor.createdAt*1000);


    return(
        <div className={styles["editorDetailSection"]}>
            <Descriptions labelStyle={{fontSize: 15, fontWeight: 500, marginBottom: -20}}
                          contentStyle={{fontSize: 15, fontWeight: 300}}
                          layout="vertical"
                          size="medium"
                          bordered={false}
                          column={4}
                          >
                <Descriptions.Item label="Editor">{editor.firstName} {editor.lastName}</Descriptions.Item>
                <Descriptions.Item label="Benutzername">{editor.username}</Descriptions.Item>
                <Descriptions.Item label="E-Mail">{editor.email}</Descriptions.Item>
                <Descriptions.Item label="Account seit">{createdDate.getDate()}.{createdDate.getMonth()+1}.{createdDate.getFullYear()}</Descriptions.Item>
            </Descriptions>
        </div>
    )
}

export default EditorDetailView;
