import React, {useEffect, useState} from 'react';
import {Layout, Menu} from 'antd';
import {useLocation, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {
    DashboardOutlined,
    FundProjectionScreenOutlined,
    PartitionOutlined,
    SettingOutlined,
    TeamOutlined,
    AppstoreOutlined,
    FolderOpenOutlined,
    FolderOutlined,
    UserOutlined,
    UserAddOutlined,
    ReadOutlined,
    LogoutOutlined,
    ProfileOutlined,
    DatabaseOutlined,
    CrownOutlined,
    FileTextOutlined,
    UnorderedListOutlined,
    SearchOutlined,
    GroupOutlined, CodeSandboxOutlined, QrcodeOutlined, QuestionCircleOutlined, ProjectOutlined
} from '@ant-design/icons';
import './Style.less';

import logo from '../images/RhAppFox.svg';
import {logout} from '../redux/actions/auth';
import {history} from "../utils/history";

const {SubMenu} = Menu;

const {Sider} = Layout;

function SiderMenu({handleOnCollapse, collapsed, parentHandler}) {

    const theme = 'dark';
    const dispatch = useDispatch();
    const authRole = useSelector(state => state.auth?.user?.user?.role);


    let location = useLocation();
    const [current, setCurrent] = useState(
        location.pathname === "/" || location.pathname === ""
            ? '/catalog-list'
            : location.pathname,
    );

    const generalItems = [
        {
            label: 'Lerngruppen',
            key: 'contentGroupsKey3',
            icon: <GroupOutlined />,
            children: [
                {label: 'Übersicht', key: '/content-groups', icon:  <UserOutlined />},
            ],
        },
        {
            label: 'Erweiterte Suche',
            key: '/search',
            icon: <SearchOutlined />,
        },
        {
            label: 'Tan',
            key: 'tanGroupKey',
            icon: <CodeSandboxOutlined />,
            children: [
                {label: 'Tan Liste', key: '/tan', icon:  <QrcodeOutlined />},
                {label: 'App Nutzer Import', key: '/tan/import', icon:  <UserOutlined />},
            ],
        },
        {
            label: 'Info & Statistik',
            key: 'catalogSubKey3',
            icon: <ReadOutlined />,
            children: [
                {label: 'Profil & Allgemeines', key: '/profile', icon:  <UserOutlined />},
                {label: 'Statistik zu Fragen', key: '/question-stats', icon:  <ProjectOutlined />},
            ],
        },
        {
            label: 'Abmelden',
            key: 'logout',
            icon:  <LogoutOutlined />,
        },
    ]

    const adminItems = [
        {
            label: 'Fragen',
            key: 'questionSubKey',
            icon: <QuestionCircleOutlined />,
            children: [
                {label: 'Frage erstellen', key: '/question-editor', icon: <FileTextOutlined/>},
                {label: 'Liste meiner Fragen', key: '/question-list', icon:  <UnorderedListOutlined />},
            ],
        },
        {
            label: 'Kataloge',
            key: 'catalogSubKey1',
            icon: <AppstoreOutlined/>,
            children: [
                {label: 'Meine Kataloge', key: '/catalog-list', icon: <ReadOutlined/>},
                {label: 'Allgemeiner Katalog', key: '/general-catalog', icon: <ReadOutlined/>},
            ],
        },

    ]

    const superItems = [
        {
            label: 'Editoren',
            key: 'editorSubKey',
            icon: <UserOutlined/>,
            children: [
                {label: 'Liste aller Editoren', key: '/editor-list', icon:   <TeamOutlined />},
                {label: 'Neuen Editor anlegen', key: '/editor-create', icon:   <UserAddOutlined/>},
            ],
        },
        {
            label: 'Kataloge',
            key: 'catalogSubKey1',
            icon: <AppstoreOutlined/>,
            children: [
                {label: 'Liste aller Kataloge', key: '/catalog-list', icon:  <DatabaseOutlined />},
                {label: 'Allgemeiner Katalog', key: '/general-catalog', icon: <ReadOutlined/>},
                {label: 'Detailansicht', key: '/catalog-content', icon:  <ProfileOutlined />},
            ],
        },
        {
            label: 'App Nutzer',
            key: '/player-list',
            icon: <CrownOutlined />,
        },
    ]

    const [menuItems, setMenuItems] = useState(generalItems);

    useEffect(() => {

        if (authRole === 'super') {
            setMenuItems(superItems.concat(generalItems))
        } else {
            setMenuItems(adminItems.concat(generalItems))
        }

    }, []);


    useEffect(() => {
        if (location) {
            if (current !== location.pathname) {
                console.log("location.pathname:", location.pathname)

                if (location.pathname === "/") {

                    if (authRole === 'super') {
                        setCurrent("/editor-list");
                    } else {
                        setCurrent("/question-create");
                    }

                } else {
                    setCurrent(location.pathname);
                }
            }
        }
    }, [location, current]);

    const handleSiderMenuClick = action => {
        console.log('menu:', action);
        switch (action.key) {

            case '/question-editor':
                console.log("History Push to /question-editor");
                history.push('/question-editor');
                break;

                // Also for Superuser
            case '/question-list':
                console.log("History Push to /question-list");
                history.push('/question-list');
                break;

            case 'admin-question-edit':
                console.log("History Push to /question/c2c4e955-6227-44c5-ac5f-e096a4016361");
                history.push('/question/c2c4e955-6227-44c5-ac5f-e096a4016361');
                break;

                // SUPER USER

            case '/editor-create':
                console.log("History Push to /editor-create");
                history.push('/editor-create');
                break;



            case '/editor-list':
                console.log("History Push to /editor-list");
                history.push('/editor-list');
                break;

            case '/catalog-list':
                console.log("History Push to /catalogs-list");
                history.push('/catalog-list');
                break;

            case '/general-catalog':
                console.log("History Push to /general-catalog");
                history.push('/general-catalog');
                break;

            case '/player-list':
                console.log("History Push to /player-list");
                history.push('/player-list');
                break;

                // Search
            case '/search':
                console.log("History Push to /search");
                history.push('/search');
                break;

            // Content Groups
            case '/content-groups':
                console.log("History Push to /content-groups");
                history.push('/content-groups');
                break;

            // FOR BOTH

            case '/profile':
                console.log("History Push to /profile");
                history.push('/profile');
                break;

            case '/question-stats':
                console.log("History Push to /question-stats");
                history.push('/question-stats');
                break;
            // LOGOUT

            case '/tan':
                console.log("TAN Codes");
                history.push('/tan');
                break;

            case '/tan/import':
                console.log("TAN Import");
                history.push('/tan/import');
                break;

            case 'logout':
                console.log("Dispatch logout");
                dispatch(logout());
                console.log("History Push to /");
                history.push('/');
                //parentHandler(true);
                console.log("Logout done");
                break;

            default:
                console.log("DEFAULT History Push to /");
                history.push('/');
        }
    };

    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="80"
            onCollapse={handleOnCollapse}
            collapsed={collapsed}
            width="256"
            theme={theme}
            collapsible

        >
            <div className="logo-container">
                <img
                    src={logo}
                    width="60%"
                />
            </div>

            <Menu mode="inline"
                  theme={theme}
                  onClick={handleSiderMenuClick}
                  defaultOpenKeys={['editorSubKey', 'catalogSubKey', 'questionSubKey']}
                  selectedKeys={[current]}
                  items={menuItems}
            >
            </Menu>
        </Sider>
    );
}

export default withRouter(SiderMenu);
