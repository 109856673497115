import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    PASSWORD_UPDATE,
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
    ? { isLoggedIn: true, user: user }
    : { isLoggedIn: false, user: null };

export default function auth(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
            };
        case PASSWORD_UPDATE:
            console.log("PASSWORD_UPDATE: ", payload)
            return {
                ...state,
                isLoggedIn: true,
                user: {
                    ...state.user,
                    accessToken: payload.accessToken,
                    refreshToken: payload.refreshToken
                }
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        default:
            return state;
    }
}
