import React, {useContext, useRef, useState} from 'react';
import {QuestionContext} from "../../../../context/questionContext";
import {Button, Row, notification} from 'antd';
import {Input} from 'antd';
import {Alert, message} from 'antd';
import Modal from "antd/es/modal/Modal";

const {TextArea} = Input;

export default function AnswerInput(props) {

    const {addAnswer, answers} = useContext(QuestionContext);
    const [answer, setAnswer] = useState("");

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const onChange = (e) => {
        setAnswer(e.target.value)
    }

    function addAnswerButtonClicked(isCorrectAnswer) {
        if (answers.length < 6) {
            console.log("Adding new answer");
            const newAnswer = {};
            newAnswer.title = answer
            newAnswer.correct = isCorrectAnswer
            addAnswer(newAnswer);
            setAnswer("");

        } else {
            showModal();
            setAnswer(answer);
        }
    }

    return (
        <Row
            justify="end"
            gutter={[0, 20]}
            className="todos-container"
        >
            <TextArea
                rows={3}
                value={answer}
                placeholder="Bitte hier die Antwort eingeben"
                allowClear
                onChange={onChange}
            />
            <Button
                style={{marginRight: "16px"}}
                type="secondary"
                onClick={e => {
                    e.stopPropagation();
                    addAnswerButtonClicked(true)
                }}
                disabled={answer.length < 2}>
                Als richtige Antwort hinzufügen
            </Button>
            <Button
                type="primary"
                onClick={e => {
                    e.stopPropagation();
                    addAnswerButtonClicked(false)
                }}
                disabled={answer.length < 2}>
                Als falsche Antwort hinzufügen
            </Button>
            <Modal title="Maximale Anzahl Antworten erreicht"
                   open={isModalVisible}
                   onOk={handleOk}>
                <p>Es könnten pro Fragen nur maximal 6 verschiedene Antworten hinzugefügt werden. Bitte löschen Sie
                    vorher eine andere Antwort und fügen Sie dann die neue Antwort hinzu.</p>
            </Modal>
        </Row>

    );
};
