import React, {useRef} from "react";
import {SearchContentProvider} from "./SearchContext";
import SearchPanel from "./SearchPanel";
import {PageHeader} from "antd";
import SearchResult from "./SearchResult";

const GeneralQuestionsSearchView = (props) => {

    const searchPanelComp = useRef(null);

    return (
        <SearchContentProvider
            isGeneral={"true"}
        >
            <SearchPanel
                triggerSearch={() => {searchPanelComp.current.startSearch();}}
                showCatalogs={true}
                showGeneralQuestionSelection={false}
            />
            <PageHeader
                title="Suchergebnisse allgemeiner Fragenkatalog"
                id="pageHeader"
                subTitle="Alle Fragen auf welche die Suchparameter zutreffen"
            />
            <SearchResult
                ref={searchPanelComp}
                allowDeleteQuestions={false}
                editorList={[]}
            />
        </SearchContentProvider>
    )
}

export default GeneralQuestionsSearchView;
