import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Form, Input, Button} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {login} from '../../redux/actions/auth';

import 'antd/dist/antd.min.css';
import styles from './login.module.css';
import Modal from "antd/es/modal/Modal";
import Row from "antd/es/descriptions/Row";


export const Login = (props) => {

    const dispatch = useDispatch();
    const [isValidInput, setIsValidInput] = useState(false);

    let history = useHistory();
    //console.log("history", history);

    const validatePasswordField = (rule, value, callback) => {
        if (value.length > 0 && value.length < 4) {
            setIsValidInput(false)
            return Promise.reject(new Error('Passwort muss mind. 4-stellig sein!'));
        } else {
            setIsValidInput(true)
            return Promise.resolve()
        }
    }

    const onFinish = (formValues) => {
        console.log('onFinish', formValues);
        localStorage.setItem('username', formValues.username);

        dispatch(login(formValues.username, formValues.password)).then(data => {
            console.log("Pushing to home...");
            history?.push("/");
        }).catch(error => {
            console.log('Error in Login (onFinish)', error);
            if (error) {
                if (!error.response) {
                    Modal.info(
                        {
                            title: 'Netzwerkfehler',
                            content: 'Es konnte keine Verbindung zum Server hergestellt werden',
                        })
                } else if (error.response.status === 401) {
                    Modal.info(
                        {
                            title: 'Login fehlgeschlagen',
                            content: 'Der Benutzername und das Passwort stimmen nicht überein',
                        })

                } else if (error.response.status === 500) {
                    Modal.info(
                        {
                            title: 'Server Fehler 500',
                            content: 'Es ist ein unbekannter Fehler aufgetreten',
                        })

                } else {
                    const messageTitle = "Unbekannter Fehler ("+error.response.status+")";
                    Modal.info(
                        {
                            title: messageTitle,
                            content: 'Es ist ein unbekannter Fehler mit dem Status Code '+error.response.status+' aufgetreten',
                        })

                }
            } else {
                Modal.info(
                    {
                        title: 'Unbekannter Fehler (0)',
                        content: 'Es ist ein unbekannter Fehler aufgetreten',
                    })

            }

        });
    };

    return (
            <Form
                name="normal_login"
                className={`${styles['login-form-components']}`}
                initialValues={{
                    remember: true,
                    username: localStorage.getItem('username') || '',
                    password: ''
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="username"
                    rules={[
                        {required: true, message: 'Bitte geben Sie Ihren Benutzernamen ein!'},
                        {whitespace: false, message: 'Bitte ohne Leerzeichen an Anfang und Ende eingeben!'}
                    ]}
                >
                    <Input
                        prefix={<UserOutlined className={'site-form-components-item-icon'}/>}
                        placeholder="Username"
                        style={{width: "300px"}}/>
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {required: true, message: 'Bitte geben Sie Ihr Passwort ein!'},
                        {validator: validatePasswordField}
                    ]}
                >
                    {/* <Input.Password /> */}
                    <Input.Password
                        prefix={<LockOutlined className={'site-form-components-item-icon'}/>}
                        placeholder="Passwort"
                        autoComplete="on"
                        style={{width: "300px"}}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        disabled={!isValidInput}
                        type="primary"
                        htmlType="submit"
                        className={`${styles['login-form-components-button']}`}>
                        Anmelden
                    </Button>
                </Form.Item>
            </Form>
    );
};
