import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE, SET_CATEGORIES, SET_CATALOGS, SET_EDITORS,
} from "./types";

//import AuthService from '../../network/services/auth.service';
import RAQAClient from '../../network/api/RaqaClient';

//https://javascript.info/currying-partials
export const login = (usernameOrEmail, password) => (dispatch) => {
    return RAQAClient
        .login(usernameOrEmail, password)
        .then(
        (response) => {
            console.log("THEN: ", response);

            if (['super', 'admin'].includes(response.data?.user?.role)) {

                console.log("Dispatch LOGIN_SUCCESS");

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: {user: response.data},
                });

                if (['super', 'admin'].includes(response.data?.user?.role)) {
                    RAQAClient.listQuestionsCategories().then (
                        (response) => {
                            console.log("API Result from /question/categories: ", response.data);
                            dispatch({
                                type: SET_CATEGORIES,
                                payload: response.data
                            })
                        },
                        (error) => {
                            console.log("listQuestionsCategories error");

                            if (error.response) {
                                console.log(error.response.data);
                                console.log(error.response.status);
                                console.log(error.response.headers);
                            }
                        }
                    )

                    RAQAClient.listCatalogs().then (
                        (response) => {
                            console.log("API Result from GET /catalog: ", response.data);
                            dispatch({
                                type: SET_CATALOGS,
                                payload: response.data
                            })
                        },
                        (error) => {
                            console.log("listQuestionsCategories error");

                            if (error.response) {
                                console.log(error.response.data);
                                console.log(error.response.status);
                                console.log(error.response.headers);
                            }
                        }
                    )

                   RAQAClient.listUsers("admin", true).then(
                        (response) => {
                            const editorsWithKey =  response.data.map(u => {
                                u.key = u.id;
                                return u;
                            })

                            console.log("API Result from listUsers after Login: ", editorsWithKey);

                            dispatch({
                                type: SET_EDITORS,
                                payload: editorsWithKey
                            })
                        }
                    );



                }

                return Promise.resolve();
            } else {
                console.log("Dispatch LOGIN_FAIL");
                // Deletes the Redux state user variable (see reducer)
                dispatch({
                    type: LOGIN_FAIL,
                });

                return Promise.reject();
            }
        },
        (error) => {
            // Deletes the Redux state user variable (see reducer)
            dispatch({
                type: LOGIN_FAIL,
            });

            return Promise.reject(error);
        })
        .catch(error => {
            console.error("Catch Login Error:",error);
            return Promise.reject(error);
        })
};

export const logout = () => (dispatch) => {
    dispatch({
        type: LOGOUT,
    });
};