import React, {useState} from "react";
import {Button, Modal, Spin} from "antd";
import {CheckOutlined, DeleteOutlined, EyeInvisibleOutlined} from "@ant-design/icons";
import RAQAClient from "../../network/api/RaqaClient";
import styles from './updateIcon.module.css';

function UpdateVisibilityIcon({questionId, publishState, reloadTable}) {
    const [pending, setPending] = useState(false);
    const [isPublished, setIsPublished] = useState(publishState);

    function setQuestionVisibility() {
        setPending(true)

        const updateData = {
            isPublished: !isPublished
        }

        RAQAClient.updateQuestion(questionId, updateData)
            .then( () => {
                    setIsPublished(!isPublished);
                    reloadTable();
                }
            )
            .catch(error => {
                Modal.error({
                    title: "Setzt der Sichtbarkeit fehlgeschlagen",
                    content: "Die Sichtbarkeit der Frage konnte nicht geändert werden",
                    onOk:  undefined
                });
            })
            .finally( () => {
                setPending(false)
            })
        }



    if (pending === true) {
        return  <div className={styles["iconContainer"]} ><Spin/></div>
    } else {
        if (isPublished === true) {
            return  <div className={styles["iconContainer"]} >
                           <CheckOutlined onClick={() => {
                               setQuestionVisibility()
                           }}/>
                    </div>
        } else {
            return  <div className={styles["iconContainer"]} >
                           <EyeInvisibleOutlined onClick={() => {
                               setQuestionVisibility()
                           }}/>
                   </div>
        }
    }
}

export default UpdateVisibilityIcon;
