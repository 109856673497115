import React, {useEffect} from "react";
import {useState} from "react";
import {useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import {Button, Form, Card, Col, Divider, Input, Modal, Popconfirm, Space, Row, Spin, Table} from "antd";
import {CheckOutlined, CrownOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import RAQAClient from '../../network/api/RaqaClient';
import {history} from "../../utils/history";
import UpdateVisibilityIcon from "../../components/other/UpdateIcon";
import {useSelector} from "react-redux";
import ContentGroupAddCatalogModal from "./ContentGroupAddCatalogModal";

const ContentGroupCatalogsTable = ({contentGroupId}) => {

    // All catalogs of content group
    const [contentGroupCatalogs, setContentGroupCatalogs] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    // Catalogs we want to add
    const [catalogsToAdd, setCatalogsToAdd] = useState([]);
    // Flag is show CatalogModal is visible
    const [showCatalogModal, setShowCatalogModal] = useState(false);

    // Access the Redux State
    const reduxCatalogs = useSelector(state => state.questions?.catalogs);
    const reduxEditors = useSelector(state => state.administration.editors);

    // USE-QUERY
    const {data: assignedCatalogs, refetch, isLoading: isFetching,} =
        useQuery(['contentGroup', contentGroupId, 'catalogs'],
            () => fetchContentGroupCatalogs(contentGroupId),
            {
                keepPreviousData: true,
                onSettled: (data, error) => onContentGroupsCatalogsSettled(data, error)

            });


    useEffect(() => {

        const catalogTableData = [];

        // Filter all catalogs that we can add to this content group
        // We filter the reduxCatalogs and the assigned catalog
        if (reduxCatalogs && contentGroupCatalogs) {
            // This filters all catalogs that are not assigned to the content group
            const availableCatalogs = reduxCatalogs.filter(reduxCatalog => !contentGroupCatalogs.some(contentGroupCatalog => contentGroupCatalog.key === reduxCatalog.uuid));

            // Now add more properties to the object
            availableCatalogs.forEach(catalog => {
                const editor = reduxEditors.find(editor => editor.id === catalog.userId);
                const newEntry = {
                    key: catalog.uuid,
                    catalog: catalog,
                    editor: editor,
                }
                catalogTableData.push(newEntry);
            });
            console.log(catalogTableData);
            setCatalogsToAdd(catalogTableData);
        }

    }, [contentGroupCatalogs, reduxCatalogs]);

    const onClickShowCatalogModal = (e) => {
        e.stopPropagation();
        setShowCatalogModal(true);
    };

    const onViewCatalog = (record) => {
        history.push({
            pathname: '/catalog-content',
            state: {
                catalog: record.catalog,
                editor: record.editor
            }
        });
    };

    const onRemoveCatalogFromContentGroup = (record) => {
        console.log("**** Starting to remove catalog from content group ****");

        setIsDeleting(true);

        console.log("Record", record);
        console.log("CatalogId", record.catalog.uuid);

        RAQAClient.removeCatalogsFromContentGroup(contentGroupId, [record.catalog.uuid])
            .then((response) => {
                    console.log("removeCatalogsFromContentGroup finished", response);

                    setIsDeleting(false);
                    console.log("**** Finished remove catalog from content group ****", response);

                    Modal.success({
                        title: "Katalog entfernt",
                        content: "Der Katalog wurde erfolgreich von der Gruppe entfernt",
                        onOk: refetch
                    });
                }
            )
            .catch(error => {
                console.log("removeCatalogsFromContentGroup error", error);
                console.log("**** Finished with error to remove catalog from content group ****");

                setIsDeleting(false);
                Modal.error({
                    title: "Löschen fehlgeschlagen",
                    content: "Der Katalog konnte nicht entfernt werden",
                    onOk: undefined
                });
                console.log("Delete error: ", error);
            })
    };

    /// New question data (or error) received
    const onContentGroupsCatalogsSettled = (catalogs, error) => {
        const tableData = [];

        // Build the list for table with the editor and a key
        catalogs.forEach(catalog => {
            const editor = reduxEditors.find(editor => editor.id === catalog.userId);
            const newEntry = {
                key: catalog.uuid,
                catalog: catalog,
                editor: editor,
            }
            tableData.push(newEntry);
        });

        setContentGroupCatalogs(tableData);
    };

    const fetchContentGroupCatalogs = async (contenGroupId) => {
        try {
            const data = await RAQAClient.getContentgroupCatalogs(contenGroupId);
            console.log('getContentGroupCatalogs Data.....', data?.data?.catalogs);
            return data?.data?.catalogs || [];
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    const columns = [
        {
            title: (<CrownOutlined/>),
            dataIndex: ["catalog", "isPublished"],
            key: 'isPublished',
            width: '5%',
            render: isPublished =>
                <span>{isPublished === true ? <CheckOutlined/> : ""} </span>
        },
        {title: 'Katalog', dataIndex: ["catalog", "title"], width: '25%', key: 'title', render: (text, record) => {
                if (record.catalog.isPublished === true) {
                    return `${text}`;
                } else {
                    return <span style={{"color": "darkgrey"}}>{text}</span>; // just for decoration
                }
            }},
        {title: 'Beschreibung', dataIndex: ["catalog", "description"], width: '35%', key: 'description',render: (text, record) => {
                if (record.catalog.isPublished === true) {
                    return `${text}`;
                } else {
                    return <span style={{"color": "darkgrey"}}>{text}</span>; // just for decoration
                }
            }},

        {
            title: 'Editor', dataIndex: ["editor"], key: 'editor', width: '20%', render: (text, record) => {
                if (record.catalog.isPublished === true) {
                    return `${text.firstName} ${text.lastName}`; // just for decoration
                } else {
                    return <span style={{"color": "darkgrey"}}>{text.firstName} {text.lastName}</span>; // just for decoration
                }

            }
        },
        {
            title: 'Fragen',
            dataIndex: ["catalog", "questionUuids"],
            key: 'questionUuids',
            width: '10%',
            render: (questionUuids, record) => {
                if (record.catalog.isPublished === true) {
                    return `${questionUuids.length}`; // just for decoration
                } else {
                    return <span style={{"color": "darkgrey"}}>{questionUuids.length}</span>; // just for decoration
                }

            }
        },
        {
            title: 'Aktionen',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <Space size="middle" split={<Divider type="vertical"/>}>
                    <a onClick={e => {
                        e.stopPropagation();
                        onViewCatalog(record);
                    }}>
                        <EyeOutlined/></a>

                    <Popconfirm
                        title="Wollen Sie diesen Katalog wirklich aus der Gruppe entfernen?"
                        onConfirm={e => {
                            e.stopPropagation();
                            onRemoveCatalogFromContentGroup(record)
                        }}
                        onCancel={e => e.stopPropagation()}
                        okText="Ja"
                        cancelText="Nein"
                    >
                        <a onClick={e => e.stopPropagation()}><DeleteOutlined/></a>
                    </Popconfirm>
                </Space>
            ),
        },

    ];


    return (
        <div>


            <Row style={{"marginTop":"10px", "marginBottom":"15px"}}>
                <Col span={18}>
                    <h3>{contentGroupCatalogs ? contentGroupCatalogs.length : "Keine"} zugewiesene Kataloge</h3>
                </Col>
                <Col span={6} style={{"textAlign": "right"}}>
                    <Button key="buttonAddCatalog" type="primary" onClick={onClickShowCatalogModal}>
                        Katalog hinzufügen
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Table size="small"
                           columns={columns}
                        // rowSelection={rowSelectionUsers}
                           dataSource={contentGroupCatalogs}
                           pagination={{pageSize: 10}}
                    />

                    <ContentGroupAddCatalogModal
                        contentGroupId={contentGroupId}
                        open={showCatalogModal}
                        availableCatalogs={catalogsToAdd}
                        onCancel={() => {
                            setShowCatalogModal(false);
                        }}
                        onSave={() => {
                            refetch();
                            setShowCatalogModal(false);
                        }}
                    />
                </Col>

            </Row>
        </div>
    );
};

export default ContentGroupCatalogsTable;
