import QuestionTableView from "../../../components/question/QuestionTableView";
import {useDispatch, useSelector} from "react-redux";
import {useQuery, useQueryClient} from "react-query";
import RAQAClient from "../../../network/api/RaqaClient";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import CatalogCardListView from "../../../components/catalog/CatalogCardListView";
import {Modal, Spin} from "antd";
import EditorDetailView from "../../../components/editor/EditorDetailView";
import CatalogDetailView from "../../../components/catalog/CatalogDetailView";

import styles from './catalog.content.module.css';
import {history} from "../../../utils/history";


const CatalogContent = ({history, match}) => {

    // Use location to get the parameter in location.state
    const location = useLocation();

    let catalog = location.state.catalog;
    let editor = location.state.editor;

    console.log("Catalog Content: ", catalog)
    console.log("Editor: ", editor)

    // Use State
    const [isDeleting, setIsDeleting] = useState(false);
    const [additionalCatalogs, setAdditionalCatalogs] = useState([]);
    const [questions, setQuestions] = useState([]);

    // Redux State
    const reduxEditors = useSelector(state => state.administration?.editors);

    // Query Client
    const queryClient = useQueryClient()
    const {data, refetch, isLoading, isSuccess, error} = useQuery("catalogContent",
        () => fetchQuestionsForCatalog()
    );

    // Run only once
    useEffect(() => {
        console.log("useEffect");
        refetch();
    }, [catalog, editor])

    // -- Async API methods -------------------------------------
    const fetchQuestionsForCatalog = async () => {

        const response = await RAQAClient.listQuestionsForCatalog(catalog.uuid);
        console.log("Response listQuestionsForCatalog", response)
        const responseQuestions = response.data || [];
        const questionsWithUuids = responseQuestions.map(q => {
            const question = {...q};
            question.key = q.uuid;
            return question;
        })

        questionsWithUuids.forEach(question => {
            question.catalogUuids.forEach(uuid => {
                question.catalogNames = [];
                reduxEditors.forEach(editor => {
                        const catalogFound = editor.catalogs.find(x => x.uuid === uuid)
                        if (catalogFound !== undefined && catalogFound.uuid === catalog.uuid) {
                            question.catalogNames.push(catalogFound.title);
                        }
                    }
                )
            })
            if (question.isGeneral === true) {
                console.log("IS GENERAL QUESTION");
                question.catalogNames.unshift("Allgemein");
            }
        });

        const otherCatalogs = editor.catalogs.filter(item => item.uuid !== catalog.uuid);
        setAdditionalCatalogs(otherCatalogs);
        setQuestions(questionsWithUuids);

        return questionsWithUuids;
    }

    // User clicked the 'edit' link of a questions
    const editQuestion = (questionId) => {
        console.log('Edit Question clicked');
        history.push(`/question/${questionId}`);
    };

    // User clicked the 'delete' link of a questions
    const deleteQuestion = (questionsId) => {
        setIsDeleting(true);

        RAQAClient.deleteQuestion(questionsId)
            .then( () => {
                    setIsDeleting(false);

                    Modal.success({
                        title: "Frage erfolgreich gelöscht",
                        content: "Die Frage wurde erfolgreich gelöscht",
                        onOk:  refetch
                    });
                }
            )
            .catch(error => {
                setIsDeleting(false);
                Modal.error({
                    title: "Löschen fehlgeschlagen",
                    content: "Die Frage konnte nicht gelöscht werden",
                    onOk:  undefined
                });
                console.log("Delete error: ", error);
            })
    };

    console.log("Rendering: Questions: ", questions)
    console.log("Rendering: Additional Catalogs: ", additionalCatalogs)

    return (
        <Spin spinning={isLoading}>
            <div id={styles['catalogView']}>
                <h1>Fragenkatalog<br/><span className={styles["catalogHeadline"]}>{catalog.title}</span></h1>
                <CatalogDetailView catalog={catalog}/>
                <div style={{paddingBottom: 25}}/>
                <h1>Vorhandene Fragen</h1>
                <QuestionTableView questions={questions} onDeleteQuestion={deleteQuestion} onEditQuestion={editQuestion} />
                <h1>Informationen zum Editor</h1>
                <EditorDetailView editor={editor}/>
                <div style={{paddingBottom: 25}}/>
                <h1>Weitere Kataloge des Editors</h1>
                <CatalogCardListView catalogs={additionalCatalogs} editor={editor}/>
            </div>
        </Spin>
    )
}

export default CatalogContent;
