import {Button, Card, Col, Form, Input, Row, Select, Space, Spin} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {SearchContext} from "./SearchContext";
import {useSelector} from "react-redux";

const SearchPanel = (props) => {

    // Context API
    const {searchTerm, filteredCatalogs, filteredCategories, updateSearchParams, isGeneral} = useContext(SearchContext);

    // Search Form
    const [form] = Form.useForm();

    // Redux State
    const reduxCatalogs = useSelector(state => state.questions?.catalogs);
    const reduxCategories = useSelector(state => state.questions?.categories);

    // State for all available catalogs and categories
    const [availableCategories, setAvailableCategories] = useState([])
    const [availableCatalogs, setAvailableCatalogs] = useState([])

    // Set the stored search parameters in the form-components
    useEffect(() => {
        form.setFieldsValue({
            searchString: searchTerm,
            searchCategories: filteredCategories,
            searchCatalogs: filteredCatalogs,
            generalQuestions: isGeneral,
        });

    }, [])

    useEffect(() => {
        // Get all catalogs
        if (reduxCatalogs !== undefined) {
            console.log("reduxCatalogs")
            console.log(reduxCatalogs)

            const selectCatalogOptions = [];
            reduxCatalogs.forEach(
                element => selectCatalogOptions.push(
                    <Select.Option key={element.uuid} value={element.uuid}>
                        {element.title}
                    </Select.Option>)
            );
            setAvailableCatalogs(selectCatalogOptions);
        }

        // Set all categories
        if (reduxCategories !== undefined) {
            const selectOptions = [];
            reduxCategories.forEach(
                element => selectOptions.push(
                    <Select.Option key={element} value={element}>
                        {element}
                    </Select.Option>)
            );
            setAvailableCategories(selectOptions);
        }

    }, [reduxCatalogs, reduxCategories])

    const deleteSearchFilter = () => {
        form.setFieldsValue({
            searchString: "",
            searchCategories: [],
            searchCatalogs: [],
            generalQuestions: isGeneral,
        });

        console.log("*** Delete Search Filter and isGeneral is: "+isGeneral)

        const searchParams = {
            searchTerm: "",
            searchCategories: [],
            searchCatalogs: [],
            searchGeneral: isGeneral,
        }
        updateSearchParams(searchParams)

    }
    const onSearchFormFinish = (values: any) => {
        const searchString = values["searchString"]
        const categories = values["searchCategories"]
        const catalogs = values["searchCatalogs"]
        const isGeneralStringFromForm = values["generalQuestions"]

        const searchParams = {
            searchTerm: searchString,
            searchCategories: categories,
            searchCatalogs: catalogs,
            searchGeneral: isGeneralStringFromForm ? isGeneralStringFromForm : isGeneral
        }

        // Update the search params in the search context
        updateSearchParams(searchParams)
        // Trigger the search in the search result component
        props.triggerSearch()
    }
    return (
        <div>
            <Spin spinning={false}>

                <Card title="Suche mit Suchbegriff und Kategorien" style={{width: '100%'}}>
                    <Form
                        labelCol={{span: 2}}
                        wrapperCol={{span: 22}}
                        form={form}
                        onFinish={onSearchFormFinish}
                        initialValues={{
                            ["searchCategories"]: filteredCategories,
                            ["searchString"]: searchTerm,
                            ["generalQuestions"]: "undefined"
                        }}
                    >
                        <Form.Item name={"searchString"} label={"Suchbegriff"}>
                            <Input placeholder="Hier Suchbegriff eingeben" allowClear/>
                        </Form.Item>

                        <Form.Item name={"searchCategories"} label={"Kategorien"}>
                            <Select
                                allowClear={true}
                                mode="tags"
                                size={"medium"}
                                placeholder="Kategorie wählen"
                                style={{width: '100%'}}
                            >
                                {availableCategories}
                            </Select>
                        </Form.Item>
                        {
                            props.showCatalogs ?
                                <Form.Item name={"searchCatalogs"} label={"Kataloge"}>
                                    <Select
                                        allowClear={true}
                                        mode="tags"
                                        size={"medium"}
                                        placeholder="Kataloge wählen"
                                        style={{width: '100%'}}
                                    >
                                        {availableCatalogs}
                                    </Select>
                                </Form.Item>
                                :
                                <></>
                        }
                        {
                            props.showGeneralQuestionSelection ?
                                <Form.Item name={"generalQuestions"} label={"Allgemeiner Fragenkatalog"}   labelCol={{span: 24}}
                                           wrapperCol={{span: 24}}>
                                    <Select
                                        options={[
                                            { value: "true", label: 'Ergebnisse müssen im allgemeinen Fragenkatalog sein' },
                                            { value: "false", label: 'Ergebnisse dürfen nicht im allgemeinen Fragenkatalog sein' },
                                            { value: "undefined", label: 'Ergebnisse können im allgemeinen Fragenkatalog sein' },
                                        ]}
                                    />
                                </Form.Item>
                                :
                                <></>
                        }


                        <Form.Item wrapperCol={{offset: 16, span: 8}}>
                            <Row>
                                <Col span={12} style={{paddingRight: '10px'}}>
                                    <Button block onClick={deleteSearchFilter}>
                                        Filter löschen
                                    </Button>
                                </Col>
                                <Col span={12}>
                                    <Button block type="primary" htmlType="submit">
                                        Suchen
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </Card>


                <div style={{"marginBottom": 30}}></div>
            </Spin>
        </div>
    )
}

export default SearchPanel;
