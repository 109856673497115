import {EyeOutlined} from "@ant-design/icons";
import UpdateVisibilityIcon from "../../../components/other/UpdateIcon";
import {Button, Card, Col, Form, Input, Modal, PageHeader, Row, Select, Spin, Table, Tag} from "antd";
import QuestionView from "../../../components/question/QuestionView";
import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from "react";
import resultListColumns from "../question/resultListColumns";
import {SearchContext} from "./SearchContext";
import {useQuery} from "react-query";
import RAQAClient from "../../../network/api/RaqaClient";
import {useSelector} from "react-redux";
import {history} from "../../../utils/history";
import { useLocation } from 'react-router-dom'

const SearchResult = forwardRef((props, ref) => {

    // Context API
    const {searchTerm, filteredCatalogs, filteredCategories, isGeneral} = useContext(SearchContext);
    console.log(`--> SearchContentProvider generalQuestionsOnly: ${isGeneral}`);
    console.log(isGeneral);

    // Get from the param is the search result should contain general questions
    // generalQuestionsOnly == true => Only questions which have set isGeneral = true
    // generalQuestionsOnly == false => Only questions which have set isGeneral = false
    // generalQuestionsOnly == undefined => All questions no matter what is General is set


    const [tableData, setTableData] = useState([]);

    const reduxCatalogs = useSelector(state => state.questions?.catalogs);

    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = React.useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);

    useImperativeHandle(ref, () => ({
        startSearch() {
            console.log(`Current Page: ${currentPage}`)
            console.log(`SearchContentProvider generalQuestionsOnly: ${isGeneral}`)

            performSuperSearch(currentPage, searchTerm, filteredCategories, filteredCatalogs, isGeneral, props.editorList).then(r => {
                console.log("Got results after manual search ")
            })
        }
    }));

   /* useEffect(() => {
        // pageNumber, searchTerm, filteredCategories, filteredCatalogs, isGeneral, userIds
        performSuperSearch(currentPage, searchTerm, filteredCategories, filteredCatalogs, isGeneral, props.editorList).then(r => {
            console.log("Got results from useEffect search ")
        })
    }, [])*/

    // User clicked the 'edit' link of a questions
    const editQuestion = (questionId) => {
        console.log('Edit Question clicked');
        history.push(`/question/${questionId}`);
    };

    const  deleteQuestion = (questionsId) => {
        setIsDeleting(true);

        RAQAClient.deleteQuestion(questionsId)
            .then(() => {
                    setIsDeleting(false);
                    refetch();
                }
            )
            .catch(error => {
               setIsDeleting(false);
                Modal.error({
                    title: "Löschen fehlgeschlagen",
                    content: "Die Frage konnte nicht gelöscht werden",
                    onOk: undefined
                });
                console.log("Delete error: ", error);
            })
    };

    const  refreshTable = () => {
        refetch();
    }
    // Columns of the result table
    const resultColumns = resultListColumns([], [], editQuestion, props.allowDeleteQuestions ? deleteQuestion : null, refreshTable)

    /// New question data (or error) received
    const onQuestionsSettled = (data, error) => {
        console.log('onQuestionsSettled: ');
        console.log(data);

        setTableData(data);
    };

    const {
        data: questions,
        refetch,
        isLoading: isFetching,
    }
        = useQuery(['questions', currentPage], () =>
        performSuperSearch(currentPage, searchTerm, filteredCategories, filteredCatalogs, isGeneral, props.editorList), {
            keepPreviousData: true,
            onSettled: (data, error) => onQuestionsSettled(data, error)
        }
    )

    const performSuperSearch = async (pageNumber, searchTerm, filteredCategories, filteredCatalogs, isGeneral, userIds) => {

        console.log('performSuperSearch pageNumber: ' + pageNumber);
        setCurrentPage(pageNumber);
        console.log('performSuperSearch searchString: ' + searchTerm);
        console.log('performSuperSearch filteredCategories: ' + filteredCategories);
        console.log('performSuperSearch filteredCatalogs: ' + filteredCatalogs);
        console.log('performSuperSearch isGeneral: ' + isGeneral);
        console.log('performSuperSearch userIds: ' + userIds);

        let includeGeneralCatalog = undefined;

        if(isGeneral === "true") {
            includeGeneralCatalog = true;
        }
        else if (isGeneral === "false") {
            includeGeneralCatalog = false;
        }

        return RAQAClient.getSearchResults(pageNumber, pageSize, searchTerm, filteredCategories, filteredCatalogs, includeGeneralCatalog, userIds).then(response => {

            console.log('Search result questions received: '+response.data);
            console.log(response.data);

            //console.log(response.data);

            const resultQuestions = response.data.questions.map(q => {
                const question = {...q};
                question.key = q.uuid;
                question.catalogNames = [];

                question.catalogUuids.forEach(uuid => {
                    const catalog = reduxCatalogs.find(x => x.uuid === uuid)
                    if (catalog !== undefined) {
                        question.catalogNames.push(catalog.title);
                    }
                });

                if(question.isGeneral === true) {
                    // console.log("IS GENERAL QUESTION");
                    question.catalogNames.unshift("Allgemein");
                }

                return question;
            });

            setPageSize(() => response.data.pageSize);
            setCurrentPage(() => response.data.page);
            setTotalPages(() => response.data.totalPages);
            setTotalItems(() => response.data.totalItems);
            setTableData(resultQuestions);

            return resultQuestions;

        });
    };


    return (
        <div>
            <div style={{"marginBottom": 30}}></div>
                <Table
                    loading={false}
                    columns={resultColumns}
                    dataSource={tableData}
                    rowKey={question => question.uuid}
                    onChange={() => {
                    }}
                    pagination={{
                        defaultPageSize: pageSize,
                        total: totalItems,
                        // ADD: additional props for pagination component
                        onChange: (page) => {
                            setCurrentPage(() => page);
                        },
                        currentPage,
                        onShowSizeChange: (current, size) => {
                            setPageSize(() => size);
                        },
                    }}
                    expandable={{
                        expandedRowRender: (record) => (
                            <QuestionView showQuestion={false} question={record}/>
                        )
                    }}
                ></Table>
                <span>Gefundende Fragen: {totalItems}</span>
        </div>
        )
    }
)
export default SearchResult;
