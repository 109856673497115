import React, {useContext, useEffect, useRef} from "react";
import { QuestionContext } from "../../../../context/questionContext";
import {List} from "antd";
import AnswerItem from "./AnswerItem";

export default function AnswerList(props) {
    const {answers} = useContext(QuestionContext);
    const divRef = useRef();

    return (
        <List
            locale={{
                emptyText: "Keine Antworten vorhanden",
            }}
            dataSource={answers}
            renderItem={(answer) => (

                <AnswerItem
                    answer={answer}
                    reference = {divRef}
                />
            )}
        />
    );
}
