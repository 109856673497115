import EditorDetailView from "../../../components/editor/EditorDetailView";
import {useLocation} from "react-router-dom";
import CatalogCardListView from "../../../components/catalog/CatalogCardListView";
import React from "react";
import {Divider} from "antd";

const EditorView = ({history, match}) => {

    const location = useLocation();

    var editor = {};

    if (location.state !== undefined) {
        editor = location.state.editor;
        console.log("Editor: ", location.state.editor);
    }
    else {
        return (
            <div>
                No editor defined
            </div>
        )
    }
        return (
        <div>
            <h2>Detailansicht Editor</h2>
            <EditorDetailView editor={editor}/>
            <Divider dashed />
            <div>
                <div style={{paddingBottom: 10}}/>
                <h2>Vorhandene Fragenkataloge</h2>
                <div style={{paddingBottom: 10}}/>
                <CatalogCardListView catalogs={editor.catalogs} editor={editor}/>
            </div>
        </div>
    )
}

export default EditorView;
