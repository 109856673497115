import React, {createContext, useState} from "react";
import {useLocation} from "react-router-dom";

export const initialSearchFilterState = {
    searchString: "",
    catalogs: [],
    categories: []
};

// Create context
export const SearchContext = createContext(initialSearchFilterState);

export function SearchContentProvider({ isGeneral, children }) {

    console.log("SearchContentProvider: "+isGeneral)
    // React Router
    const location = useLocation();

    // Get the search params from the local storage
    const savedSearchParams = JSON.parse(localStorage.getItem(location.pathname));

    // Use State
    const [searchString, setSearchString] = useState(savedSearchParams ? savedSearchParams.searchTerm : "")
    const [searchCategories, setSearchCategories] = useState(savedSearchParams ? savedSearchParams.searchCategories : [])
    const [searchCatalogs, setSearchCatalogs] = useState(savedSearchParams ? savedSearchParams.searchCatalogs : [])
    const [searchGeneralQuestions, setSearchGeneralQuestions] = useState(savedSearchParams ? savedSearchParams.searchGeneral : isGeneral)

    /*
    // Set the isGeneral
    useEffect(() => {
        if(savedSearchParams && !savedSearchParams.hasOwnProperty("searchGeneral")) {
            setSearchGeneralQuestions(isGeneral);
            console.log("Has NOT property searchGeneral and now set to: "+isGeneral)
        }
        else {
            console.log("Has property searchGeneral and now set to: "+savedSearchParams.searchGeneral)

        }

    }, [])*/

    function updateSearchParams(params) {
        console.log("updateSearchParameters");
        console.log(params);

        setSearchString(params.searchTerm)
        setSearchCategories(params.searchCategories)
        setSearchCatalogs(params.searchCatalogs)
        setSearchGeneralQuestions(params.searchGeneral)

        /*
        if(params.searchGeneral == null || params.searchGeneral === "undefined") {
            setSearchGeneralQuestions(isGeneral)
        }
        else {
            setSearchGeneralQuestions(params.searchGeneral)
        }*/

        // Store the search params so that we can use them again
        localStorage.setItem(location.pathname, JSON.stringify(params));
    }

    return (
        <SearchContext.Provider
            value={{
                searchTerm: searchString,
                filteredCatalogs: searchCatalogs,
                filteredCategories: searchCategories,
                isGeneral: searchGeneralQuestions,
                updateSearchParams,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
}
