import {Button, Divider, message, Modal, PageHeader, Popconfirm, Space, Spin, Table} from "antd";
import React, {useState} from "react";
import RAQAClient from "../../../network/api/RaqaClient";
import {useQuery, useQueryClient} from "react-query";
import {history} from "../../../utils/history";
import {useDispatch, useSelector} from "react-redux";
import {CheckOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FileAddOutlined} from "@ant-design/icons";
import {SET_EDITORS} from "../../../redux/actions/types";
import EditorAssignmentModal from "./components/EditorAssignmentModal";

const EditorList = (props) => {

    // Redux useDispatch, used for setting catalog state
    const dispatch = useDispatch();

    // Query Client
    const queryClient = useQueryClient()
    const {data, isLoading: isFetching, refetch: refetchUsers} = useQuery("editors",
        () => fetchUsers()
    );

    // State variable if deletion API call is active
    const [isDeleting, setIsDeleting] = useState(false);
    const [editorsToAssign, setEditorsToAssign] = useState();
    const [editorFromAssign, setEditorFromAssign] = useState();

    // -- MODAL HANDLING ----------------------------------------
    const [inputModalVisible, setInputModalVisible] = useState(false);

    console.log("EditorList loading....")

    // User clicked the 'delete' link of a questions
    const onClickDeleteUser = (editor) => {
        console.log(data);
        console.log(editor);
        const questionInfo = editor.questionInfo;
        console.log("questionInfo.catalogs.length:", editor.catalogs.length);
        console.log("questionInfo.unassigned:", questionInfo.unassigned);

        if(editor.catalogs.length === 0 && questionInfo.unassigned === 0) {
            Modal.confirm(
                {
                    title: "Diesen Editor wirklich löschen?",
                    content: "Soll dieser Editor wirklich dauerhaft gelöscht werden?",
                    onOk: () => {
                        deleteUser(editor.id);
                    },
                    cancelText: "Abbruch"
                }
            )
        }
        else {
            setEditorFromAssign(editor.id);
            const editorsToAssign = data.filter(item => item.id !== editor.id)
            setEditorsToAssign(editorsToAssign);
            setInputModalVisible(true);
        }
    };

    function deleteUser(userId) {
       setIsDeleting(true);
       RAQAClient.deleteUser(userId)
           .then(() => {
                   setIsDeleting(false);
                   refetchUsers().then(r => console.log("Did refresh after delete"));

                   Modal.success({
                       title: "User wurde erfolgreich gelöscht",
                       content: "Der User wurde erfolgreich gelöscht",
                       onOK: () => {
                           Modal.destroyAll();
                       }
                   });
               }
           )
           .catch(error => {
               setIsDeleting(false);
               Modal.error({
                   title: "Löschen fehlgeschlagen",
                   content: "Der User konnte nicht gelöscht werden",
                   onOk: undefined
               });
               console.log("Delete error: ", error);
           })

    }

    const handleCreateButtonClicked = () => {
        history.push({
            pathname: '/editor-create',
        });
    }

    // User clicked the 'edit' link of a catalog
    const editEditor = (editorData) => {
        console.log('Edit Editor clicked', editorData);

        history.push({
            pathname: '/editor-create',
            state: {editor: editorData}
        });

    };

    // User clicked the 'view' link of a catalog
    const viewEditor = (editorData) => {
        console.log('Edit Editor clicked', editorData);

        history.push({
            pathname: '/editor-view',
            state: {editor: editorData}
        });

    };

    // Handler from Modal View
    const handleInputModalConfirmed = (editorToAssign) => {
        console.log("Existing User to reassign catalog: ", editorFromAssign);
        console.log("New User to reassign catalogs: ", editorToAssign);
        if(editorFromAssign !== undefined && editorToAssign !== undefined) {
            RAQAClient.reassignContent(editorFromAssign, editorToAssign)
                .then(() => {
                    RAQAClient.deleteUser(editorFromAssign)
                        .then(() => {
                            refetchUsers().then(r => console.log("Did refresh after delete"));

                            Modal.success({
                                title: "User wurde erfolgreich gelöscht und die Kataloge übertragen",
                                content: "Der User wurde erfolgreich gelöscht",
                                onOK: () => {
                                    Modal.destroyAll();
                                }
                            });
                        })
                        .catch(error => {
                        setIsDeleting(false);
                        Modal.error({
                            title: "Löschen fehlgeschlagen",
                            content: "Der User konnte nicht gelöscht werden, Kataloge und Inhalte wurde jedoch übertragen",
                            onOk: undefined
                        });
                        console.log("Delete error: ", error);
                    })

                    }
                )
                .catch(error => {
                    setIsDeleting(false);
                    Modal.error({
                        title: "Löschen fehlgeschlagen",
                        content: "Der User konnte nicht gelöscht werden",
                        onOk: undefined
                    });
                    console.log("Delete error: ", error);
                })
        }
        setInputModalVisible(false);
    };

    const handleInputModalCanceled = () => {
        setInputModalVisible(false);
        setEditorFromAssign(undefined);
    };


    // -- Async API methods -------------------------------------

    const fetchUsers = async () => {

        const response = await RAQAClient.listUsers("admin",true);
        console.log("Response Users", response)
        const editorUsers = response.data || [];

        console.log("EditorUsers: ", editorUsers);

        dispatch(
            {
                type: SET_EDITORS,
                payload: editorUsers,
            }
        )
        return editorUsers.map(u => {
            u.key = u.id;
            return u;
        })
    }

    const columns = [
        {title: 'Username', dataIndex: 'username', key: 'numberQuestions',  width: '25%'},
        {title: 'Vorname', dataIndex: 'firstName', key: 'firstName',width: '25%'},
        {title: 'Nachname', dataIndex: 'lastName', key: 'lastName', width: '25%'},
        {title: 'Kataloge', dataIndex: 'catalogs', key: 'catalogs',  width: '15%', render: (catalogs, index) => {
                //consoleconsole.log.log("Catalogs: ", catalogs.length)
                return (<span>{catalogs.length}</span>)
            }
            },


        {
            title: 'Aktionen',
            dataIndex: '',
            width: '10%',
            key: 'x',
            render: (text, record) => (
                <Space size="middle" split={<Divider type="vertical"/>}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={e => {
                        e.stopPropagation();
                        editEditor(record)
                    }}><EditOutlined/></a>
                    <a onClick={e => {
                        e.stopPropagation();
                        viewEditor(record);
                    }}>
                        <EyeOutlined/></a>
                    <a onClick={e => {
                        e.stopPropagation();
                        onClickDeleteUser(record);
                    }}>
                        <DeleteOutlined/></a>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Spin spinning={isDeleting}>

                <PageHeader
                    ghost={true}
                    onBack={() => window.history.back()}
                    title="Liste aller Editoren"
                    extra={[
                        <Button key="1" type="primary" onClick={() => handleCreateButtonClicked()}>Neu</Button>
                    ]}>
                </PageHeader>
                <Table
                    columns={columns}
                    dataSource={data}
                />
            </Spin>
            <EditorAssignmentModal
                editorsToAssign = {editorsToAssign}
                visible={inputModalVisible}
                onOkClicked={handleInputModalConfirmed}
                onCancelClicked={handleInputModalCanceled}
            />
        </div>
    )
}

export default EditorList