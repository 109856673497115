import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, Row, Col, Form, Input, Switch, Table, Result, Spin, PageHeader, Modal, Space, Divider} from "antd";
import RAQAClient from "../../../network/api/RaqaClient";
import {useQuery} from "react-query";
import {history} from "../../../utils/history";
import {EditOutlined, ExclamationCircleOutlined, FileAddOutlined} from "@ant-design/icons";
import QuestionView from "../../../components/question/QuestionView";

const fetchAllQuestions = async () => {
    console.log("fetchQuestions called!")
    return RAQAClient.listQuestions().then(response => {
        console.log("RAQAClient.listQuestions() Response:", response);

        return response.data.map(q => {
            const question = {...q};
            question.key = q.uuid;
            return question;
        });
    });
};

const { TextArea } = Input;
const { confirm } = Modal;

const CatalogForm = props  => {

    console.log("--- CatalogForm");

    const location = useLocation();
    const catalog = location.state.catalog;
    console.log("--- Showing content for Catalog ", catalog);

    const defaultCatalogQuestions = catalog.questionUuids;

    const [form] = Form.useForm();

    // The questions for the assigned table and the unassigned table
    const [assignedQuestions, setAssignedQuestions] = useState([])
    const [unassignedQuestions, setUnassignedQuestions] = useState([])

    const [catalogIsPublished, setCatalogIsPublished] = useState(catalog.isPublished)

    // useState for display modal dialog (to many answers for single dialog)
    const [isModalVisible, setIsModalVisible] = useState(false);

    // Loading
    const [didUpload, setUploadState] = useState(false)
    const [isLoading, setLoading] = useState(false)

    // The selected row keys for the assigned table and unassigned table
    const [unassignedSelectedRowKeys, setUnassignedSelectedRowKeys] = useState([])
    const [assignedSelectedRowKeys, setAssignedSelectedRowKeys] = useState([])
    const [selectedAssignedQuestions, setSelectedAssignedQuestions] = useState([])
    const [selectedUnassignedQuestions, setSelectedUnassignedQuestions] = useState([])

    const {data: questions, refetch: refetchCatalogQuestions, isLoading: isFetchingCatalog, isSuccess: isSuccessCatalogQuestions, error: errorCatalogQuestions}
        = useQuery(['catalogQuestions'],
        () => {
            return fetchQuestionsForCatalog()
        },
        {
            onSettled: (data, error) => onQuestionsForCatalogSettled(data, error)
        });


    const { data, refetch: refetchAllQuestions, isQuestionsLoading, isQuestionsSuccess, errorQuestionsLoading }
        = useQuery("allQuestions",
        () => fetchAllQuestions(),
        {enabled: false, onSettled: (data, error) => onAllQuestionsSettled(data, error)}
    );

    /*
    // Run only once
    useEffect(() => {
        console.log("--- useEffect");
        refetchCatalogQuestions();
    }, [])
*/

    // -- Async API methods -------------------------------------
    const fetchQuestionsForCatalog = async () => {
        console.log("fetchQuestionsForCatalog called!")
        const response = await RAQAClient.listQuestionsForCatalog(catalog.uuid);
        console.log("Response listQuestionsForCatalog", response)
        const responseQuestions = response.data || [];
        const questionsWithUuids = responseQuestions.map(q => {
            const question = {...q};
            question.key = q.uuid;
            return question;
        })
        return questionsWithUuids;
    }

    /// New question data (or error) received
    const onQuestionsForCatalogSettled = (data, error) => {
        console.log("--- onQuestionsForCatalogSettled data:", data);
        console.log("--- onQuestionsForCatalogSettled error:", error);
        setAssignedQuestions(data);

        // Now call the useQuery refetch for getting all questions
        refetchAllQuestions();
    };


    /// New question data (or error) received
    const onAllQuestionsSettled = (data, error) => {
        console.log("--- onAllQuestionsSettled data:", data);
        console.log("--- on<allQuestionsSettled error:", error);

        const assignedQuestionsUuids = defaultCatalogQuestions;

        // Create an array with all questions which which are in the assignedQuestionsUuids[] array of the catalog
        const assignedQuestionsArray = data.filter(function(question, index, arr){
            return assignedQuestionsUuids.includes(question.uuid);
        });

        // Remove the assigned questions from the unassigned questions
        const unassignedQuestionsArray =  data.filter(question => !assignedQuestionsArray.includes(question));

        setUnassignedQuestions(unassignedQuestionsArray);

        console.log("onQuestionsSettled finished---");
        clearSelectionRowsInTables();

    };

    function clearSelectionRowsInTables() {
        setAssignedSelectedRowKeys([]);
        setUnassignedSelectedRowKeys([]);
        setSelectedUnassignedQuestions([]);
        setSelectedAssignedQuestions([]);
    }

    const handleRemoveFromAssigned = () => {

        //console.log("---- Button pressed REMOVE FROM ASSIGNED");
        // Make a copy of the unassigned questions
        const newUnassignedArray = [...unassignedQuestions];

        // Add the selected questions to the newAssignedArray and set the assignedQuestions hook
        newUnassignedArray.push(...selectedAssignedQuestions);
        setUnassignedQuestions(newUnassignedArray);
        //console.log("newUnassignedArray: ",newUnassignedArray);

        // Remove the questions from the assigned array
        const newAssignedArray =  assignedQuestions.filter(question => !newUnassignedArray.includes(question));
        //console.log("newAssignedArray: ",newAssignedArray);
        setAssignedQuestions(newAssignedArray);

        // Clear the assigned selection array
        clearSelectionRowsInTables();
    };

    const handleAddToAssigned = () => {

        // console.log("---- Button pressed ADD FROM UNASSIGNED");
        // Make a copy of the assignedQuestions Array
        const newAssignedArray = [...assignedQuestions];

        // Add the selected questions to the newAssignedArray and set the assignedQuestions hook
        newAssignedArray.push(...selectedUnassignedQuestions);
        setAssignedQuestions(newAssignedArray);
        //console.log("newAssignedArray: ",newAssignedArray);

        // Remove the questions from the unassigned array
        const newUnassignedArray =  unassignedQuestions.filter(question => !newAssignedArray.includes(question));
        //console.log("newUnassignedArray: ",newUnassignedArray);
        setUnassignedQuestions(newUnassignedArray);

        // Clear the unassigned selection array
        clearSelectionRowsInTables();
    }

    const rowSelectionAssigned = {
        selectedRowKeys: assignedSelectedRowKeys,
        onChange: (selectedRowKeys, rows) => {
            setSelectedAssignedQuestions([...rows])
            setAssignedSelectedRowKeys(selectedRowKeys)
        },
    };

    const rowSelectionUnassigned = {
        selectedRowKeys: unassignedSelectedRowKeys,
        onChange: (selectedRowKeys, rows) => {
            setSelectedUnassignedQuestions([...rows])
            setUnassignedSelectedRowKeys(selectedRowKeys)
        },
    };

    // Handler functions

    // User clicked the 'add' link of a catalog
    const onClickCreateQuestion = () => {
        console.log('Add Question to Catalog clicked', catalog.uuid );
        history.push({
            pathname: '/question-create',
            state: { catalogId: catalog.uuid }
        });
    };

    const onFinish = (values) => {

        // If a catalog has less then 1 question it can't be published
        if(assignedQuestions.length < 1) {

            if(values.isPublished === true) {
                Modal.info({
                    title: 'Veröffentlichung wurde geändert',
                    content: `Bei Katalogen ohne Fragen kann keine Veröffentlichung erfolgen.`,
                });
                values.isPublished = false;
            }
        }


        // Get an array with only the assigned questions uuids
        const newQuestionUuids = assignedQuestions.map(question => {
            return question.uuid;


        })

        // console.log('onFinish isPublished:', catalogIsPublished);

        // Create the JSON object for the API Call
        const updatedCatalog = {
            title: values.catalogName,
            description: values.description,
            isPublished: values.isPublished,
            questionUuids: newQuestionUuids
        }
       // console.log('onFinish updatedCatalog:', values);
       // console.log('onFinish updatedCatalog:', updatedCatalog);

        RAQAClient.updateCatalog(catalog.uuid, updatedCatalog).then(response => {
                setLoading(false);
                setUploadState(true);
            }
        )
            .catch(error => {
                setLoading(false);
                setIsModalVisible(true);
                form.resetFields();
                console.log("Catch error");
            })

    };

    // HANDLERS
    // User clicked the 'edit' link of a questions
    function onClickCatalogList() {
        console.log('onClickNewCatalog');
        history.push(`/catalog-list`);
    }

    // User clicked the 'edit' link of a questions
    const onEditQuestion = (questionId) => {
        console.log('Edit Question clicked');
        history.push(`/question/${questionId}`);
    };

    function onClickDeleteCatalog(value) {

        confirm({
            title: 'Katalog wirklich löschen?',
            icon: <ExclamationCircleOutlined />,
            content: 'Soll der Katalog wirklich gelöscht werden? Es wird nur der Katalog gelöscht, die zugewiesenen Fragen sind weiterhin verfügbar.',
            cancelText: 'Abbruch',

            // https://stackoverflow.com/questions/52649980/react-ant-modal-props-of-undefined
            // https://github.com/ant-design/ant-design/issues/5269#issuecomment-347836625
            onOk: () => {
                console.log('OK');
                setLoading(true);
                RAQAClient.deleteCatalog(catalog.uuid).then(response => {
                        history.push(`/catalog-list`);
                    }
                )
                    .catch(error => {
                        setLoading(false);
                        setIsModalVisible(true);
                        form.resetFields();
                        console.log("Catch error");
                    })

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    function onSwitchChange(checked) {
        console.log(`Switch value: ${checked}`);
        setCatalogIsPublished(!catalogIsPublished);
    }

    function handleModalOkClick() {
        setIsModalVisible(false);
    }

    // TABLE COLUMNS DEFINITION

    const columnsAssigned = [
        {
            title: (
                <Row  type="flex" align="middle">
                    <Col span={18}>
                        In diesem Fragenkatalog enthaltene Fragen
                    </Col>
                    <Col span={6} style={{textAlign:"right"}}>
                        <Button onClick={handleRemoveFromAssigned} type="secondary">
                            Aus Fragenkatalog entfernen
                        </Button>
                    </Col>
                </Row>

            ),
            dataIndex: 'text',
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <Space size="middle" split={<Divider type="vertical"/>}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={e => {
                        e.stopPropagation();
                        onEditQuestion(record.uuid)
                    }}><EditOutlined /></a>

                </Space>
            ),
        },
    ];

    const columnsUnassigned = [

        {
            title: (
                <Row  type="flex" align="middle">
                    <Col span={18}>
                        Alle nicht enthaltenen Fragen
                    </Col>
                    <Col span={6} style={{textAlign:"right"}}>
                        <Button onClick={handleAddToAssigned} type="secondary">
                            Zu Fragenkatalog hinzufügen
                        </Button>
                    </Col>
                </Row>

            ),
            dataIndex: 'text',
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <Space size="middle" split={<Divider type="vertical"/>}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={e => {
                        e.stopPropagation();
                        onEditQuestion(record.uuid)
                    }}><EditOutlined /></a>

                </Space>
            ),
        },
    ];

    if (didUpload) {
        return (
            <Result
                status="success"
                title="Änderungen durchgeführt!"
                subTitle="Die von von Ihnen durchgeführten Änderungen wurden am Server gespeichert und der der Katalog wurde erfolgreich aktualisiert."
                extra={[

                    <Button key="list" onClick={e => {
                        e.stopPropagation();
                        onClickCatalogList()
                    }}>Zur Liste</Button>,
                ]}
            />
        )
    }

    return (
        <div>
            <h1 style={{paddingBottom: 20, fontSize: 32}}>Fragenkatalog bearbeiten</h1>
            <Form
                form={form}
                name="basic"
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true, catalogName: catalog.title, description: catalog.description, isPublished: catalog.isPublished}}
                autoComplete="off"
                onFinish={onFinish}
            >
                <fieldset disabled={isLoading}>

                <Row
                    justify="center"
                    align="right"
                    gutter={10}
                >
                            <Col span={24} align="right">
                                <Space size="large">
                                    <Button key="buttonList"
                                            type="secondary" onClick={e => {
                                        e.stopPropagation();
                                        onClickCatalogList();
                                    }}>
                                        Zur Liste
                                    </Button>
                                    <Button key="buttonCreate" type="secondary" onClick={e => {
                                        e.stopPropagation();
                                        onClickCreateQuestion();
                                    }}>
                                        Frage erstellen
                                    </Button>
                                    <Button key="buttonSave" type="primary" htmlType="submit">
                                        Katalog speichern
                                    </Button>
                                </Space>
                            </Col>

                </Row>
                <Form.Item
                    label="Name des Fragenkatalogs"
                    name="catalogName"
                    rules={[{ required: true, message: 'Bitte einen Namen für den Fragenkatalog eingeben' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Beschreibung des Fragenkatalogs"
                    name="description"
                >
                    <TextArea rows={3} />
                </Form.Item>
                <Form.Item
                    name="isPublished"
                    valuePropName="checked"
                    noStyle
                >
                        <Switch
                            onChange={onSwitchChange}
                            defaultChecked={catalog.isPublished}
                        />
                </Form.Item>
                    <span>&nbsp;&nbsp;Fragenkatalog veröffentlichen und in der App sichtbar machen</span>
                <div style={{paddingBottom: 20}}>
                </div>


            <Table size="small"
                   columns={columnsAssigned}
                   rowSelection={rowSelectionAssigned}
                   dataSource={assignedQuestions}
                   pagination={{ pageSize: 10 }}

                   expandable={{
                       expandedRowRender: (record) => (
                           <QuestionView showQuestion={false} question={record}/>
                       )
                   }}

            />
                <div style={{paddingBottom: 20}}>
                </div>
            <Table size="small"
                   rowSelection={rowSelectionUnassigned}
                   columns={columnsUnassigned}
                   dataSource={unassignedQuestions}
                   pagination={{ pageSize: 10 }}

                   expandable={{
                       expandedRowRender: (record) => (
                           <QuestionView showQuestion={false} question={record}/>
                       )
                   }}
            />
                </fieldset>
                <div style={{paddingBottom: 20}}/>
                    <Button key="buttonDelete" type="danger" onClick={onClickDeleteCatalog}>
                        Katalog löschen
                    </Button>


            </Form>
            <Modal title="Fehler beim Speichern"
                   open={isModalVisible}
                   onOk={handleModalOkClick}
                   cancelButtonProps={{style: {display: 'none'}}}>
                <p>Bei Speichern der Katalogdaten ist ein Fehler aufgetreten. Sollte der Fehler erneut auftreten, so kontaktieren Sie bitte den Administrator.</p>
            </Modal>
        </div>
    )
}

export default CatalogForm;