import {Button, Card, Col, Result, Row} from "antd";
import React, {useContext} from "react";
import QuestionView from "../../../components/question/QuestionView";
import {QuestionFormContext} from "../QuestionFormContext";
import {useHistory} from "react-router-dom";

const QuestionEditorUploadFinishView = ({newQuestionHandler}) => {

    let history = useHistory();

    const {
        authRole,
        responseQuestion,
        questionUuid
    }
        = useContext(QuestionFormContext);

    function goToQuestionList() {
        history.push(`/question-list`);
    }

    function createNewQuestion(value) {
        newQuestionHandler();
    }

    return (
        <div style={{margin: 'auto', width: '70%'}}>
            <Result
                status="success"
                title="Frage wurde erfolgreich gespeichert"
                subTitle="Die Frage wurde erfolgreich am Server gespeichert. Sie können später die Frage zu einem Fragenkatalog hinzufügen."
                extra={[
                    <Button type="primary" key="console" onClick={createNewQuestion}>
                        Neue Frage
                    </Button>,
                    <Button key="list" onClick={e => {
                        e.stopPropagation();
                        goToQuestionList()
                    }}>Zur meinen Fragen</Button>,
                ]}
            />

            {responseQuestion !== undefined &&
                <Card  style={{height: '100%', width: '100%'}}>
                    <div style={{height: '100%', width: '100%'}}>
                        <QuestionView showQuestion={true} question={responseQuestion}/>
                    </div>
                </Card>
            }
            <center>
                <p style={{"paddingTop": "20px"}}>Die Frage wurde mit der ID {questionUuid} gespeichert.
                </p>
            </center>
        </div>
    )
}

export default QuestionEditorUploadFinishView;
