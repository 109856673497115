import {SET_EDITORS} from "../actions/types";

const initialState = {
    editors:[],
};

export default function administration(state = initialState, action) {
    const { type, payload } = action;
    console.log("Dispatching editors with payload: "+payload+ " and Action: "+action);

    switch (type) {
        case SET_EDITORS:
            console.log("Dispatching SET_EDITORS with payload: "+payload);
            return {
                ...state,
                editors: payload
            };


        default:
            return state;
    }
}