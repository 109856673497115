import {Button, Col, Form, message, Modal, Row, Select, Space, Spin} from "antd";
import React, {useContext, useEffect, useRef, useState} from "react";
import {QuestionContext} from "../../../context/questionContext";
import {QuestionFormContext} from "../QuestionFormContext";
import RAQAClient from "../../../network/api/RaqaClient";
import {useDispatch, useSelector} from "react-redux";
import QuestionEditorUploadFinishView from "./question.editor.upload-finish-view";
import {SET_CATEGORIES} from "../../../redux/actions/types";

const {Option} = Select;

const QuestionEditorForm = ({children}) => {

    const {
        setQuestionText,
        isOnlySingleAnswer,
        setOnlySingleAnswerAllowed,
        question,
        resetQuestion,
        answers,
        resetAnswers,
        addAnswer,
    }
        = useContext(QuestionContext);

    const {
        isAddMode,
        questionUuid,
        questionEditorId,
        currentUserId,
        fileList,
        isLoading,
        reduxCategories,
        setAvailableCategories,
        setFileList,
        setIsLoading,
        setIsAddMode,
        setQuestionUuid,
        setImageUuidList,
        setResponseQuestion,
        setQuestionEditorId,
        setQuestionEditorName,
        setEditorLastModifiedName,
        deleteQuestion
    } = useContext(QuestionFormContext);

    // Get the form-components
    const [form] = Form.useForm();
    // Dispatch for Redux
    const dispatch = useDispatch();

    // useState for display modal dialog (no answer is set to correct)
    const [isCorrectAnswersModalVisible, setIsCorrectAnswersModalVisible] = useState(false);

    // States for the response after an insert or update of a questions
    const [responseError, setResponseError] = useState(false);
    const [responseErrorMessage, setResponseErrorMessage] = useState("");
    const [didUpload, setUploadState] = useState(false)

    // Redux editors
    const editors = useSelector(state => state.administration?.editors);

    console.log("QuestionEditorForm - Add Mode: "+isAddMode);

    useEffect(() => {
        console.log("useEffect isAddMode called");
        if (isAddMode) {
            // Reset form-components, questions and answers
            form.resetFields();
            resetQuestion();
            resetAnswers();
            setQuestionEditorName("n/a")
            setEditorLastModifiedName("n/a")
            // Preset the Default Catalog
            form.setFieldsValue({
                catalogs: ["DEFAULT_CATALOG"]
            });

            setFileList([]);

        } else {
            // reset questions and answers
            resetQuestion();
            resetAnswers();

            RAQAClient.getQuestion(questionUuid).then(response => {
                console.log("Response getQuestion: ", response);

                // Set the userId (the one who created the question) of the question we are displaying
                setQuestionEditorId(response.data.userId)

                let catalogsIds = [...response.data.catalogUuids];
                if (response.data.isGeneral === true) {
                    catalogsIds.unshift("DEFAULT_CATALOG");
                }

                form.setFieldsValue({
                    question: response.data.text,
                    answerInfo: response.data.answerInfo,
                    answerUrl: response.data.answerUrl,
                    isPublished: response.data.isPublished,
                    categories: response.data.categories,
                    catalogs: catalogsIds
                });

                // Set the switch if single only are allowed
                if (response.data.type === "mult") {
                    setOnlySingleAnswerAllowed(false);
                } else {
                    setOnlySingleAnswerAllowed(true);
                }

                const responseAnswers = response.data.answers;
                responseAnswers.forEach(answer => {
                    const newAnswer = {};
                    newAnswer.title = answer.text;
                    newAnswer.correct = answer.correct;
                    addAnswer(newAnswer);
                });

                // Set the image Uuids in the context, we need them for downloading
                // and displaying the images
                if(response.data.imageUuids) {
                    console.log("*****  IMAGES FOUND ****")

                    setImageUuidList(response.data.imageUuids);
                }
                else {
                    console.log("***** NO IMAGES FOUND ****")
                }

                // Set the questions text
                setQuestionText(response.data.text);

                let questionCreator = editors.find(editor => editor.id === response.data.userId);
                if(questionCreator !== undefined) {
                    setQuestionEditorName(questionCreator.firstName + " "+questionCreator.lastName)
                }
                else {
                    setQuestionEditorName("Unbekannt")
                }

                if(response.data.modifiedByUser !== undefined) {

                    if (response.data.modifiedByUser === 0) {
                        setEditorLastModifiedName("-")
                    }
                    else {
                        let editorWhoLastModified = editors.find(editor => editor.id === response.data.modifiedByUser);
                        if(editorWhoLastModified !== undefined) {
                            setEditorLastModifiedName(editorWhoLastModified.firstName + " "+editorWhoLastModified.lastName)
                        }
                        else {
                            setEditorLastModifiedName("Unbekannt")
                        }
                    }
                }

            }).catch(err => {
                errorAlert('Fehler beim Laden', 'Die Frage konnte nicht geladen werden. Versuchen Sie es später erneut oder kontaktieren Sie den Administrator.');
                console.log('error', err);
            });
        }
    }, [isAddMode]);

    function newQuestion(value) {
        form.resetFields();
        setIsLoading(false);
        setUploadState(false);
        resetQuestion();
        setIsAddMode(true);
        setFileList([]);
        setImageUuidList([]);
        // Preset the Default Catalog
        form.setFieldsValue({
            catalogs: ["DEFAULT_CATALOG"]
        });
    }

    const onFinish = (values: any) => {

        // Count how many correct answers we have
        const answersArray = answers.map(answer => {
            return {text: answer.title, correct: answer.correct};
        });
        const countCorrectAnswers = answersArray.filter((obj) => obj.correct === true).length;

        // Show warning if we don't have at least one correct answer
        (countCorrectAnswers > 0) ? saveOrUpdateQuestion() : setIsCorrectAnswersModalVisible(true);

    };

    // OK Click Handler for modal if none of the answers is marked as correct
    // Modal is displayed when none of the answers is marked as correct
    function handleNoCorrectAnswerOk() {
        setIsCorrectAnswersModalVisible(false);
        saveOrUpdateQuestion()
    }

    // OK Click Handler for modal if none of the answers is marked as correct
    // Modal is displayed when none of the answers is marked as correct
    function handleNoCorrectAnswerCancel() {
        setIsCorrectAnswersModalVisible(false);
    }

    function saveOrUpdateQuestion() {

        form.validateFields().then((values) => {

            // Build a new array
            const answersArray = answers.map(answer => {
                return {text: answer.title, correct: answer.correct};
            });

            // Get the question type
            const questionType = (isOnlySingleAnswer === true) ? "norm" : "mult";

            // Check if the categories are undefined and
            const categories = (values.categories === undefined) ? [] : values.categories;
            let catalogs = (values.catalogs === undefined) ? [] : values.catalogs;
            const isInGeneralCatalog = catalogs.includes("DEFAULT_CATALOG");

            if (isInGeneralCatalog === true) {
                catalogs = arrayRemove(catalogs, "DEFAULT_CATALOG");
            }

            // Set loading to true
            setIsLoading(true);
            const jsonQuestion = {
                text: values.question,
                answers: answersArray,
                categories: categories,
                catalogUuids: catalogs,
                isPublished: values.isPublished || false,
                isGeneral: isInGeneralCatalog,
                type: questionType,
                answerUrl: values.answerUrl || null,
                answerInfo: values.answerInfo || null
            }

            // Create the FormData for the Multipart Upload
            const formData = new FormData();
            formData.append("question", JSON.stringify(jsonQuestion).toString());

            // Create the parts for the images
            fileList.forEach((file, index) => {
                formData.append("image"+index,  file);
            });

            if (isAddMode === true) {
                // Make the API call and save the question
                createQuestionWithImages(formData)
                    .then(response => {

                            if(response.status === 200) {
                                console.log("Response createQuestionWithImages: ", response);
                                console.log("Response createQuestionWithImages: ", response.data);
                                setQuestionUuid(response.data.questionUuid);
                                loadUpdatedOrInsertedQuestionFromServer(response.data.questionUuid);
                                setIsLoading(false);
                                setUploadState(true);
                                addNewCategoriesToStore(values.categories);
                            }
                            else {
                                setIsLoading(false);
                                setResponseError(true)
                                setResponseErrorMessage("Falscher Status Code")
                                errorAlert("Fehler beim Speichern der Frage", `Der Server antwortete mit dem Status Code ${response.status}`)
                            }
                        }
                    )
                    .catch(error => {
                        setIsLoading(false);
                        setResponseError(true)
                        setResponseErrorMessage(error.message)
                        console.log("Catch error", error.message);
                        errorAlert('Fehler beim Speichern', 'Die Frage konnte nicht gespeichert werden. ' +
                            'Versuchen Sie es später erneut oder kontaktieren Sie den Administrator. ' +
                            `Fehler: ${error.message}`);

                    })
            } else {
                console.log("JSON Question: ", jsonQuestion);
                updateQuestion(questionUuid, jsonQuestion)
                    .then(response => {
                            console.log("Response updateQuestion: ");
                             console.log("Response updateQuestion: ", response);

                            if (response.status === 200) {
                                setQuestionUuid(response.data.questionId);
                                loadUpdatedOrInsertedQuestionFromServer(response.data.questionId);
                                setIsLoading(false);
                                setUploadState(true);
                                addNewCategoriesToStore(values.categories);
                            }
                            else {
                                errorAlert("Fehler beim Updaten der Frage", `Der Server antwortete mit dem Status Code ${response.status}`)
                            }
                        }
                    )
                    .catch(error => {
                        setIsLoading(false);
                        setResponseError(true)
                        setResponseErrorMessage(error.message)
                        console.log("Catch error", error.message);
                        errorAlert('Fehler beim Speichern', 'Die Frage konnte nicht geupdatet werden. ' +
                            'Versuchen Sie es später erneut oder kontaktieren Sie den Administrator. ' +
                            `Fehler: ${error.message}`);
                    })
            }
        })
    }

    function loadUpdatedOrInsertedQuestionFromServer(questionId) {
        console.log("loadUpdatedOrInsertedQuestionFromServer QuestionId: "+questionId);

        RAQAClient.getQuestion(questionId)
            .then(response => {
                console.log("Response after insert/update: ", response);

                if(response.status === 200) {
                    setResponseQuestion(response.data)
                    setResponseError(false)
                    setResponseErrorMessage("")
                }
                else {
                    errorAlert("Fehler beim Laden der Frage", `Der Server antwortete mit dem Status Code ${response.data}`)
                }
            })
            .catch(error => {
                console.log("Catch error", error);
                console.log( error);
                errorAlert("Fehler beim Laden der Frage", "Die Frage konnte nicht geladen werden")
                setResponseError(true)
            })
    }

    const createQuestionWithImages = async (formData) => {
        try {
            return await RAQAClient.createQuestionWithImages(formData);
        } catch (error) {
            showError(error)
            throw(error);
        }
    }

    const updateQuestion = async (id, question) => {
        try {
            return await RAQAClient.updateQuestion(id, question);
        } catch (error) {
            showError(error)
            throw(error);
        }
    }

    function addNewCategoriesToStore(selectedCategories) {

        // Update our categories because there could be new values added
        const allCategories = reduxCategories.concat(selectedCategories)
        const uniqueCategories = allCategories.filter((item, pos) => allCategories.indexOf(item) === pos)

        // Create the Option elements
        const selectOptions = [];
        uniqueCategories.forEach(
            element => selectOptions.push(
                <Option key={element} value={element}>
                    {element}
                </Option>)
        );
        // Use the hook to store it
        setAvailableCategories(selectOptions);

        // Update our Redux Store
        dispatch({
            type: SET_CATEGORIES,
            payload: uniqueCategories
        })
    }

    function showError(error) {
        console.log('Error during question creation', error);
        const response = error.response;
        if (response) {
            const messageContent = (
                <>
                    <h2>Es ist ein Fehler aufgetreten</h2>
                    <h3>Status: {`${response.status} (${response.statusText})`}</h3>
                    <h3>{`Grund: ${response.data?.userMessage || response.data?.message || response.data?.code || 'nicht verfügbar'}`}</h3>
                </>
            );
            message.error(messageContent);
        } else {
            message.error(error.toString());
        }
    }

    function arrayRemove(arr, value) {
        return arr.filter(function (ele) {
            return ele !== value;
        });
    }

    function errorAlert(title, infotext) {
        Modal.error({
            title: title,
            content: infotext,
        });
    }

    if(didUpload) {
        return <QuestionEditorUploadFinishView newQuestionHandler={newQuestion}/>
    }
    else {
        return (
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{questions: question}}
            >
                <fieldset disabled={isLoading}>
                    {children}
                    <div style={{paddingBottom: 25}}/>

                    {isAddMode === false &&

                        <Row
                            justify="center"
                            align="middle"
                            gutter={[28, 24]}
                            className="todos-container"
                        >
                            <Col style={{textAlign: "right"}} xs={{span: 22}} sm={{span: 22}} md={{span: 2}} lg={{span: 2}}
                                 xl={{span: 22}}>
                                <Button style={{textAlign: 'right'}} key="buttonDelete" type="danger"
                                        disabled={questionEditorId !== currentUserId}
                                        onClick={deleteQuestion}>
                                    Frage löschen
                                </Button>
                            </Col>
                        </Row>
                    }
                </fieldset>

                <Modal title="Keine der Antworten wurde als richtig markiert"
                       open={isCorrectAnswersModalVisible}
                       onOk={handleNoCorrectAnswerOk}
                       okText="Trotzdem speichern"
                       onCancel={handleNoCorrectAnswerCancel}
                >
                    <p>Keine der Antworten wurde als richtig markiert. Soll die Frage trotzdem gespeichert werden?</p>
                </Modal>
            </Form>

        )
    }
}

export default QuestionEditorForm
