import {
    Spin,
    Row,
    Col,
    Divider,
    Modal,
    PageHeader,
    Popconfirm,
    Space,
    Table,
    Tag,
    Input,
    Typography,
    Select, Form, Button
} from "antd";
import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import RAQAClient from "../../../network/api/RaqaClient";
import {history} from '../../../utils/history';
import QuestionView from "../../../components/question/QuestionView";
import {CheckOutlined, EyeInvisibleOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import "antd/dist/antd.min.css";
import {useSelector} from "react-redux";
import {Option} from "antd/es/mentions";
import UpdateIcon from "../../../components/other/UpdateIcon";
import UpdateVisibilityIcon from "../../../components/other/UpdateIcon";
import resultListColumns from "./resultListColumns";

const QuestionList = (props) => {

    const currentUserId = useSelector(state => state.auth?.user?.user?.id);

    // useState Hooks
    const [isDeleting, setIsDeleting] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [catalogListWithGeneral, setCatalogListWithGeneral] = useState([]);
    const [availableCategories, setAvailableCategories] = useState([])
    const [tableFilterCategories, setTableFilterCategories] = useState([])

    // Search & Category Filtering
    const [searchString, setSearchString] = useState("")
    const [filteredCategories, setFilteredCategories] = useState([])
    const [filteredCategoryQuestions, setFilteredCategoryQuestions] = useState([])

    const [form] = Form.useForm();

    // Redux State
    const reduxCatalogs = useSelector(state => state.questions?.catalogs);
    const reduxCategories = useSelector(state => state.questions?.categories);

    /// New question data (or error) received
    const onQuestionsSettled = (data, error) => {
        const categoriesFromAllQuestions = data.flatMap(question => question.categories)
        const sortedCategories = [...new Set(categoriesFromAllQuestions)].sort().map(category => {
                return {
                    text: category,
                    value: category,
                    key: category,
                }
        })

        setTableFilterCategories(sortedCategories)
        setTableData(data);
    };

    // useQuery Hook
    const {data: questions, refetch: refetchQuestions, isLoading: isFetching, isSuccess: isSuccess2, error: error2}
        = useQuery(['jobs'],
        () => {
            return fetchQuestions()
        },
        {
            onSettled: (data, error) => onQuestionsSettled(data, error)
        });


    useEffect(() => {

        console.log("UseEffect");

        // Get all catalogs from the user
        let catalogList = reduxCatalogs
            .filter(catalog => catalog.userId === currentUserId)
            .map(catalog => {
            return {
                text: catalog.title,
                value: catalog.uuid,
                key: catalog.uuid,
            }
        },[])

       catalogList.unshift({text: "Allgemein", value: "0000-0001", key: "0000000000"})
       setCatalogListWithGeneral(catalogList);

        if (reduxCategories !== undefined && reduxCategories !== null) {
            const selectOptions = [];
            reduxCategories.forEach(

                element => {
                    console.log("selectOptions entry:", element);

                    if(element !== undefined && element !== null) {
                        selectOptions.push(
                            <Select.Option key={element} value={element}>
                                {element}
                            </Select.Option>
                        )
                    }
                }
            );
            setAvailableCategories(selectOptions);
        }

    }, [reduxCatalogs, reduxCategories])

    // Update the questions if the search string or the filtered categories are changing
    useEffect(() => {
        console.log("UseEffect [searchString, filteredCategories]");
        updateQuestionListByFilters()
    }, [searchString, filteredCategories])


    // API Call

    const fetchQuestions = async (officeId) => {
        console.log('begin fetching questions....');
        return RAQAClient.listQuestions().then(response => {
            console.log('Questions from server received: ' + response.data.length);

            const remoteQuestions = response.data.map(q => {
                const question = {...q};
                question.key = q.uuid;
                question.isUpdating = false;
                question.catalogNames = [];

                question.catalogUuids.forEach(uuid => {
                    const catalog = reduxCatalogs.find(x => x.uuid === uuid)
                   if(catalog !== undefined) {
                       question.catalogNames.push(catalog.title);
                   }
                });

                if(question.isGeneral === true) {
                   // console.log("IS GENERAL QUESTION");
                    question.catalogNames.unshift("Allgemein");
                }
                return question;
            });

            //console.log("List of all questions: ", remoteQuestions)
            return remoteQuestions;

        });
    };


    const handleTableChange = (pagination, filters, sorter) => {
        console.log("handleTableChange", pagination, filters, sorter);
    };

    function updateQuestionListByFilters()  {

        if (filteredCategories.length > 0) {
            if(searchString !== "") {
                setTableData(filteredCategoryQuestions.filter(question => {
                        return question.text.toLowerCase().includes(searchString.toLowerCase())
                    }
                ))
            }
            else {
                setTableData(filteredCategoryQuestions)
            }
        }
        else {
            if(searchString !== "") {
                setTableData(questions.filter(question => {
                        return question.text.toLowerCase().includes(searchString.toLowerCase())
                    }
                ))
            }
            else {
                setTableData(questions)
            }
        }
    }

    const onTextSearchChanged = (textSearch) => {
        setSearchString(textSearch)
    };

    const handleCategoryChange = (selectedCategories: string[]) => {
        console.log(`selected categories ${selectedCategories}`)

        setFilteredCategories(selectedCategories);

        if(selectedCategories.length > 0) {
            const categoryFilteredQuestions = [];

            for (const category of selectedCategories) {
                questions.filter(question => {
                        if(question.categories.includes(category)) {
                            categoryFilteredQuestions.push(question)
                        }
                    }
                )
            }
            //console.log(categoryFilteredQuestions.length)

            setFilteredCategoryQuestions(categoryFilteredQuestions)
        }
        else {
            setFilteredCategoryQuestions([])
        }

    };


    // User clicked the 'edit' link of a questions
    const editQuestion = (questionId) => {
        console.log('Edit Question clicked');
        history.push(`/question/${questionId}`);
    };

    // User clicked the 'delete' link of a questions
    const  deleteQuestion = (questionsId) => {
        setIsDeleting(true);

        RAQAClient.deleteQuestion(questionsId)
            .then( () => {
                    setIsDeleting(false);
                    setSearchString("")
                    refetchQuestions().then( () => {
                       // updateQuestionListByFilters();
                        form.resetFields();
                        refetchQuestions();
                    })
            }
            )
            .catch(error => {
                setIsDeleting(false);
                Modal.error({
                    title: "Löschen fehlgeschlagen",
                    content: "Die Frage konnte nicht gelöscht werden",
                    onOk:  undefined
                });
                console.log("Delete error: ", error);
            })
    };

    const  refreshTable = () => {
        refetchQuestions();
    }

    const myColumn = resultListColumns(catalogListWithGeneral, tableFilterCategories, editQuestion, deleteQuestion,refreshTable)

    return (
        <div>
            <Spin spinning={isDeleting || isFetching}>

                <h2>Suchkriterien</h2>
                <Form form={form}>
                <Row type="flex" gutter={0} style={{ marginBottom: 0 }}>
                    <Col span={24} offset={0}>
                        <Form.Item
                            label="Suchbegriff"
                            name="Suche"
                            id="search"
                        >
                        <Input.Search
                            allowClear
                            onSearch={onTextSearchChanged}
                        />
                        </Form.Item>
                    </Col>
                </Row>
                <Row type="flex" gutter={10} style={{ marginBottom: 10 }}>
                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}}>
                        <Form.Item
                            label="Alle Kategorien"
                            name="categories"
                            id="categories"
                        >
                            <Select
                                allowClear={true}
                                mode="tags"
                                size={"medium"}
                                //defaultValue={filteredCategories}
                                placeholder="Kategorie wählen"
                                onChange={handleCategoryChange}
                                style={{width: '100%'}}
                            >
                                {availableCategories}
                            </Select>
                        </Form.Item>
                    </Col>
            </Row>
            </Form>

            <Col span={24}>

                <PageHeader
                    title="Fragenliste"
                    id="pageHeader"
                    subTitle="Liste aller von mir erstellten Fragen"
                />

                <Table
                    columns={myColumn}
                    dataSource={tableData}
                    rowKey={question => question.uuid}
                    pagination={true}
                    onChange={handleTableChange}
                    size="default"
                    expandable={{
                        expandedRowRender: (record) => (
                            <QuestionView showQuestion={false} question={record}/>
                        )
                    }}
                />

            </Col>
            </Spin>
        </div>
    )
}

export default QuestionList;
