import React, {useContext, useEffect, useRef, useState} from 'react';
import {QuestionContext} from "../../../../context/questionContext";
import {Tooltip, List, Button, Popconfirm, Switch, Input, Row, Col} from 'antd';
import {CloseOutlined, CheckOutlined, DeleteFilled} from '@ant-design/icons';
import {DeleteOutlined} from "@ant-design/icons";


export default function AnswerItem({answer, reference}) {
    const {deleteAnswer, toggleAnswer, changeAnswer} = useContext(QuestionContext);

    const [isEditMode, setEditMode] = useState(false);
    const [inputAnswer, setInputAnswer] = useState(answer.title);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            console.log("Click detected")

            if (reference.current && !reference.current.contains(event.target)) {
                console.log("Outside click detected!!!")
                setEditMode(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [reference]);

    const onToggleChange = e => {
        toggleAnswer(answer.id, e);
    };

    const onEditClicked = e => {
        console.log("setEditMode to true")
        setEditMode(true)
    };

    const onSaveAnswerClicked = e => {
        console.log("onSaveAnswerClicked: "+inputAnswer)
        changeAnswer(answer.id, inputAnswer)
        setEditMode(false)

    };

    const onAnswerChange = e => {
        console.log('Answer Change:', e.target.value);
        setInputAnswer(e.target.value)
    };

    if  (isEditMode === false) {
        return (
            <div>
                <List.Item
                    actions={[
                <Tooltip
                    title={answer.correct ? 'Als falsch markieren' : 'Als richtig markieren'}
                >
                    <Switch
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                        onChange={onToggleChange}
                        defaultChecked={answer.correct}
                        checked={answer.correct}
                    />
                </Tooltip>,
                <Popconfirm
                    title="Diese Antwort wirklich löschen?"
                    cancelText={"Abbruch"}
                    onConfirm={() => {
                        deleteAnswer(answer.id);
                    }}
                >
                    <a style={{color: '#F00', fontSize: '125%'}} onClick={e => e.stopPropagation()}><DeleteOutlined/></a>
                </Popconfirm>,
                <a style={{color: '#F00', fontSize: '125%'}} onClick={onEditClicked}>Edit</a>

            ]}
            className="list-item"
            key={answer.id}
        >
            <div className={answer.correct ? 'answer-correct' : 'answer-false'}>
                {answer.title}
            </div>
        </List.Item>
            </div>
        )
    }
    else {
        return (
            <div ref={reference}>
                 <Row align="right">
                    <Col span={20}>
                        <Input value={inputAnswer} onChange={onAnswerChange}/>
                    </Col>
                    <Col span={4} align="right">
                        <Button onClick={onSaveAnswerClicked}>Fertig</Button>
                    </Col>
                 </Row>
            </div>

        )
    }
}
