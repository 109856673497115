import React, { useEffect } from "react";
import { useState } from "react";
import {useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import {Button, Form, Card, Col, Divider, Input, Modal, Popconfirm, Space, Row, Spin, Table} from "antd";
import {CheckOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import RAQAClient from '../../network/api/RaqaClient';
import {history} from "../../utils/history";
import ContentGroupAddCatalogModal from "./ContentGroupAddCatalogModal";
import ContentGroupAddPlayerModal from "./ContentGroupAddPlayerModal";
import {SET_EDITORS} from "../../redux/actions/types";

const ContentGroupUsersTable = ({contentGroupId}) => {

    const [form] = Form.useForm();

    const [isDeleting, setIsDeleting] = useState(false);
    // Flag is show PlayerModal is visible
    const [showPlayerModal, setShowPlayerModal] = useState(false);

    // Players we want to add in the modal
    const [allPlayers, setAllPlayers] = useState([]);

    // The Search string for filtering the players
    const [searchString, setSearchString] = useState("");
    const [filteredAssignedPlayers, setFilteredAssignedPlayers] = useState();

    // USE-QUERY
    const { data: assignedPlayers, refetch, isLoading: isFetching, }
        = useQuery(['contentGroupPlayers', contentGroupId, 'players'],
        () => fetchContentGroupUsers(contentGroupId), {
        keepPreviousData: true
    });


    const fetchContentGroupUsers = async (contentGroupId) => {
        try {
            const data = await RAQAClient.getContentGroupUsers(contentGroupId);
            console.log('fetchContentGroupUsers.....', data?.data?.users);
            return data?.data?.users || [];
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    useEffect(() => {
        setFilteredAssignedPlayers(assignedPlayers);
    }, [assignedPlayers]);

    // When the search string changes we do a new search and the set the result
    useEffect(() => {
        if(assignedPlayers) {
            const searchResult = assignedPlayers.filter(player => player.lastName.toLowerCase().includes(searchString.toLowerCase()) || player.firstName.toLowerCase().includes(searchString.toLowerCase()))
            setFilteredAssignedPlayers(searchResult);
        }
        else {
            setFilteredAssignedPlayers([]);
        }
    }, [searchString]);

    // Called when the search input changes
    const onSearchChange = (e) => {
        const inputText = e.target.value;

        if (inputText.length > 2) {
            setSearchString(inputText);
        } else {
            setFilteredAssignedPlayers(assignedPlayers);
            setSearchString("");
        }
    }

    const viewPlayer = (playerData) => {
        console.log('Player clicked', playerData);

        history.push({
            pathname: '/player-detail',
            state: {player: playerData}
        });

    };

    const onRemovePlayerFromContentGroup = (record) => {
        //console.log("**** Starting to remove player from content group ****");

        setIsDeleting(true);

        RAQAClient.removePlayersFromContentGroup(contentGroupId, [record.id])
            .then((response) => {
                    //console.log("onRemovePlayerFromContentGroup finished", response);

                    setIsDeleting(false);
                    //console.log("**** Finished remove player from content group ****", response);

                    Modal.success({
                        title: "User entfernt",
                        content: "Der User wurde erfolgreich von der Gruppe entfernt",
                        onOk: refetch
                    });
                }
            )
            .catch(error => {
                console.log("onRemovePlayerFromContentGroup error", error);
                //console.log("**** Finished with error to remove player from content group ****");

                setIsDeleting(false);
                Modal.error({
                    title: "Löschen fehlgeschlagen",
                    content: "Der User konnte nicht entfernt werden",
                    onOk: undefined
                });
                console.log("Delete error: ", error);
            })
    };

    const onClickShowPlayerModal = (e) => {
        e.stopPropagation();
        RAQAClient.listUsers("player", false).then(
            (response) => {
                console.log("allPlayers: ", response.data);
                console.log("assignedPlayers: ", assignedPlayers);

                //const notSelectedPlayers = response.data.filter((item) => !assignedPlayers.includes(item));
                const notSelectedPlayers = response.data.filter(allPlayers => !assignedPlayers.some(assignedPlayer => allPlayers.id === assignedPlayer.id))

                const playersWithKey =  notSelectedPlayers.map(u => {
                    u.key = u.id;
                    return u;
                })

                // Now remove the players from already selectedPlayers
                setAllPlayers(playersWithKey)
                setShowPlayerModal(true);
            }
        );
    };


    const columns = [
        {title: 'Vorname', dataIndex: 'firstName'},
        {title: 'Nachname', dataIndex: 'lastName'},
        {title: 'E-Mail', dataIndex: 'email'},
        {title: 'Letzter Login', dataIndex: 'lastLoginAt',  render: (loginTimestamp, index) => {
                if(loginTimestamp === null) {
                    return "Nicht vorhanden"
                }
                const lastLoginAt = new Date(loginTimestamp * 1000);
                const result = lastLoginAt.toLocaleDateString("de-DE", { // you can use undefined as first argument
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                });
                return result+ " Uhr"
            }
        },
        {title: 'Account seit', dataIndex: 'createdAt', render: (createdAtTimestamp, index) => {
                if(createdAtTimestamp === null) {
                    return "Nicht vorhanden"
                }
                const lastLoginAt = new Date(createdAtTimestamp * 1000);
                const result = lastLoginAt.toLocaleDateString("de-DE", { // you can use undefined as first argument
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });
                return result
            }
        },
        {
            title: 'Aktionen',
            dataIndex: '',
            render: (text, record) => (
                <Space size="middle" split={<Divider type="vertical"/>}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

                    <a onClick={e => {
                        e.stopPropagation();
                        viewPlayer(record);
                    }}>
                        <EyeOutlined/></a>

                    <Popconfirm
                        title="Wollen Sie diesen Spieler wirklich aus der Gruppe entfernen?"
                        onConfirm={e => {
                            e.stopPropagation();
                            onRemovePlayerFromContentGroup(record)
                        }}
                        onCancel={e => e.stopPropagation()}
                        okText="Ja"
                        cancelText="Nein"
                    >
                        <a onClick={e => e.stopPropagation()}><DeleteOutlined/></a>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Row style={{"marginTop":"30px", "marginBottom":"0px"}}>
            <Col span={6}>
                <h3>{assignedPlayers ? assignedPlayers.length : "Keine"} zugewiesene Spieler</h3>
            </Col>
                <Col offset={1} span={10}>
                    <Form
                        form={form}
                        initialValues={{ searchString: "" }}
                    >
                        <Form.Item
                            label="Suche nach Namen:"
                            name="searchString"
                        >
                            <Input
                                placeholder="Nach Namen suchen (mind. 3 Zeichen)"
                                onChange={onSearchChange}
                                allowClear
                            />
                        </Form.Item>
                    </Form>                </Col>
            <Col offset={1} span={6} style={{"textAlign": "right"}}>
                <Button key="buttonAddCatalog" type="primary" onClick={onClickShowPlayerModal}>
                    Spieler hinzufügen
                </Button>
            </Col>
        </Row>


            <Table size="small"
                   columns={columns}
                   dataSource={filteredAssignedPlayers}
                   pagination={{ pageSize: 10 }}
                   rowKey={record => record.id}
            />

            <ContentGroupAddPlayerModal
                contentGroupId={contentGroupId}
                open={showPlayerModal}
                allPlayers={allPlayers}
                assignedPlayers={assignedPlayers}
                onCancel={() => {
                    setShowPlayerModal(false);
                }}
                onSave={() => {
                    setSearchString("");
                    form.resetFields();
                    refetch();
                    setShowPlayerModal(false);
                }}
            />
        </div>

    );
};

export default ContentGroupUsersTable;
