import { Button, Col, Descriptions, Divider, Modal, Row } from "antd";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import { useQuery } from "react-query";
import RAQAClient from "../../../network/api/RaqaClient";
import UserDetailView from "../../../components/user/UserDetailView";
import PasswordChangeModal from "../../../components/user/PasswordChangeModal";
import DifficultQuestionsTable from "../../../components/question/DifficultQuestionsTable";

const QuestionStatsView = (props) => {

    // useQuery Hook
    const { data: stats, refetch, isLoading, isSuccess, error } = useQuery(['stats'],
        () => fetchStatistics());

    const fetchStatistics = async () => {
        return RAQAClient.getStatistics()
            .then(response => {
                console.log("Stats", response)
                return response.data;
            }).catch(error => {
                console.log("Error", error);
            });
    };

    return (
        <div>
            <h2>Fragen nach Schwierigkeit</h2>
            <DifficultQuestionsTable />
        </div>
    )
}

export default QuestionStatsView;
