import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import questions from "./questions";
import administration from "./editors";

export default combineReducers({
    auth,
    message,
    questions,
    administration
});