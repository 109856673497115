import React, {createContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import RAQAClient from "../../network/api/RaqaClient";
import {Modal} from "antd";
import RaqaClient from "../../network/api/RaqaClient";

const {confirm} = Modal;

// Create context
export const QuestionFormContext = createContext();

export function QuestionFormContentProvider({ addQuestionMode, questionId, children }) {

    // The images file list for the upload
    const [isAddMode, setIsAddMode] = useState(addQuestionMode);

    // The images file list for the upload
    const [fileList, setFileList] = useState([]);

    // The images Uuids for the edit mode
    const [imageUuidList, setImageUuidList] = useState([]);

    // The current questionId, only set if in edit mode
    const [questionUuid, setQuestionUuid] = useState(questionId);

    // Loading state
    const [isLoading, setIsLoading] = useState(false);

    // The response (a question) after the question was created or updated
    const [responseQuestion, setResponseQuestion] = useState();

    // Name of the Editors
    const [questionEditorName, setQuestionEditorName] = useState("n/a");
    const [editorLastModifiedName, setEditorLastModifiedName] = useState("n/a");

    // Redux state values
    const reduxCategories = useSelector(state => state.questions?.categories);
    const reduxCatalogs = useSelector(state => state.questions?.catalogs);

    // Available catalogs and categories
    const [availableCategories, setAvailableCategories] = useState([])
    const [availableCatalogs, setAvailableCatalogs] = useState([])

    // Auth role and User ID of the current User
    const authRole = useSelector(state => state.auth?.user?.user?.role);
    const currentUserId = useSelector(state => state.auth?.user?.user?.id);

    // The ID of the editor of the question
    const [questionEditorId, setQuestionEditorId] = useState();

    let history = useHistory();

    function deleteQuestion() {

        confirm({
            title: 'Frage wirklich löschen?',
            icon: <ExclamationCircleOutlined/>,
            content: 'Soll diese Frage wirklich gelöscht werden? Die Frage ist dann auch in keinem der zugewiesenen Kataloge verfügbar.',
            cancelText: 'Abbruch',

            // https://stackoverflow.com/questions/52649980/react-ant-modal-props-of-undefined
            // https://github.com/ant-design/ant-design/issues/5269#issuecomment-347836625
            onOk: () => {
                console.log("Deleting Question:", questionUuid);
                setIsLoading(true);
                RAQAClient.deleteQuestion(questionUuid).then(response => {
                        history.push(`/question-list`);
                    }
                )
                    .catch(error => {
                        setIsLoading(false);
                        console.log(error);
                        console.log("Catch error");
                    })

            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    return (
        <QuestionFormContext.Provider
            value={{
                isAddMode: isAddMode,
                setIsAddMode: setIsAddMode,
                isLoading: isLoading,
                fileList: fileList,
                imageUuidList: imageUuidList,
                currentUserId: currentUserId,
                questionEditorName: questionEditorName,
                questionEditorId: questionEditorId,
                questionUuid: questionUuid,
                responseQuestion: responseQuestion,
                editorLastModifiedName: editorLastModifiedName,
                reduxCategories: reduxCategories,
                reduxCatalogs: reduxCatalogs,
                availableCategories: availableCategories,
                availableCatalogs: availableCatalogs,
                setIsLoading: setIsLoading,
                setFileList: setFileList,
                setImageUuidList: setImageUuidList,
                setQuestionUuid: setQuestionUuid,
                setQuestionEditorName: setQuestionEditorName,
                setEditorLastModifiedName: setEditorLastModifiedName,
                setQuestionEditorId: setQuestionEditorId,
                setResponseQuestion: setResponseQuestion,
                setAvailableCategories: setAvailableCategories,
                setAvailableCatalogs: setAvailableCatalogs,
                deleteQuestion,
            }}
        >
            {children}
        </QuestionFormContext.Provider>
    );
}
