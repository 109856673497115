import React, {useEffect, useState} from "react";
import { Row, Col, Form, Modal, Button, Input } from "antd";

import RAQAClient from "../../../network/api/RaqaClient";

import 'antd/dist/antd.min.css';


const TanModifyModal = (props) => {

    const { tanData, visible, onCloseClicked, onTanDataSaved } = props;
    const [form] = Form.useForm();

    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    useEffect(() => {
            form.resetFields();
    },[]);

    const handleSaveClicked = (data) => {
        requestTanUpdate(data);
    };

    const handleCancelClicked = (e) => {
        onCloseClicked()
    };

    const requestTanUpdate = async (data) => {
        console.log(`Update Tan: `, data);

        if(data.firstName === undefined) {
            data.firstName = null
        }

        if(data.lastName === undefined) {
            data.lastName = null
        }

        if(data.email === undefined) {
            data.email = null
        }

        try {
            const response = await RAQAClient.updateTans([{ code: tanData?.code || "00000000", ...data }])
            onTanDataSaved();
            onCloseClicked();
            form.resetFields();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Modal
            forceRender
            title={(
                <div style={{marginTop:"10px"}}>
                    <h2>TAN {tanData?.code} zuweisen</h2>
                </div>
            )}
            footer={(
                <p style={{fontSize:"10px", textAlign:"left"}}>
                    Wenn Sie eine E-Mail Adresse angeben, wird der TAN Code per E-Mail an die angegebene Adresse verschickt.
                </p>
            )}
            open={visible}
            onCancel={onCloseClicked}
        >
            <Form
                form={form}
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    ...tanData,
                }}
                onFinish={handleSaveClicked}
            >
                <Form.Item
                    label="Vorname"
                    name="firstName"
                    key="firstName"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Nachname"
                    name="lastName"
                    key="lastName"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="E-Mail"
                    name="email"
                    key="email"
                >
                    <Input />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <div style={{textAlign:"left"}}>
                        <Button key="cancel" htmlType="button" onClick={handleCancelClicked}>
                            Abbrechen
                        </Button>
                        <Button key="ok" style={{ margin: '0 8px' }} type="primary" htmlType="submit">
                            Speichern
                        </Button>
                    </div>
                </Form.Item>

            </Form>
        </Modal>
    );
};

export default TanModifyModal;