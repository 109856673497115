import React, {useEffect, useState} from "react";
import {Col, Row, Divider, Tag} from "antd";

import styles from './QuestionView.module.css';
import {useSelector} from "react-redux";
import auth from "../../redux/reducers/auth";

const QuestionView = (data) => {

    // console.log("The question to render: ",data);

    const question = data.question;

    const [percentCorrect, setPercentCorrect] = useState(100);
    const [questionAuthor, setQuestionAuthor] = useState("Unbekannt");
    const [modifiedAuthor, setModifiedAuthor] = useState("-");

    // Redux editors
    const reduxEditors = useSelector(state => state.administration?.editors);

    useEffect(() => {
        if (question.answerCount === 0 && question.answerCorrectCount === 0) {
            setPercentCorrect(100)
        }
        else if(question.answerCorrectCount === 0) {
            setPercentCorrect(0)
        }
        else if(question.answerCount > 0 && question.answerCorrectCount >>0) {
            const percent = (100*question.answerCorrectCount)/question.answerCount
            setPercentCorrect(percent)

        }

        let author = reduxEditors.find(o => o.id === question.userId);
        if (author !== undefined || author === null) {
            setQuestionAuthor(author.firstName + " " +author.lastName)
        }

        let modifiedAuthor = reduxEditors.find(o => o.id === question.modifiedByUser);
        if (modifiedAuthor !== undefined || modifiedAuthor === null) {
            setModifiedAuthor(modifiedAuthor.firstName + " " +modifiedAuthor.lastName)
        }


    }, []);

    const formattedAnswers = question.answers.map(answer => {
        if (answer.correct === true) {
            return <li className={styles["question-answer-correct"]} key={answer.text}>{answer.text}</li>
        }
        else {
            return <li className={styles["question-answer-wrong"]} key={answer.text}>{answer.text}</li>
        }
    });

    return (
        <div>
            {data.showQuestion === true &&
                <Row gutter={25}>
                    <Col span={24} style={{"marginBottom": "20px"}} key="Frage">
                        <div>
                            <p style={{margin: 0}}><b>Frage</b></p>
                        </div>
                        <div>
                            {question.text}
                        </div>
                    </Col>
                </Row>
            }
            <Row gutter={25}>
                <Col span={12} style={{"marginBottom": "20px"}} key="Antworten">
                    <div>
                        <p style={{margin: 0}}><b>Antworten</b></p>
                    </div>
                    <div>
                        <ul>
                            {formattedAnswers}
                        </ul>
                    </div>
                    <div>
                        <p style={{margin: 0, paddingTop: 10, paddingBottom: 5}}><b>Kategorien</b></p>
                    </div>
                    <div> { question.categories.map(category =>
                        <Tag key={category} color="default">{category}</Tag>
                    )
                    }
                    </div>

                </Col>
                <Col span={12} style={{"marginBottom": "20px"}} key="Info">
                    <div>
                        <p style={{margin: 0}}><b>Zusatzinformationen zur Frage</b></p>
                    </div>
                    <div>
                        <p style={{margin: 0, paddingRight: 10}}>{
                            question.answerInfo !== null ? question.answerInfo : "Nicht vorhanden"
                        }
                        </p>
                    </div>
                    <div>
                        <p style={{margin: 0, paddingTop: 20}}><b>URL zur Frage</b></p>
                    </div>
                    <div>
                        <p style={{margin: 0, paddingRight: 10}}>{
                            question.answerUrl !== null ? question.answerUrl : "Nicht vorhanden"
                        }
                        </p>
                    </div>
                    <div>
                        <p style={{margin: 0, paddingTop: 20}}><b>Frage ist veröffentlicht?</b></p>
                    </div>
                    <div>
                        <p style={{margin: 0, paddingRight: 10}}>{
                            question.isPublished === true ? "Ja" : "Nein"
                        }
                        </p>
                    </div>
                    <div>
                        <p style={{margin: 0, paddingTop: 20}}><b>Autor:in</b></p>
                    </div>
                    <div>
                        <p style={{margin: 0, paddingRight: 10}}>{questionAuthor}
                        </p>
                    </div>

                    <div>
                        <p style={{margin: 0, paddingTop: 20}}><b>Geändert von</b></p>
                    </div>
                    <div>
                        <p style={{margin: 0, paddingRight: 10}}>{modifiedAuthor}
                        </p>
                    </div>

                </Col>
                <Col span={12} style={{"marginBottom": "20px"}} key="AntwortenStats1">
                    <div>
                        <p style={{margin: 0, paddingTop: 20}}><b>Anzahl Antworten</b></p>
                    </div>
                    <div>
                        <p style={{margin: 0, paddingRight: 10}}>{question.answerCount}
                        </p>
                    </div>
                </Col>
                <Col span={12} style={{"marginBottom": "20px"}} key="AntwortenStats2">
                    <div>
                        <p style={{margin: 0, paddingTop: 20}}><b>Richtige Antworten</b></p>
                    </div>
                    <div>
                        <p style={{margin: 0, paddingRight: 10}}>{question.answerCorrectCount} ({parseFloat(percentCorrect).toFixed(1)} Prozent)
                        </p>
                    </div>
                </Col>
            </Row>
        </div>

    )
}

export default QuestionView;
