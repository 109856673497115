import React, { useContext} from "react";
import { Form, Input } from 'antd';

export default function QuestionInput() {
    return (
        <Form.Item name="question" rules={[{ required: true, message: 'Bitte geben Sie eine Frage ein' }]}>

            <Input.TextArea
                showCount
                maxLength={500}
            />

        </Form.Item>
    );
}

