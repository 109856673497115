import {useHistory} from "react-router-dom";
import {QuestionContext} from "../../../context/questionContext";
import React, {useContext, useEffect, useState} from "react";
import {Card, Checkbox, Col, Form, Modal, Row, Switch} from "antd";
import QuestionInput from "./form-components/QuestionInput";

const QuestionEditorContent = () => {

    // History to go back and push to a different page
    let history = useHistory();

    const { isOnlySingleAnswer, setOnlySingleAnswerAllowed, answers, toggleAnswer } = useContext(QuestionContext);

    // useState for display modal dialog (to many answers for single dialog)
    const [isModalVisible, setIsModalVisible] = useState(false);

    function onSwitchChange(value) {
        setOnlySingleAnswerAllowed(!isOnlySingleAnswer);
    }

    // OK Click Handler for  single answer mode alert is display
    // Modal is displayed when more than one answer in single answer mode is set to correct
    function handleOk() {
        setIsModalVisible(false);

        answers.forEach(answer => {
            toggleAnswer(answer.id, false);
        });
    }

    // Here we check whenever isSingleAnswer changes or answers changes
    // if more than one answer is marked as true when isSingleAnswer is true
    useEffect(() => {
        if (isOnlySingleAnswer === true) {
            console.log("isSingleAnswer is true", answers);
            const count = answers.filter((obj) => obj.correct === true).length;
            console.log("Correct answers: ", count);

            if (count > 1) {
                setIsModalVisible(true);
            }
        }
    }, [isOnlySingleAnswer, answers]);

    return (
        <Row
            justify="start"
            align="middle"
            gutter={[28, 24]}
            className="todos-container"
        >

            {/* The questions section */}
            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}}>
                <Card title="Frage" style={{borderRadius: 15}}
                      headStyle={{
                          backgroundColor: 'rgba(245, 245, 245, 1)',
                          borderTopLeftRadius: 15,
                          borderTopRightRadius: 15
                      }}
                      bodyStyle={{backgroundColor: 'rgba(255, 255, 255, 0)', border: 1}}
                      extra={
                          <div>
                              <Switch
                                  onChange={onSwitchChange}
                                  checked={isOnlySingleAnswer}
                              >
                              </Switch>
                              <span>&nbsp;&nbsp;Nur Einzelantwort erlauben</span>
                          </div>
                      }>
                    <QuestionInput/>
                    <Form.Item
                        label=""
                        name="isPublished"
                        valuePropName="checked"
                    >
                        <Checkbox>Diese Frage veröffentlichen</Checkbox>
                    </Form.Item>
                </Card>
            </Col>
            <Modal title="Zu viele richtige Antworten"
                   open={isModalVisible}
                   onOk={handleOk}
                   cancelButtonProps={{style: {display: 'none'}}}>
                <p>Bei einer ein Einzelantwort-Frage kann nur genau eine Antwort richtig sein.
                    Es sind jedoch mehrere Antworten als richtig markiert. Bitte markieren Sie nur eine Antwort als
                    richtig.</p>
            </Modal>
        </Row>

    )
}

export default QuestionEditorContent;
