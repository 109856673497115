import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CatalogTableView from "../../super/catalog/components/CatalogTableView";
import RAQAClient from "../../../network/api/RaqaClient";
import {SET_EDITORS} from "../../../redux/actions/types";

const CatalogEditorsList = (props) => {

    // Redux useDispatch, used for setting catalog state
    const dispatch = useDispatch();

    // The catalogs of the other editors
    const [editorCatalogs, setEditorCatalogs] = useState([]);

    // The current editor / user
    const currentEditor = useSelector((state) => (state.auth.user.user));

    // Access the Redux State
    const reduxEditors = useSelector(state => state.administration.editors);
    //const [editors, setEditors] = useState(reduxEditors);

    useEffect(() => {
        fetchEditors()
    },[])

    // Update the catalogs state data if the reduxEditors or editors is changinge
    useEffect(() => {
        const tableData = [];
        console.log("reduxEditors: ", reduxEditors)

        reduxEditors.forEach(editor => {
                if (editor.id !== currentEditor.id)
                    editor.catalogs.forEach(catalog => {
                            const newEntry = {
                                key: catalog.uuid,
                                catalog: catalog,
                                editor: editor
                            }
                            tableData.push(newEntry);
                        }
                    )
            },
        )

        console.log("Table Data: ", tableData)
        setEditorCatalogs(tableData);

    }, [reduxEditors])

    // -- Async API methods -------------------------------------

    const fetchEditors = async () => {

        const response = await RAQAClient.listUsers("admin",true);
        const editorUsers = response.data || [];
        console.log("editorUsers: ", editorUsers)

        dispatch(
            {
                type: SET_EDITORS,
                payload: editorUsers,
            }
        )
        return editorUsers.map(u => {
            u.key = u.id;
            return u;
        })
    }

    return (
        <div style={{paddingTop:50}}>
            <h1>Kataloge der anderen Editoren</h1>
            <CatalogTableView tableData={editorCatalogs}/>
        </div>
    )
}

export default CatalogEditorsList
