export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const PASSWORD_UPDATE = "PASSWORD_UPDATE";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_CATEGORIES = "SET_CATEGORIES";
export const CLEAR_CATEGORIES = "CLEAR_CATEGORIES";

export const SET_CATALOGS = "SET_CATALOGS";
export const SET_EDITORS = "SET_EDITORS";
