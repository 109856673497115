import React, { useState } from "react";
import { Row, Col, InputNumber, Menu, Dropdown, Modal, Button } from "antd";

import RAQAClient from "../../../network/api/RaqaClient";

import 'antd/dist/antd.min.css';


const TanCreationModal = (props) => {

    const { visible, onCloseClicked, onTansGenerated } = props;

    const [currentInput, setCurrentInput] = useState(1);

    const fillNumberfield = (count) => {
        setCurrentInput(count);
    };

    const handleCreateClicked = (e) => {
        requestCreateTans(currentInput);
    }

    const requestCreateTans = async (count) => {
        console.log(`Create Tans: `, count);

        try {
            const response = await RAQAClient.generateTans(count);
            const resp = response.data;
            // const url = window.URL.createObjectURL(new Blob([resp]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', 'tancodes.txt'); //or any other extension
            // document.body.appendChild(link);
            // link.click();

            onTansGenerated();
            onCloseClicked();
        } catch (error) {
            console.log(error);
        }
    }

    const dropdownContent = [5, 10, 15, 20, 25];

    const menu = (
        <Menu>
            {dropdownContent.map(fillNr => (
                <Menu.Item key={fillNr} onClick={() => fillNumberfield(fillNr)}>
                    {fillNr}
                </Menu.Item>
            ))
            }
        </Menu>
    );


    return (
        <Modal
            forceRender
            title={(
                <div>
                    <h1>TAN Generator</h1>
                </div>
            )}
            open={visible}
            footer={[
                <Button key="cancel" type="default" onClick={onCloseClicked}>
                    Abbrechen
                </Button>,
                <Button key="ok" type="primary" onClick={handleCreateClicked}>
                    Tans generieren
            </Button>,
        ]}
            onCancel={onCloseClicked}
        >
            <Row>
                <Col offset={0} span={14} key={"headline"}><span>Wie viele Tans möchten Sie erzeugen?</span></Col>
                <Col offset={2} span={8} key={"content"}>
                    <Dropdown overlay={menu}>
                        <InputNumber
                            min={1}
                            max={10000}
                            defaultValue={100}
                            value={currentInput}
                            onChange={(value) => setCurrentInput(value)}
                        />
                    </Dropdown>
                </Col>
            </Row>
        </Modal>
    );
};

export default TanCreationModal;
