import {Card, Col, message, Modal, Row, Upload} from "antd";
import React, {useContext, useEffect, useState} from "react";
import ImgCrop from "antd-img-crop";
import {ExclamationCircleFilled, PlusOutlined} from "@ant-design/icons";
import {QuestionFormContext} from "../QuestionFormContext";
import RaqaClient from "../../../network/api/RaqaClient";

const {confirm} = Modal;

const QuestionEditorImages = () => {

    // Context API
    const {
        isAddMode,
        questionUuid,
        fileList,
        setFileList,
        imageUuidList,
        setImageUuidList
    } = useContext(QuestionFormContext);

    // File list for uploading images
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const [isFinished, setIsFinished] = useState(false);


    useEffect(() => {

        if (!isAddMode) {
            let imagesList = [];
            let errorImagesList = [];

            Promise.all(imageUuidList.map((uuid) => {
                    return RaqaClient.getImage(uuid)
                        .then(response => {
                                const mimeType = response.headers['content-type'];
                                const file = new File([response.data], "Image", {type: mimeType});
                                const reader = new FileReader();
                                reader.readAsDataURL(response.data);
                                reader.onloadend = function () {
                                    file.url = reader.result;
                                    file.status = "done"
                                    file.thumbUrl = reader.result;
                                    file.uid = uuid
                                    imagesList.push(file);
                                }
                            }
                        ).catch(error => {
                            console.error("Error downloading image with uuid: "+uuid);
                            errorImagesList.push(uuid)
                        });
                }
            )).then(_ => {
                // ONLY WORKS WITH A TIMEOUT
                setTimeout(function () {
                    setFileList(imagesList);

                    if(errorImagesList.length > 0) {
                        // Remove all uuids from the imageUuidList which are in the errorList
                        const newImageUuidList = imageUuidList.filter(n => !errorImagesList.includes(n));
                        setImageUuidList(newImageUuidList);
                    }


                }, 300);
                setIsFinished(true);
            });

        }
    }, [imageUuidList]);

    const beforeUploadImage = async (file) => {

        if (isAddMode) {
            if (isCorrectFileType(file) === true) {
                file.thumbUrl = await convertBase64(file)
                setFileList([...fileList, file]);
            } else {
                message.error("Dieses Bild ist im falschen Bildformat");
            }
            return false;
        } else {
            // Create the FormData for the Multipart Upload
            const formData = new FormData();
            formData.append("newImage", file);

            RaqaClient.uploadImageForQuestion(questionUuid, formData)
                .then(async response => {
                    if (response.status === 200) {
                        message.info("Image upload erfolgreich");
                        const newUuid = response.data.uuids[0];
                        console.log("New UUID: "+newUuid);
                        file.uid = newUuid;
                        file.thumbUrl = await convertBase64(file);
                        setFileList([...fileList, file]);
                        //setImageUuidList([...imageUuidList, newUuid]);

                    } else {
                        message.error("Image Upload fehlgeschlagen");
                        console.error("Image Upload ist fehlgeschlagen");
                    }
                })
                .catch(error => {
                    message.error("Fehler! Image Upload ist fehlgeschlagen");
                    console.error("Fehler! Image Upload ist fehlgeschlagen");
                    console.error(error);

                });
            return false;
        }
    }

    const onRemoveImage = (file) => {

        if (isAddMode) {
            removeImageFromFileList(file);
        } else {
            confirm({
                title: 'Bild wirklich löschen?',
                icon: <ExclamationCircleFilled/>,
                content: 'Wollen Sie dieses Bild dauerthaft löschen? Es wird dann aus dieser Frage entfernt und am Server gelöscht.',
                okText: 'Löschen',
                okType: 'danger',
                cancelText: 'Abbruch',
                onOk() {
                    console.log(`Deleting Image with ID ${file.uid} for question  ${questionUuid}`);

                    RaqaClient.deleteImage(questionUuid, file.uid).then(response => {
                        if (response.status === 200) {
                            removeImageFromUuidList(file.uid);
                            removeImageFromFileList(file);
                        }
                    });
                },
                onCancel() {
                },
            });
        }
    }

    function removeImageFromFileList(file) {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
    }

    function removeImageFromUuidList(imageUuid) {
        const index = imageUuidList.indexOf(imageUuid);
        const newImageUuidList = imageUuidList.slice();
        newImageUuidList.splice(index, 1);
        setImageUuidList(newImageUuidList);
    }

    const onPreviewImage = async (file) => {
        const base64 = await convertBase64(file);
        setPreviewImage(base64);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    }

    const convertBase64 = (file) => new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
            resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
            reject(error)
        }
    })

    const beforeImageCrop = async (file) => {
        if (isCorrectFileType(file) === true) {
            return true;
        } else {
            message.error("Dieses Bild ist im falschen Bildformat");
            return false;
        }
    }

    const isCorrectFileType = (file) => {
        if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            return true;
        }
        else {
            return false;
        }
    }

    const handleCancel = () => setPreviewOpen(false);

    const uploadButton = (
        <div>
            <PlusOutlined/>
            <div style={{marginTop: 8}}>Hinzufügen</div>
        </div>
    );

    if (isAddMode === false && isFinished === false) {
        return (<div>
                Lade Bilder
            </div>
        )
    } else {
        return (
            <Row
                justify="start"
                align="middle"
                gutter={[28, 24]}
                className="todos-container"
                style={{marginTop: "25px"}}
            >
                <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}}>
                    <Card title="Bilder zur Frage" style={{borderRadius: 15}}
                          headStyle={{
                              backgroundColor: 'rgba(245, 245, 245, 1)',
                              borderTopLeftRadius: 15,
                              borderTopRightRadius: 15
                          }}
                          bodyStyle={{backgroundColor: 'rgba(255, 255, 255, 0)', border: 1}}
                    >
                        <ImgCrop fillColor="white" zoomSlider showReset={true} aspectSlider={true} aspect={9 / 16}
                                 beforeCrop={beforeImageCrop}>
                            <Upload
                                listType="picture-card"
                                maxCount={3}
                                accept=".png,.jpeg,.jpg, image/png,image/jpg, image/jpeg"
                                multiple
                                fileList={fileList}
                                onRemove={onRemoveImage}
                                beforeUpload={beforeUploadImage}
                                onPreview={onPreviewImage}
                            >
                                {fileList.length >= 5 ? null : uploadButton}
                            </Upload>
                        </ImgCrop> </Card>
                </Col>

                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img alt="example" style={{width: '100%'}} src={previewImage}/>
                </Modal>
            </Row>
        )
    }

}

export default QuestionEditorImages;
