import React, {useEffect} from "react";
import {useState} from "react";
import {useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import {Button, Form, Card, Col, Divider, Input, Modal, Space, Row, Spin, Table, Popconfirm, message} from "antd";
import {CheckOutlined, CrownOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import RAQAClient from '../../network/api/RaqaClient';
import RaqaClient from "../../network/api/RaqaClient";


const ContentGroupAddCatalogModal = ({open, onCancel, onSave, availableCatalogs, contentGroupId}) => {

    const [assignedSelectedRowKeys, setAssignedSelectedRowKeys] = useState([])
    const [selectedCatalogs, setSelectedCatalogs] = useState([])
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);

    const handleOk = async () => {
        //console.log(selectedCatalogs);
        let catalogUuids = selectedCatalogs.map(catalog => catalog.key);
        //console.log(uuids);

        if (catalogUuids.length > 0) {
            try {
                const response = await RAQAClient.addCatalogsToContentGroup(contentGroupId, catalogUuids);
                if (response.status === 200) {
                    message.info("Zuweisung der Kataloge erfolgreich");
                } else {
                    clearSelectionRowsInTables();
                    message.error("Zuweisung der Kataloge fehlgeschlagen");
                    console.log("Fehler bei Zuweisen neuer Kataloge, Response Status: ", response.status)
                }
            } catch (error) {
                message.error("Zuweisung der Kataloge fehlgeschlagen");
                console.log("Fehler bei Zuweisen neuer Kataloge, Response Status: ", error)
            } finally {
                clearSelectionRowsInTables();
                onSave();
            }

        } else {
            onSave();
            clearSelectionRowsInTables();
        }
    };

    const handleCancel = () => {
        clearSelectionRowsInTables();
        onCancel();
    };

    function clearSelectionRowsInTables() {
        setAssignedSelectedRowKeys([]);
        setSelectedCatalogs([]);
        setOkButtonDisabled(true);
    }


    const rowSelectionAssigned = {
        selectedRowKeys: assignedSelectedRowKeys,
        onChange: (selectedRowKeys, rows) => {
            if (selectedRowKeys.length > 0) {
                setOkButtonDisabled(false);
            } else {
                setOkButtonDisabled(true);
            }
            setSelectedCatalogs([...rows])
            setAssignedSelectedRowKeys(selectedRowKeys)
        },
    };

    const columns = [
        {
            title: (<CrownOutlined/>),
            dataIndex: ["catalog", "isPublished"],
            key: 'isPublished',
            width: '5%',
            render: isPublished =>
                <span>{isPublished === true ? <CheckOutlined/> : ""} </span>
        },
        {title: 'Katalog', dataIndex: ["catalog", "title"], width: '25%', key: 'title',},
        {title: 'Beschreibung', dataIndex: ["catalog", "description"], width: '35%', key: 'description'},

        {
            title: 'Editor', dataIndex: ["editor"], key: 'editor', width: '20%', render: (text, record) => {
                return `${text.firstName} ${text.lastName}`; // just for decoration
            }
        },
        {
            title: 'Fragen',
            dataIndex: ["catalog", "questionUuids"],
            key: 'questionUuids',
            width: '10%',
            render: (questionUuids, record) => {
                return `${questionUuids.length}`; // just for decoration
            }
        },
    ];

    return (
        <>
            <Modal title="Katalog hinzufügen"
                   width="60%"
                   open={open}
                   okButtonProps={{disabled: okButtonDisabled}}
                   onOk={handleOk}
                   onCancel={handleCancel}>

                <Table size="small"
                       columns={columns}
                       rowSelection={rowSelectionAssigned}
                       dataSource={availableCatalogs}
                       pagination={{pageSize: 10}}
                />

            </Modal>
        </>
    );
};

export default ContentGroupAddCatalogModal;
