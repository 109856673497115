import React, {useState} from "react";
import RAQAClient from "../../network/api/RaqaClient";
import {useQuery} from "react-query";
import {Divider, Popconfirm, Space, Table, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";

const PlayerContentGroupView = ({player}) => {

    const history = useHistory();
    const [tableData, setTableData] = useState([]);

    const { data: contentGroups, refetch, isLoading: isFetching, } = useQuery(['contentGroups'], () => fetchContentGroups(), {
        keepPreviousData: true,
        onSettled: (data, error) => onContentGroupsSettled(data, error)
    });


    const fetchContentGroups = async () => {
        try {
            const data = await RAQAClient.listContentgroups();
            return data;
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    /// New question data (or error) received
    const onContentGroupsSettled = (response, error) => {

        const allContentGroups = response?.data?.items;
        const playerContentGroups = player.contentgroups;

        console.log("** allContentGroups: ", allContentGroups);
        console.log("** playerContentGroups: ", playerContentGroups);

        if (error) {
            console.log('Error', error);
        }

        if(allContentGroups) {
            const userRelatedContentGroups = allContentGroups.filter(contentGroup => playerContentGroups.find(playerGroup => playerGroup.id == contentGroup.id));
            console.log("** userRelatedContentGroups: ", userRelatedContentGroups);
            setTableData(userRelatedContentGroups);

        }
        else {
            setTableData([]);
        }
    };

    const handleContentGroupSelected = (contentGroupId) => {
        history.push(`/content-group-detail/${contentGroupId}`);
    };


    const actionColumn = {
        title: 'Aktion',
        dataIndex: '',
        key: 'action',
        width: '5%',
        render: (text, record) => (
            <Space size="middle" split={<Divider type="vertical"/>}>
                <Tooltip title ='Editieren'>
                    <a onClick={e => {
                        e.stopPropagation();
                        handleContentGroupSelected(record.id);
                        //editContentgroup(record);
                    }}><EditOutlined /></a>
                </Tooltip>
            </Space>
        )};

    const columns = [
        {
            title: () => <Tooltip title ='Name der Lerngruppe'>Name</Tooltip>,
            dataIndex: 'title',
            width: '65%',

        },
        {
            title: () => <Tooltip title ='Anzahl der der Lerngruppe zugewiesenen Kataloge'>Kataloge</Tooltip>,
            dataIndex: 'assignedCatalogs',
            width: '15%',

        },
        {
            title: <Tooltip title ='Anzahl der Spieler, die Zugriff auf dieses Lerngruppe haben'>Spieler</Tooltip>,
            dataIndex: 'assignedPlayers',
            width: '15%',

        },
        actionColumn
    ];


    return(
        <div>
            <Table
                style={{'cursor': 'pointer'}}
                loading={isFetching}
                columns={columns}
                dataSource={tableData}
                rowKey={contentGroup => contentGroup.id}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => { handleContentGroupSelected(record.id); },
                    };
                }}
            />
        </div>
    )
}

export default PlayerContentGroupView;
