import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import * as serviceWorker from './utils/serviceWorker';
import store from './redux/stores/store';

if (process.env.LOG_CONSOLE === 'false' || process.env.REACT_APP_LOG_CONSOLE === 'false') {
    console.log = () => {}
    //console.error = () => {}
    console.debug = () => {}
}


ReactDOM.render(

    <Provider store={store}>
        <App />
    </Provider>
   , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
