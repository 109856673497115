import {Card, Divider, List, Modal, Popconfirm, Space, Table, Tag} from "antd";
import React, {useState} from "react";
import {CheckOutlined, CrownOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import QuestionView from "./QuestionView";
import {history} from "../../utils/history";
import RAQAClient from "../../network/api/RaqaClient";

const QuestionTableView = ({questions, onEditQuestion, onDeleteQuestion}) => {

    const columns = [
        {title: (<CheckOutlined />), dataIndex: 'isPublished', key: 'isPublished', width: '5%', render: isPublished =>
                <span>{isPublished === true ? <CheckOutlined /> : ""} </span>
        },
        {title: 'Frage', dataIndex: 'text', key: 'text', width: '60%'},
        {
            title: 'Kataloge', dataIndex: 'catalogNames', key: 'catalogs', width: '35%', render: catalogNames => (
                <>
                    {catalogNames?.map(tag => {
                        return (
                            <Tag color={tag==="Allgemein" ? "red" : "blue"} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })
                    }
                </>)
        },
        {
            title: 'Aktion',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <Space size="middle" split={<Divider type="vertical"/>}>
                    <a onClick={e => {
                        e.stopPropagation();
                        onEditQuestion(record.uuid)
                    }}><EditOutlined /></a>
                    <Popconfirm
                        title="Wollen Sie diese Frage wirklich löschen?"
                        onConfirm={e => { e.stopPropagation(); onDeleteQuestion(record.uuid)}}
                        onCancel={e => e.stopPropagation()}
                        okText="Ja"
                        cancelText="Nein"
                    >
                        <a onClick={e => e.stopPropagation()}><DeleteOutlined /></a>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return(
        <Table
            dataSource={questions}
            columns={columns}
            size="small"
            expandable={{
                expandedRowRender: (record) => (
                    <QuestionView showQuestion={false} question={record}/>
                )
            }}
        />
    )
}

export default QuestionTableView