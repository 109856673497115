import {Descriptions} from "antd";
import styles from "../editor/editor.detail.module.css";

const CatalogDetailView = ({catalog}) => {

    const createdDate = new Date(catalog.createdAt*1000);
    const lastModified = new Date(catalog.updatedAt*1000);


    return(
        <div className={styles['editorDetailSection']}>
            <Descriptions labelStyle={{fontSize: 15, fontWeight: 500, marginBottom: -20}}
                          contentStyle={{fontSize: 15, fontWeight: 300}}
                          layout="vertical"
                          size="medium"
                          bordered={false}
                          column={4}>
                <Descriptions.Item label="Beschreibung" span={4}>{catalog.description}</Descriptions.Item>
                <Descriptions.Item label="Anzahl Fragen">{catalog.questionCount}</Descriptions.Item>
                <Descriptions.Item label="Erstellt am">{createdDate.getDate()}.{createdDate.getMonth()+1}.{createdDate.getFullYear()}</Descriptions.Item>
                <Descriptions.Item label="Letzte Änderung">{lastModified.getDate()}.{lastModified.getMonth()+1}.{lastModified.getFullYear()}</Descriptions.Item>
                <Descriptions.Item label="Veröffentlicht">{ catalog.isPublished ? "Ja" : "Nein"}</Descriptions.Item>

            </Descriptions>
        </div>
    )
}

export default CatalogDetailView;
