import React, { useEffect } from "react";
import { useState } from "react";
import {useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import {Button, Form, Card, Col, Divider, Input, Modal, Popconfirm, Space, Row, Spin, Table} from "antd";
import {CheckOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import RAQAClient from '../../network/api/RaqaClient';
import ContentGroupUsersTable from "./ContentGroupUsersTable";
import ContentGroupCatalogsTable from "./ContentGroupCatalogsTable";
import ContentGroupCreateModal from "./ContentGroupCreateModal";
import ContentGroupAddCatalogModal from "./ContentGroupAddCatalogModal";
import {useSelector} from "react-redux";

const DEFAULT_NETWORK_ERROR_TEXT = 'Beim ist ein Verbinungsfehler aufgetreten. Sollte der Fehler erneut auftreten, so kontaktieren Sie bitte den Administrator.';
const DEFAULT_SAVE_ERROR_TEXT = 'Beim Speichern der Daten ist ein Fehler aufgetreten. Sollte der Fehler erneut auftreten, so kontaktieren Sie bitte Ihren Administrator.';

const ContentGroupDetailView = ({history, match}) => {

    // Search Form
    const [titleForm] = Form.useForm();
    const [messageText, setMessageText] = useState();

    // Params
    const { id: contentGroupId } = match.params;

    // USE-QUERY
    const { data: contentGroupData, refetch, isLoading: isFetching, } = useQuery(['contentGroup', contentGroupId], () => fetchContentGroup(contentGroupId), {
        keepPreviousData: true
    });

    useEffect(() => {
        titleForm.resetFields();
    }, [contentGroupData]);


    const fetchContentGroup = async (contentgroupId) => {
        try {
            const data = await RAQAClient.getContentgroup(contentgroupId);
            //console.log('Data.....', data?.data);
            return data?.data || {};
        } catch (error) {
            console.error(error);
            setMessageText(DEFAULT_NETWORK_ERROR_TEXT);
            return [];
        }
    };

    const onUpdateTitle = async (formData) => {
        try {
            const cgroup = { ...formData, id: contentGroupData.id };
            await RAQAClient.editContentGroup(cgroup);
            await refetch();
        } catch (error) {
            titleForm.resetFields();
            console.log("ERROR", error);
            switch (error?.response?.status) {
                case 409:
                    setMessageText('Eine Lerngruppe mit diesem Namen existiert bereits. Bitte wählen Sie einen anderen Namen und versuchen Sie es erneut.');
                    break;
                default:
                    setMessageText(DEFAULT_SAVE_ERROR_TEXT);
                    break;
            }
        }
    };

    const onClickDeleteContentGroup = async () => {

    };

    return (
        <div>
            <Spin spinning={isFetching}>
                {/*<span>{JSON.stringify(contentGroupData)}</span>*/}
                <Card
                    title={(<h1>Lerngruppe bearbeiten</h1>)}
                    style={{width: '100%'}}
                    extra={
                        <Popconfirm
                            title="Wollen Sie diese Lerngruppe wirklich löschen?"
                            onConfirm={e => { /*e.stopPropagation(); onDeleteContentgroup()*/ }}
                            onCancel={e => e.stopPropagation()}
                            okText="Ja"
                            cancelText="Nein">
                            <Button key="buttonDelete" type="danger" onClick={onClickDeleteContentGroup}>
                                Lerngruppe löschen
                            </Button>
                        </ Popconfirm>
                    }
                >
                    <Form form={titleForm}
                        layout="inline"
                        //labelCol={{span: 4}}
                        //wrapperCol={{span: 20}}
                        onFinish={onUpdateTitle}
                        initialValues={contentGroupData}>
                        <Form.Item name={"title"} label={"Name"} rules={[{ required: true, message: 'Bitte Namen eingeben!' }]}>
                            <Input placeholder="Namen hier eingeben" allowClear />
                        </Form.Item>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={
                                        !titleForm.isFieldsTouched(true) ||
                                        !!titleForm.getFieldsError().filter(({ errors }) => errors.length).length
                                    }
                                >Speichern</Button>
                            )}
                        </Form.Item>
                    </Form>
                </Card>
                <div style={{"margin":"20px"}}></div>

                <ContentGroupUsersTable
                    contentGroupId={contentGroupId} />
                <div style={{paddingBottom: 20}} />
                <ContentGroupCatalogsTable
                    contentGroupId={contentGroupId} />

            </Spin>

            <Modal title="Fehler beim Speichern"
                   open={!!messageText}
                   onOk={() => setMessageText(undefined)}
                   cancelButtonProps={{style: {display: 'none'}}}>
                <p>{messageText}</p>
            </Modal>
        </div>
    );
};

export default ContentGroupDetailView;
