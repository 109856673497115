import {Tag} from "antd";
import React from "react";

const PlayerTagList = ({addedPlayersList, onRemove}) => {

    return (
        <div style={{"marginBottom":"20px"}}>
            {
                addedPlayersList.map(item =>
                <Tag key={item.id}  closable onClose={()=> {
                    onRemove(item.id)
                }}>
                    {item.firstName} {item.lastName}
                </Tag>)
            }
        </div>
    )
}

export default PlayerTagList;
