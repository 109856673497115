import React, {useState, useEffect} from 'react';
import {Form, Input, Button, Row, Col, Select, Modal} from 'antd';

import 'antd/dist/antd.min.css';

const EditorAssignmentModal = (props) => {

    const {title = "Kataloge übertragen", confirmButtonText = "Übertragen"} = props;
    const {editorsToAssign} = props;
    const {visible: modalVisible} = props;
    const {onOkClicked, onCancelClicked} = props;

    // Hooks
    const [form] = Form.useForm();
    const [selected, setSelected] = useState();

    useEffect(() => {
        form.resetFields();
        console.log("Deleting Form Fields");
    }, [form]);


    // HANDLER
    const onSubmitClicked = (e) => {
        form.submit();
    };

    const onFinish = (values) => {
        console.log('    const onFinish = (values) => {\n:', values);
        onOkClicked?.(values.newEditor);
    };

    const handleAfterClose = () => {
        setSelected(undefined);
        form.resetFields();
    };

    function handleChange(value) {
        console.log(`selected ${value}`);
        setSelected(value);
    }

    return (
        <Modal
            forceRender
            afterClose={handleAfterClose}
            closable={false}
            title={
                <div
                    style={{
                        width: '100%',
                    }}
                    // fix eslintjsx-a11y/mouse-events-have-key-events
                    // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
                    onFocus={() => {
                    }}
                    onBlur={() => {
                    }}
                    // end
                >
                    {title}
                </div>
            }
            open={modalVisible}
            onOk={onFinish}
            onCancel={onFinish}

            footer={[
                <Button key="back" onClick={onCancelClicked}>
                    Abbrechen
                </Button>,
                <Button key="submit"
                        type="primary"
                        onClick={onSubmitClicked}
                        disabled={!selected && true}>
                    {confirmButtonText}
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                name="reassignCatalog"
                onFinish={onFinish}
                labelCol={{span: 0}}
                wrapperCol={{span: 0}}
            >
                <span>
                    Dieser Editor hat noch vorhandene Fragen und Kataloge. Bitte wählen Sie einen Editor aus, dem die vorhandenen
                    Fragen und Kataloge übertragen werden.
                </span>
                <div style={{"margin-top": 20}}/>

                {editorsToAssign &&
                <Form.Item name="newEditor" label="Inhalte an diesen Editor übertragen:">
                    <Select
                        onChange={handleChange}
                        placeholder="Editor wählen"
                    >
                        {editorsToAssign.map(
                            (editor) => (
                                <Select.Option key={editor.id}>{editor.firstName} {editor.lastName}</Select.Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                }
            </Form>
        </Modal>
    );
};

export default EditorAssignmentModal;