import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import {Row, Col, InputNumber, Menu, Dropdown, Table, Tag, Radio, Space, Divider, Popconfirm} from "antd";
import {CheckOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";

import QuestionView from './QuestionView';
import RAQAClient from "../../network/api/RaqaClient";

import 'antd/dist/antd.min.css';
import styles from './DifficultQuestionsTable.module.css';
import {history} from "../../utils/history";



const DifficultQuestionsTable = (props) => {

    //const authRole = useSelector(state => state.auth?.user?.user?.role);
    //const isSuperUser = (authRole === 'super');

    // queryClient = useQueryClient();

    // LOAD: Questions
    const { data: questions, refetch: refetchQuestions, isLoading: isLoadingQuestions, isSuccess: isSuccessQuestions, error: errorQuestions } = useQuery(['successQuestions'],
        () => fetchQuestions());
    // LOAD: Editors
    const { data: editors, refetch: refetchEditors, isLoading: isLoadingEditors, isSuccess: isSuccessEditors, error: errorEditors } = useQuery(['successEditors'],
        () => fetchEditors());

    const [questionsWithCatalogNames, setQuestionsWithCatalogNames] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [minAnswers, setMinAnswers] = useState(10);

    const [successRatio, setSuccessRatio] = useState(0.20);
    const [isMoreThan, setIsMoreThan] = useState(false);

    // Refetch data when values change
    useEffect(() => {
        refetchQuestions();
    }, [successRatio, currentPage, pageSize, minAnswers, isMoreThan]);

    // Update catalog names of questions
    useEffect(() => {
        if (questions !== undefined) {
            const updatedQuestions = questions.map(question => {
                return {
                    ...question,
                    catalogNames: catalogNamesFor(question)
                };
            });
            setQuestionsWithCatalogNames(() => updatedQuestions);
        } else if (questionsWithCatalogNames !== undefined) {
            setQuestionsWithCatalogNames(() => []);
        }
    }, [editors, questions]);

    // Load Editors from API
    const fetchEditors = async () => {

        const response = await RAQAClient.listUsers("admin", true);
        const editorUsers = response.data || [];
        console.log(`Got ${editorUsers?.length || -1} editors.`);

        return editorUsers.map(u => {
            u.key = u.id;
            return u;
        })
    }

    // Load Questions from API
    const fetchQuestions = async () => {

        const minSuccessRatio = isMoreThan ? successRatio : 0.0;
        const maxSuccessRatio = isMoreThan ? 1.0 : successRatio;
        const sort = isMoreThan ? 'desc' : 'asc';
        const response = await RAQAClient.listDifficultQuestions(minSuccessRatio, maxSuccessRatio, currentPage, pageSize, minAnswers, sort);
        const questionList = response.data?.questions || [];
        console.log(`Got ${questionList?.length || -1} questions.`);
        const questionsWithUuids = questionList.map(q => {
            q.key = q.uuid;
            return q;
        });

        setPageSize(() => response.data.pageSize);
        setCurrentPage(() => response.data.page);
        setTotalPages(() => response.data.totalPages);
        setTotalItems(() => response.data.totalItems);

        return questionsWithUuids;
    };

    const catalogNamesFor = (question) => {
        const catalogNames = [];
        question.catalogUuids.forEach(uuid => {
            editors?.forEach(editor => {
                const catalogFound = editor.catalogs.find(catl => catl.uuid === uuid);
                if (catalogFound !== undefined) {
                    catalogNames.push(catalogFound.title);
                }
            });
        });
        if (question.isGeneral === true) {
            catalogNames.unshift("Allgemein");
        }
        return catalogNames;
    };

    // User clicked the 'edit' link of a questions
    const editQuestion = (questionId) => {
        console.log('Edit Question clicked');
        history.push(`/question/${questionId}`);
    };


    // DropDown 'Success Ratio'

    const menuRatioDropdownContent = [95, 90, 80, 70, 60, 50, 40, 30, 20, 10, 5];

    const menuRatio = (
        <Menu>
            {menuRatioDropdownContent.map(fillNr => (
                <Menu.Item key={fillNr} onClick={() => setSuccessRatio(parseFloat(fillNr) / 100.0)}>
                    {fillNr} %
                </Menu.Item>
            ))
            }
        </Menu>
    );

    const columns = [
        {
            title: '%', dataIndex: 'successRatio', key: 'successRatio', width: '5%', render: ratio =>
                <span className={styles["nowrap"]}>{parseInt((ratio * 100.0).toFixed(0))} %</span>
        },
        {
            title: (<EyeOutlined />), dataIndex: 'isPublished', key: 'isPublished', width: '5%', render: isPublished =>
                <span>{isPublished === true ? <CheckOutlined /> : ""} </span>
        },
        {
            title: 'Frage', dataIndex: 'text', key: 'text', width: '60%'
        },
        {
            title: 'Kataloge', dataIndex: 'catalogNames', key: 'catalogs', width: '35%', render: catalogNames => (
                <>
                    {catalogNames?.map(tag => {
                        return (
                            <Tag color={tag === "Allgemein" ? "red" : "blue"} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })
                    }
                </>)
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <Space size="middle" split={<Divider type="vertical"/>}>
                    <a onClick={e => {
                        e.stopPropagation();
                        editQuestion(record.uuid)
                    }}><EditOutlined /></a>
                </Space>
            ),
        }];

    return (
        <>
            <Row>
                <Col offset={0} span={24}><span>Liste aller Fragen, bei denen von allen Antworten </span>
                    <span style={{"marginLeft": 12}}>
                    <Radio.Group onChange={(e) => { setIsMoreThan(() => e.target.value) }} value={isMoreThan}>
                        <Radio value={false}>weniger als</Radio>
                        <Radio value={true}>mehr als</Radio>
                    </Radio.Group>
                    </span>

                    <Dropdown overlay={menuRatio}>
                        <InputNumber
                            min={0}
                            max={100}
                            defaultValue={20}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace('%', '')}
                            value={parseInt((successRatio * 100.0).toFixed(0))}
                            onChange={(value) => setSuccessRatio(() => parseFloat(value) / 100.0)}
                        />
                    </Dropdown>
                    <span style={{"marginLeft": 12}}>richtig waren</span>
                </Col>
            </Row>

            <Row style={{"marginBottom": 20}}>
                <Col offset={0} span={24}><span style={{"fontSize": 9}}>Es werden nur Fragen berücksichtigt, die mindestens 10 mal beantwortet wurden.</span>
                </Col>
            </Row>
            <Table
                loading={isLoadingQuestions || isLoadingEditors}
                dataSource={questionsWithCatalogNames}
                columns={columns}
                size="small"
                expandable={{
                    expandedRowRender: (record) => (
                        <QuestionView showQuestion={false} question={record} />
                    )
                }}
                pagination={{
                    defaultPageSize: pageSize,
                    total: totalItems,
                    // ADD: additional props for pagination component
                    onChange: (page) => {
                        setCurrentPage(() => page);
                    },
                    currentPage,
                    onShowSizeChange: (current, size) => {
                        setPageSize(() => size);
                    },
                }}
            />
        </>
    )
};

export default DifficultQuestionsTable;