import {SET_CATEGORIES, SET_CATALOGS, CLEAR_CATEGORIES} from "../actions/types";

const initialState = {
    categories:[],
    catalogs: []
};

export default function questions(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_CATEGORIES:
            let newCategories = payload.filter(x => x !== undefined)
            console.log("Dispatching SET_CATEGORIES with payload: "+payload);
        return {
            ...state,
            categories: newCategories
        };

        case SET_CATALOGS:
            console.log("Dispatching SET_CATALOGS with payload: "+payload);
            return {
                ...state,
                catalogs: payload
            };

        case CLEAR_CATEGORIES:
            return { ...state,
                categories: []
            };

        default:
            return state;
    }
}