import { v4 as uuidv4 } from 'uuid';
import {initialQuestionState} from "./questionContext";


export default function questionReducer(state, action) {
    switch (action.type) {
        case "SET_QUESTION_TEXT":
            return {
                ...state,
                question: action.payload
            };
        case "RESET_QUESTION":
            return initialQuestionState;
        case "SET_CATEGORIES":
            return {
                ...state,
                categories: action.payload
            };
        case "ADD_ANSWER":
            return {
                ...state,
                answers: [...state.answers, {
                    id:  uuidv4(),
                    title: action.payload.title,
                    correct: action.payload.correct ?? false
                }],
            };
        case "TOGGLE_ANSWER":
            const { id, correct } = action.payload;
            return {
                ...state,
                answers: state.answers.map((answer) => {
                    if (answer.id === id) answer.correct = correct;
                    return answer;
                }),
            };
        case "CHANGE_ANSWER":
            const { ident, newAnswer } = action.payload;
            return {
                ...state,
                answers: state.answers.map((answer) => {
                    if (answer.id === ident) answer.title = newAnswer;
                    return answer;
                }),
            };

        case "DELETE_ANSWER":
            return {
                ...state,
                answers: state.answers.filter((answer) => answer.id !== action.payload),
            };
        case "RESET_ANSWERS":
            return {
                ...state,
                answers: [],
            };

        default:
            return state;
    }
}
