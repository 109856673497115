import {useSelector} from "react-redux";
import UserDetailView from "../../../components/user/UserDetailView";
import React from "react";
import {useLocation} from "react-router-dom";
import {Descriptions, Divider} from "antd";
import PlayerContentGroupView from "../../../components/user/PlayerContentGroupView";

const PlayerDetailView = (props) => {

    // Use location to get the parameter in location.state
    const location = useLocation();

    let player = location.state.player;

    console.log("Player Data: ", player);

    function timeConvert(n) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);

        if(rhours === 0) {
            if(rminutes < 2) {
                return (rminutes + " Minute")
            }
            else {
                return (rminutes + " Minuten")
            }
        }
        else if (rhours === 1) {
            if(rminutes < 2) {
                return (rhours + " Stunde und " + rminutes + " Minute")
            }
            else {
                return (rhours + " Stunde und " + rminutes + " Minuten")
            }
        }
        else {
            if(rminutes < 2) {
                return (rhours + " Stunden und " + rminutes + " Minute")
            }
            else {
                return (rhours + " Stunden und " + rminutes + " Minuten")
            }
        }
    }

    return (
        <div>
            <h2>Detailansicht zu {player.firstName} {player.lastName}</h2>
            <UserDetailView user={player}/>
            <div style={{paddingBottom: 10}}/>
            <Divider dashed />
            <div style={{paddingBottom: 10}}/>
            <h2>Zugewiesene Lerngruppen</h2>
            <PlayerContentGroupView player={player}/>
            <div style={{paddingBottom: 10}}/>
            <h2>Nutzungsdaten</h2>
            <Descriptions labelStyle={{fontSize: 15, fontWeight: 500, marginBottom: -20}}
                          contentStyle={{fontSize: 15, fontWeight: 300}}
                          layout="vertical"
                          size="medium"
                          bordered={true}
                          column={3}
            >
                <Descriptions.Item label="Betriebssystem">{player.os === null ? "n/a" : player.os}</Descriptions.Item>
                <Descriptions.Item label="OS Version">{player.os === null ? "n/a" : player.osVersion}</Descriptions.Item>
                <Descriptions.Item label="App Version">{player.os === null ? "n/a" : player.appVersion}</Descriptions.Item>
            </Descriptions>

            <div style={{paddingBottom: 10}}/>
            <Divider dashed />
            <div style={{paddingBottom: 10}}/>

            <h2>Statistikdaten</h2>

            <Descriptions labelStyle={{fontSize: 15, fontWeight: 500, marginBottom: -20}}
                          contentStyle={{fontSize: 15, fontWeight: 300}}
                          layout="vertical"
                          size="medium"
                          bordered={true}
                          column={5}
            >
                <Descriptions.Item label="Insgesamt beantwortete Fragen">{player.answerCount}</Descriptions.Item>
                <Descriptions.Item label="Richtig beantwortete Fragen">{player.answerCorrectCount}</Descriptions.Item>
                <Descriptions.Item label="Gesamte Lernzeit">{timeConvert(player.totalLearningSeconds/60)}</Descriptions.Item>
                <Descriptions.Item label="Lernzeit der letzten 7 Tage">{
                    (() => {
                        if (player.lastDiligenceScore === 0 || player.lastDiligenceScore === null || player.lastDiligenceScore === undefined) {
                            return (
                                <div>n/a</div>
                            )
                        } else if (player.lastDiligenceScore < 61) {
                            return (
                                <div>{player.lastDiligenceScore} Minuten</div>
                            )
                        } else if (player.lastDiligenceScore > 60) {
                            return (
                                <div>{timeConvert(player.lastDiligenceScore)}</div>
                            )
                        }
                        else {
                            return (
                                <div>n/a</div>
                            )
                        }
                    })()
                }</Descriptions.Item>
                <Descriptions.Item label="Letzter bekannter Knowledge-Score">{player.lastKnowledgeScore}</Descriptions.Item>


            </Descriptions>

        </div>


    )
}

export default PlayerDetailView;
