import {shallowEqual, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import CatalogTableView from "./components/CatalogTableView";

const AllCatalogsPage = ({history, match}) => {

    const [catalogs, setCatalogs] = useState([]);

    // Access the Redux State
    const reduxEditors = useSelector(state => state.administration.editors);
    const [editors, setEditors] = useState(reduxEditors);

    useEffect(() => {
        console.log("reduxEditors: ", reduxEditors);

        const tableData = [];
        reduxEditors.forEach(editor => {
            editor.catalogs.forEach(catalog => {
                    const newEntry = {
                        key: catalog.uuid,
                        catalog: catalog,
                        editor: editor
                    }
                    tableData.push(newEntry);
                }
            )
        })

        console.log("Table Data: ", tableData)
        setCatalogs(tableData);

    }, [reduxEditors, editors])

    return (
        <div>
            <h1>Liste aller Kataloge</h1>
            <CatalogTableView tableData={catalogs}/>
        </div>
    )
}

export default AllCatalogsPage;
