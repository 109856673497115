import axios from 'axios';
import authHeader from "../services/auth-header";
import store from "../../redux/stores/store";
import {logout} from "../../redux/actions/auth";

/**
 * Create a new Axios client instance
 * @see https://github.com/mzabriskie/axios#creating-an-instance
 */

//const API_URL = "http://localhost:8008/v1/"
const API_URL = process.env.REACT_APP_BASE_URL

const getClient = (baseUrl = API_URL) => {

    const options = {
        baseURL: baseUrl
    };

    // Set the Authorization Header with the JWT Token
     options.headers = authHeader();

    // Create the Client
    const client = axios.create( options);



    // Add a request interceptor
    client.interceptors.request.use(
        requestConfig => requestConfig,
        (requestError) => {
            return Promise.reject(requestError);
        },
    );

    // Add a response interceptor
    client.interceptors.response.use(
        response => response,
        (error) => {
            if (!error.response) {
                // network error
                console.error("Network Error at Interceptor")
                this.errorStatus = 'Error: Network Error';
                return Promise.reject(error);
            }
            else if (401 === error.response.status) {
                console.error("401 Unauthorized at Interceptor")
                if(error.response.config.url !== "auth/login") {
                    store.dispatch(logout());
                    window.location = '/logout';
                }
                return Promise.reject(error);

            } else {
                console.error("Error at Interceptor: ", error)
                return Promise.reject(error);
            }
        },
    );

    return client;
};


class ApiClient {
    constructor(baseUrl = API_URL) {
        this.client = getClient(baseUrl);
    }

    get(url, conf = {}) {
        console.log("API Client started, call for Endpoint "+url)
        return this.client.get(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    delete(url, conf = {}) {
        return this.client.delete(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    head(url, conf = {}) {
        return this.client.head(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    options(url, conf = {}) {
        return this.client.options(url, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    post(url, data = {}, conf = {}) {
        return this.client.post(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    put(url, data = {}, conf = {}) {
        return this.client.put(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }

    patch(url, data = {}, conf = {}) {
        return this.client.patch(url, data, conf)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    }
}

export { ApiClient };

