import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import {Button, Table, Space, Divider, Row, Col, Modal} from "antd";
import RAQAClient from "../../../network/api/RaqaClient";
import TanCreationModal from "./TanCreationModal";

import styles from './tan.view.module.css';
import TanModifyModal from "./TanModifyModal";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";


const TanView = (props) => {

    const queryClient = useQueryClient();

    const { data: tans, refetch, isLoading, isSuccess, error } = useQuery(['allTans'],
        () => fetchTanList());

    const [createTanModalVisible, setCreateTanModalVisible] = useState(false);
    const [modifyTanData, setModifyTanData] = useState(null);
    const [deleteTanData, setDeleteTanData] = useState(null);

    const fetchTanList = async () => {

        let response = await RAQAClient.listTans();

        let tans = response.data || [];
        console.log("TANs", tans);
        return tans.map(t => {
            t.key = t.code;
            return t;
        })
    };

    // MARK: - Generate TANs

    const onGenerateTansClicked = () => {
        setCreateTanModalVisible(true);
    };

    const handleTansGenerated = () => {
        queryClient.invalidateQueries('allTans');
    }

    const handleCreateTanModalCloseClicked = () => {
        setCreateTanModalVisible(false);
    };

    // MARK: - Modify TAN

    const handleModifyTanModalCloseClicked = () => {
        setModifyTanData(null);
    };

    const handleTanUpdate = () => {
        queryClient.invalidateQueries('allTans');
    };

    // MARK: - Delete TAN

    const onClickDeleteTanData = (tanData) => {
        Modal.confirm(
            {
                title: "Diesen TAN-Code wirklich löschen?",
                content: "Soll dieser TAN-Code wirklich dauerhaft gelöscht werden?",
                onOk: () => {
                    handleDeleteTanData(tanData);
                    setDeleteTanData(null);
                },
                cancelText: "Abbruch"
            }
        )

    };

    const handleDeleteTanData = (tanData) => {
        RAQAClient.deleteTan(tanData.code)
        .then(response => {
            queryClient.invalidateQueries('allTans');
        });
    };

    const columns = [
        { title: 'TAN-Code', dataIndex: 'code', key: 'code'},
        { title: 'Vorname', dataIndex: 'firstName', key: 'tanFirstName'},
        { title: 'Nachname', dataIndex: 'lastName', key: 'tanLastName' },
        { title: 'E-Mail', dataIndex: 'email', key: 'tanEmail' },
        {
            title: 'Erstellt am', dataIndex: 'createdAt', key: 'tanCreatedAt', render: (createdAtTimestamp, index) => {
                if (createdAtTimestamp === null) {
                    return "- -"
                }
                var creationDate = new Date(createdAtTimestamp * 1000)
                var date = creationDate.toLocaleDateString("de-DE", { // you can use undefined as first argument
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });
                var time = creationDate.toLocaleTimeString("de-DE")
                return (
                    <div>
                        {date}
                        <br></br>
                        <span style={{ fontSize: 12 }}>{time}</span>
                    </div>
                )
            },
        },
        {
            title: 'Aktionen',
            dataIndex: '',
            key: 'tanActions',
            render: (text, record) => (
                <Space size="middle" split={<Divider type="vertical" />}>
                    <a onClick={e => {
                        e.stopPropagation();
                        console.log("Tan-Record: ", record)
                        setModifyTanData(record);

                    }}>
                        <EditOutlined />
                    </a>
                    <a onClick={e => {
                        e.stopPropagation();
                        onClickDeleteTanData(record);
                    }}>
                        <DeleteOutlined />
                        </a>
                </Space>
            ),
        },
    ];
return (
        <div>
            {(tans != null) && (
                <div>
                    <TanCreationModal
                        visible={createTanModalVisible}
                        onCloseClicked={handleCreateTanModalCloseClicked}
                        onTansGenerated={handleTansGenerated}
                    />

                    <Row>
                        <Col span={18} key={"listTan"}>
                            <h2>Liste aller TAN-Codes</h2>
                        </Col>
                        <Col style={{textAlign: "right"}} span={6} key={"buttonTan"}>

                            <Button className={styles.button} type="default" onClick={() => onGenerateTansClicked()}>
                                Tans erzeugen
                            </Button>
                        </Col>
                    </Row>

                    <Table
                        columns={columns}
                        dataSource={tans}
                    />

                    <TanModifyModal
                        key="modifyTanModal"
                        visible={modifyTanData != null}
                        tanData={modifyTanData}
                        onCloseClicked={handleModifyTanModalCloseClicked}
                        onTanDataSaved={handleTanUpdate}
                    />
                </div>
            )}
        </div>
);
};

export default TanView;
