import {Descriptions} from "antd";
import React from "react";

const UserDetailView = ({user}) => {

    const createdDate = new Date(user.createdAt*1000);
    const lastLoginDate = new Date(user.lastLoginAt*1000);

    return(
        <div>
            <Descriptions labelStyle={{fontSize: 15, fontWeight: 500, marginBottom: -20}}
                          contentStyle={{fontSize: 15, fontWeight: 300}}
                          layout="vertical"
                          size="medium"
                          bordered={true}
                          column={4}
            >
                <Descriptions.Item label="Name">{user.firstName} {user.lastName}</Descriptions.Item>
                <Descriptions.Item label="Benutzername">{user.username} </Descriptions.Item>
                <Descriptions.Item label="Rolle">
                    {
                        (() => {
                            if (user.role === "super") {
                                return (
                                    <div>Super User</div>
                                )
                            } else if (user.role === "admin") {
                                return (
                                    <div>Editor</div>
                                )
                            } else {
                                return (
                                    <div>App Nutzer</div>
                                )
                            }
                        })()
                    }
                </Descriptions.Item>
                <Descriptions.Item label="E-Mail">{user.email}</Descriptions.Item>
                <Descriptions.Item label="Letzter Login" span={2}>{lastLoginDate.getDate()}.{lastLoginDate.getMonth()+1}.{lastLoginDate.getFullYear()}</Descriptions.Item>
                <Descriptions.Item label="Account erstellt am" span={2}>{createdDate.getDate()}.{createdDate.getMonth()+1}.{createdDate.getFullYear()}</Descriptions.Item>
            </Descriptions>
        </div>
    )
}

export default UserDetailView;
