import React, {useContext, useEffect} from "react";
import {Card, Col, Row} from "antd";
import AnswerList from "./form-components/AnswerList";
import AnswerInput from "./form-components/AnswerInput";

const QuestionEditorAnswers = () => {

    return (
        <Row
            justify="start"
            align="middle"
            gutter={[28, 24]}
            className="todos-container"
            style={{marginTop:"25px"}}
        >
            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}}>
                <Card title="Antworten">
                    <AnswerList/>
                </Card>
            </Col>
            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}}>
                <Card title="Neue Antwort erstellen" style={{borderRadius: 15}}
                      headStyle={{
                          backgroundColor: 'rgba(245, 245, 245, 1)',
                          borderTopLeftRadius: 15,
                          borderTopRightRadius: 15
                      }}
                      bodyStyle={{backgroundColor: 'rgba(255, 255, 255, 0)', border: 1}}
                >
                    <AnswerInput/>
                </Card>
            </Col>
        </Row>
    )
}

export default QuestionEditorAnswers;
