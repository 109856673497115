export default function authHeader() {
    const user = JSON.parse(localStorage.getItem("user"));

    console.log("Getting auth header for item User");
    console.log(user);
    console.log("--------");

    if (user && user.accessToken) {

        console.log("Access Token:"+user.accessToken);

        // For Spring Boot back-end
        return { Authorization: "Bearer " + user.accessToken };

        // for Node.js Express back-end
        //return { "x-access-token": user.accessToken };
    } else {
        console.log("EMPTY ACCESS TOKEN");
        return {};
    }
}
