import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import UpdateVisibilityIcon from "../../../components/other/UpdateIcon";
import {Divider, Popconfirm, Space, Tag} from "antd";
import React from "react";


const resultColumns = (catalogs, categories, editQuestion, deleteQuestion, refreshTable ) => [
    {
        title: (<EyeOutlined />),
        dataIndex: 'isPublished',
        key: 'isPublished',
        width: '5%',
        render: (isPublished, record) => {
            return <UpdateVisibilityIcon questionId={record.uuid} publishState={isPublished} reloadTable={refreshTable} />
        },
    },
    {   title: 'Frage',
        dataIndex: 'text',
        key: 'text',
        width: '50%',
        render: (text, record) => {
            if (record.isPublished === true) {
                return `${text}`;
            } else {
                return <span style={{"color": "darkgrey"}}>{text}</span>; // just for decoration
            }
        }
    },
    {
        title: 'Kataloge',
        dataIndex: 'catalogNames',
        key: 'catalogs',
        width: '35%',
        render: catalogNames => (
            <div style={{  lineHeight: 2 }}>
                {catalogNames.map(tag => {
                    return (
                        <Tag color={tag === "Allgemein" ? "red" : "blue"} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })
                }
            </div>),
    },
    {
        title: 'Kategorien',
        dataIndex: 'categories',
        key: 'categories',
        width: '20%',
        render: categories => (
            <div style={{lineHeight: 2}}>
                {categories.map(tag => {
                    return (
                        <Tag size="small" color="green" key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })
                }
            </div>),
    },
    {
        title: 'Action',
        dataIndex: '',
        key: 'x',
        render: (text, record) => (
            <Space size="middle" split={<Divider type="vertical"/>}>
                <a onClick={e => {
                    e.stopPropagation();
                    editQuestion(record.uuid)
                }}><EditOutlined /></a>
                {deleteQuestion ?
                    <Popconfirm
                        title="Wollen Sie diese Frage wirklich löschen?"
                        onConfirm={e => { e.stopPropagation(); deleteQuestion(record.uuid) }}
                        onCancel={e => e.stopPropagation()}
                        okText="Ja"
                        cancelText="Nein"
                    >
                        <a onClick={e => e.stopPropagation()}><DeleteOutlined /></a>
                    </Popconfirm>
                    : <></>
                }

            </Space>
        ),
    },
];

export default resultColumns
