import React, {useState, useEffect} from 'react';
import {Form, Input, Button, Row, Col, Select, Modal} from 'antd';
import {UserOutlined, LockOutlined, MailOutlined, CustomerServiceOutlined} from '@ant-design/icons';
import Draggable from 'react-draggable';

import 'antd/dist/antd.min.css';
import RAQAClient from "../../network/api/RaqaClient";
import {useDispatch} from "react-redux";
import {LOGIN_SUCCESS, PASSWORD_UPDATE} from "../../redux/actions/types";


const PasswordChangeModal = (props) => {

    const {title = "Passwort ändern", confirmButtonText = "Ändern"} = props;
    const {visible: modalVisible} = props;
    const {onSuccess, onError, onCancel} = props;

    const dispatch = useDispatch();

    const [form] = Form.useForm();
    const [, forceUpdate] = useState({}); // To disable submit button at the beginning.

    const [modalDisabled, setModalDisabled] = useState(true);
    const [modalBounds, setModalBounds] = useState({left: 0, top: 0, bottom: 0, right: 0});

    const draggleRef = React.createRef();

    const onStart = (event, uiData) => {
        const {clientWidth, clientHeight} = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        setModalBounds({
            left: -targetRect?.left + uiData?.x,
            right: clientWidth - (targetRect?.right - uiData?.x),
            top: -targetRect?.top + uiData?.y,
            bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        });
    };


    useEffect(() => {
        forceUpdate({});
    }, []);

    useEffect(() => {
        form.resetFields();
    }, [form]);


    const onSubmitClicked = (e) => {
        form.submit();
    };

    const onModalCancel = () => {
        form.resetFields();
        onCancel();
    }

    const onFinish = (values) => {

        RAQAClient.changePassword(values["password"])
            .then(response => {
                console.log("Response: ", response);
                dispatch({
                    type: PASSWORD_UPDATE,
                    payload: response.data,
                });
                onSuccess();
            })
            .catch(error => {
                console.log("Error: ", error);
                onError(error)
            })
            .finally(() => {
                console.log("Finally");
                form.resetFields();
            });
    };

    return (
        <Modal
            forceRender
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        if (modalDisabled) {
                            setModalDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setModalDisabled(true);
                    }}
                    // fix eslintjsx-a11y/mouse-events-have-key-events
                    // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
                    onFocus={() => {
                    }}
                    onBlur={() => {
                    }}
                    // end
                >
                    {title}
                </div>
            }
            open={modalVisible}
            onCancel={onModalCancel}
            modalRender={modal => (
                <Draggable
                    disabled={modalDisabled}
                    bounds={modalBounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
            footer={[
                <Button key="back" onClick={onModalCancel}>
                    Abbrechen
                </Button>,
                <Button key="submit" type="primary" onClick={onSubmitClicked}>
                    {confirmButtonText}
                </Button>,
            ]}
        >
            <Form
                form={form}
                name="basic"
                layout="vertical"
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
                initialValues={{remember: true}}
                autoComplete="off"
                onFinish={onFinish}

            >
                <Form.Item
                    label="Neues Passwort"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Bitte geben Sie ein Passwort ein',
                        },
                        {
                            min: 4,
                            message: 'Passwort muss mindestens 4 Zeichen haben'
                        },
                    ]}
                >
                    <Input.Password placeholder="Passwort eingeben"/>
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Neues Passwort bestätigen"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Bitte bestätigen Sie das Passwort!',
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Die beiden Passwörter sind nicht identisch'));
                            },
                        }),
                    ]}
                >
                    <Input.Password/>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default PasswordChangeModal;