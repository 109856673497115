import {Divider, Space, Table, Tag} from "antd";
import React from "react";
import {CheckOutlined, CrownOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import {history} from "../../../../utils/history";

const CatalogTableView = ({tableData}) => {

    // User clicked the 'view' link of a catalog
    const viewCatalog = (record) => {

        history.push({
            pathname: '/catalog-content',
            state: {
                catalog: record.catalog,
                editor: record.editor
            }
        });

    };

    const columns = [
        {
            title: (<CrownOutlined/>),
            dataIndex: ["catalog", "isPublished"],
            key: 'isPublished',
            width: '5%',
            render: isPublished =>
                <span>{isPublished === true ? <CheckOutlined/> : ""} </span>
        },
        {
            title: 'Name', dataIndex: ["catalog", "title"], key: 'title', width: '25%', render: (text, record) => {
                if (record.catalog.isPublished === true) {
                    return `${text}`;
                } else {
                    return <span style={{"color": "darkgrey"}}>{text}</span>; // just for decoration
                }
            }
        },
        {
            title: 'Beschreibung',
            dataIndex: ["catalog", "description"],
            key: 'description',
            width: '40%',
            render: (text, record) => {
                if (record.catalog.isPublished === true) {
                    return `${text}`;
                } else {
                    return <span style={{"color": "darkgrey"}}>{text}</span>; // just for decoration
                }
            }
        },
        {title: 'Fragen', dataIndex: ["catalog", "questionCount"], key: 'questionCount', width: '5%'},
        {
            title: 'Editor', dataIndex: ["editor"], key: 'editor', width: '20%', render: (text, record) => {
                return `${text.firstName} ${text.lastName}`; // just for decoration
            }
        },
        {
            title: 'Aktionen',
            dataIndex: '',
            width: '5%',
            key: 'x',
            render: (text, record) => (
                <Space size="middle" split={<Divider type="vertical"/>}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={e => {
                        e.stopPropagation();
                        viewCatalog(record);
                    }}>
                        <EyeOutlined/></a>
                </Space>
            ),
        },


    ];

    return (
        <Table
            columns={columns}
            dataSource={tableData}
        />
    )

}

export default CatalogTableView;
