import React, {useRef} from "react";

import QuestionEditorHeader from "./components/question.editor.header";
import {QuestionFormContentProvider} from "./QuestionFormContext";
import QuestionEditorContent from "./components/question.editor.content";
import QuestionEditorForm from "./components/question.editor.form";
import QuestionEditorAnswers from "./components/question.editor.answers";
import QuestionEditorImages from "./components/question.editor.images";
import QuestionEditorCatalogsAndCategories from "./components/question.editor.catalogs-and-categories";
import QuestionEditorAdditionalInfo from "./components/question.editor.additional-info";

const QuestionFormPage = ({match}) => {

    // If there is an ID in the params, we have the edit mode
    const {id} = match.params;

    return (
        <QuestionFormContentProvider addQuestionMode={!id} questionId={id}>
            <QuestionEditorForm>
                <QuestionEditorHeader/>
                <QuestionEditorContent/>
                <QuestionEditorAnswers/>
                <QuestionEditorCatalogsAndCategories/>
                <QuestionEditorImages/>
                <QuestionEditorAdditionalInfo/>
            </QuestionEditorForm>
        </QuestionFormContentProvider>
    )
}

export default QuestionFormPage;
