import {Button, Col, Row, Select, Space, Spin} from "antd";
import React, {useContext, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import {QuestionContext} from "../../../context/questionContext";
import {QuestionFormContext} from "../QuestionFormContext";

const newQuestionTitleString = "Neue Frage erstellen";
const editQuestionTitleString = "Frage editieren";
const addQuestionInfoString = "Erstellen Sie hier eine Frage und die zugehörigen Antworten";
const editQuestionInfoString = "Editieren Sie hier die Frage und die zugehörigen Antworten";

const QuestionEditorHeader = () => {

    // History to go back and push to a different page
    let history = useHistory();

    const { answers} = useContext(QuestionContext);

    // Context API
    const {isAddMode, isLoading, questionEditorName, editorLastModifiedName} = useContext(QuestionFormContext);

    const [pageHeaderTitle, setPageHeaderTitle] = useState(isAddMode ? newQuestionTitleString : editQuestionTitleString);
    const [pageHeaderSubtitle, setPageHeaderSubtitle] = useState(isAddMode ? addQuestionInfoString : editQuestionInfoString);

    useEffect(() => {
        setPageHeaderTitle( isAddMode ? newQuestionTitleString : editQuestionTitleString);
        setPageHeaderSubtitle(isAddMode ? addQuestionInfoString : editQuestionInfoString);
    }, [isAddMode])

    function goToQuestionList() {
        history.goBack()
    }

    return (
        <Row justify="space-around" align="left" gutter={[24, 24]} style={{marginBottom: 20}}>
            <Col span={16} offset={0}>
                <Spin spinning={isLoading}>
                    <div>
                        <span style={{fontSize: 20, fontWeight: 600}}>{pageHeaderTitle}</span>
                        <div><span>{pageHeaderSubtitle}</span></div>
                    </div>
                </Spin>
            </Col>

            <Col span={8}>
                <div style={{textAlign: 'right'}}>
                    <Space>
                        <Button key="buttonList" style={!isAddMode ? {} : {display: 'none', padding: 20}}
                                type="secondary" onClick={e => {
                            e.stopPropagation();
                            goToQuestionList()
                        }}>Zurück</Button>

                        <Button key="buttonSave" type="primary" htmlType="submit"
                                disabled={answers.length < 2}>
                            Frage speichern
                        </Button>
                    </Space>

                </div>
                { !isAddMode &&
                    <div>
                        <div style={{textAlign: "right", fontSize: 12, marginTop: 20}}>
                            <span>Erstellt von: {questionEditorName} </span>
                        </div>
                        <div style={{textAlign: "right", fontSize: 12}}>
                            <span>Geändert von: {editorLastModifiedName} </span>
                        </div>
                    </div>
                }
            </Col>
        </Row>

    )
}

export default QuestionEditorHeader
