import React from "react";
import {List, Card, Divider, Space} from "antd";
import {history} from "../../utils/history";

import styles from './catalog.card.module.css';

const CatalogCardListView = ({catalogs, editor}) => {

    // Handler functions

    // User clicked the 'add' link of a catalog
    function onClickShowCatalogContent(catalog) {
        console.log('Add Question to Catalog clicked', catalog);
        history.push({
            pathname: '/catalog-content',
            state: {
                catalog: catalog,
                editor: editor
            }
        });
    };

    return (
        <List
            grid={{
                gutter: 24,
                xs: 1,
                sm: 2,
                md: 3,
                lg: 3,
                xl: 3,
                xxl: 3,
            }}
            dataSource={catalogs}
            renderItem={catalog => (
                <List.Item>
                    <Card className={styles['catalogCard']} type="inner" title={catalog.title}>
                        <div className={styles['catalogCardDesc']}>
                            {catalog.description}


                        </div>
                        <Divider/>
                        <Space direction="horizontal" style={{width: '100%', justifyContent: 'space-evenly'}}>

                            <div>{catalog.questionCount} Fragen</div>
                            <div>
                                <a onClick={e => {
                                    e.stopPropagation();
                                    onClickShowCatalogContent(catalog)
                                }}>
                                    Katalog anzeigen
                                </a>
                            </div>
                        </Space>

                    </Card>
                </List.Item>
            )}
        />
    )
}

export default CatalogCardListView;