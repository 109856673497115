import React, {createContext, useReducer, useState} from "react";
import questionReducer from "./questionReducer";
import {message} from "antd";
import {useSelector} from "react-redux";

// Define the default value
export const initialQuestionState = {
    question: "",
    answers: [],
    categories: []
};

export const emptyQuestionState = {
    question: "",
    answers: [],
    categories: []
};

// Create context
export const QuestionContext = createContext(initialQuestionState);

// Create Provider
export function QuestionContentProvider({ children }) {
    // Use reducer
    const [state, dispatch] = useReducer(questionReducer, initialQuestionState);
    const [isOnlySingleAnswer, setOnlySingleAnswerAllowed] = useState(true);

    // Define reducer actions
    function setQuestionText(text) {
        dispatch({
            type: "SET_QUESTION_TEXT",
            payload: text,
        });
    }

    function resetQuestion() {
        dispatch({
            type: "RESET_QUESTION",
            payload: emptyQuestionState,
        });
    }

    function addAnswer(answer) {
        console.log("Dispatch ADD_ANSWER");
        console.log("Payload: "+answer);

        dispatch({
            type: "ADD_ANSWER",
            payload: answer,
        });
    }

    function deleteAnswer(id) {
        console.log("Dispatch DELETE_ANSWER");
        console.log("Payload: "+id);

        dispatch({
            type: "DELETE_ANSWER",
            payload: id,
        });
    }

    function resetAnswers() {
        console.log("Dispatch RESET_ANSWER");

        dispatch({
            type: "RESET_ANSWERS",
            payload: [],
        });
    }

    function changeAnswer(ident, newAnswer) {
        console.log("Dispatch CHANGE_ANSWER");
        console.log("Payload id: "+ident);
        console.log("Payload newAnswer: "+newAnswer);

        dispatch({
            type: "CHANGE_ANSWER",
            payload: {
                ident,
                newAnswer,
            },
        });
    }

    function toggleAnswer(id, correct) {
        console.log("Dispatch TOGGLE_ANSWER");
        console.log("Payload id: "+id);
        console.log("Payload correct: "+correct);
        console.log("Is single answer: "+isOnlySingleAnswer);

        dispatch({
            type: "TOGGLE_ANSWER",
            payload: {
                id,
                correct,
            },
        });
    }

    return (
        <QuestionContext.Provider
            value={{
                question: state.question,
                answers: state.answers,
                categories: state.categories,
                addAnswer,
                setQuestionText,
                deleteAnswer,
                toggleAnswer,
                resetAnswers,
                resetQuestion,
                changeAnswer,
                setOnlySingleAnswerAllowed,
                isOnlySingleAnswer
            }}
        >
            {children}
        </QuestionContext.Provider>
    );
}