import React from "react";
import { useState } from "react";
import {useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import {Button, Divider, PageHeader, Popconfirm, Space, Spin, Table, Tooltip} from "antd";
import {CheckOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import RAQAClient from '../../network/api/RaqaClient';

import ContentGroupCreateModal from "./ContentGroupCreateModal";


const ContentGroupView = ({history, match}) => {

    const location = useLocation();

    const [tableData, setTableData] = useState([]);
//    const [contentgroupToEdit, setContentgroupToEdit] = useState();
    const [createContentGroup, setCreateContentGroup] = useState(false);

    const fetchContentGroups = async () => {
        console.log("=== fetchContentGroups ===");

        try {
            const data = await RAQAClient.listContentgroups();
            console.log('Data', data);
            return data;
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    /// New question data (or error) received
    const onContentGroupsSettled = (data, error) => {
        if (error) {
            console.log('Error', error);
        }
        setTableData(data?.data?.items || []);
    };

    const { data: contentGroups, refetch, isLoading: isFetching, } = useQuery(['contentGroups'], () => fetchContentGroups(), {
            keepPreviousData: true,
            onSettled: (data, error) => onContentGroupsSettled(data, error)
    });

    const onEditFinished = async (contentGroup) => {
        await RAQAClient.editContentGroup(contentGroup);
        await refetch();
    };

    const onCreateFinished = async (contentGroup) => {
        await RAQAClient.createContentGroup(contentGroup);
        await refetch();
    };

    // // User clicked the 'edit' link of a contentgroup
    // const editContentgroup = (contentgroup) => {
    //     console.log("Edit record", contentgroup);
    //     setContentgroupToEdit(contentgroup);
    // };

    const deleteContentGroup = async (contentGroupId) => {
        try {
            await RAQAClient.deleteContentGroup(contentGroupId);
            await refetch();
        } catch (error) {
            console.error(`Delete contentGroup`, error);
        }
    };

    const handleCreateButtonClicked = () => {
        setCreateContentGroup(true);
    };

    const handleContentGroupSelected = (contentGroupId) => {
        history.push(`/content-group-detail/${contentGroupId}`);
    };

    const actionColumn = {
        title: 'Aktion',
            dataIndex: '',
        key: 'action',
        width: '5%',
        render: (text, record) => (
        <Space size="middle" split={<Divider type="vertical"/>}>
            <Tooltip title ='Editieren'>
                <a onClick={e => {
                    e.stopPropagation();
                    handleContentGroupSelected(record.id);
                    //editContentgroup(record);
                }}><EditOutlined /></a>
            </Tooltip>
            <Tooltip title ='L&ouml;schen'>
                <Popconfirm
                    title="Wollen Sie diese Lerngruppe wirklich löschen?"
                    onConfirm={e => { e.stopPropagation(); deleteContentGroup(record.id) }}
                    onCancel={e => e.stopPropagation()}
                    okText="Ja"
                    cancelText="Nein">
                    <a onClick={e => e.stopPropagation()}><DeleteOutlined /></a>
                </Popconfirm>
            </Tooltip>
        </Space>
    )};

    const columns = [
        {
            title: () => <Tooltip title ='Name der Lerngruppe'>Name</Tooltip>,
            dataIndex: 'title',
            width: '65%',

        },
        {
            title: () => <Tooltip title ='Anzahl der der Lerngruppe zugewiesenen Kataloge'>Kataloge</Tooltip>,
            dataIndex: 'assignedCatalogs',
            width: '15%',

        },
        {
            title: <Tooltip title ='Anzahl der Spieler, die Zugriff auf dieses Lerngruppe haben'>Spieler</Tooltip>,
            dataIndex: 'assignedPlayers',
            width: '15%',

        },
        actionColumn
    ];

    return (
        <div>
            <Spin spinning={isFetching}>
                <PageHeader
                    ghost={true}
                    title="Lerngruppen"
                    subTitle="Hier sehen Sie alle Ihre bereits erstellten Lerngruppen"
                    extra={[
                        <Tooltip title ='Neue Lerngruppe hinzuf&uuml;gen'>
                            <Button key="1" type="primary" onClick={() => handleCreateButtonClicked()}>Neu</Button>
                        </Tooltip>
                    ]}>
                </PageHeader>
                <div>
                    {/* <div style={{paddingBottom: 10}}/> */}
                    <Table
                        style={{'cursor': 'pointer'}}
                        loading={isFetching}
                        columns={columns}
                        dataSource={tableData}
                        rowKey={contentGroup => contentGroup.id}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (event) => { handleContentGroupSelected(record.id); },
                            };
                        }}
                    />
                </div>
                <ContentGroupCreateModal
                    open={createContentGroup}
                    onCancel={() => {
                        setCreateContentGroup(false);
                    }}
                    onSave={(contentGroup) => {
                        setCreateContentGroup(false);
                        onCreateFinished(contentGroup);
                    }}
                />
            </Spin>
        </div>
    );
};

export default ContentGroupView;
