import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Select, Modal } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import Draggable from 'react-draggable';

import 'antd/dist/antd.min.css';



const CatalogInputModal = (props) => {

    const { title = "Fragenkatalog anlegen", confirmButtonText = "Speichern" } = props;
    const { visible: modalVisible } = props;
    const { onOkClicked, onCancelClicked } = props;

    const [form] = Form.useForm();
    const [, forceUpdate] = useState({}); // To disable submit button at the beginning.

    const [modalDisabled, setModalDisabled] = useState(true);
    const [modalBounds, setModalBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });

    const draggleRef = React.createRef();

    const onStart = (event, uiData) => {
        const { clientWidth, clientHeight } = window?.document?.documentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        setModalBounds({
            left: -targetRect?.left + uiData?.x,
            right: clientWidth - (targetRect?.right - uiData?.x),
            top: -targetRect?.top + uiData?.y,
            bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        });
    };


    useEffect(() => {
        forceUpdate({});
    }, []);

    useEffect(() => {
        console.log("Resetting all fields");
        form.resetFields();
    }, [form]);


    const onSubmitClicked = (e) => {
        console.log('onSubmitClicked:', e);
        form.submit();
    };

    const onFinish = (values) => {
        console.log('onFinish:', values);
        onOkClicked?.(values);
        form.resetFields();
    };

    return (
        <Modal
            forceRender
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        if (modalDisabled) {
                            setModalDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setModalDisabled(true);
                    }}
                    // fix eslintjsx-a11y/mouse-events-have-key-events
                    // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
                    onFocus={() => { }}
                    onBlur={() => { }}
                    // end
                >
                    {title}
                </div>
            }
            open={modalVisible}
            onOk={onOkClicked}
            onCancel={onCancelClicked}
            modalRender={modal => (
                <Draggable
                    disabled={modalDisabled}
                    bounds={modalBounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
            footer={[
                <Button key="back" onClick={onCancelClicked}>
                    Abbrechen
                </Button>,
                <Button key="submit" type="primary" onClick={onSubmitClicked}>
                    {confirmButtonText}
                </Button>,
            ]}
        >
            <Form
                form={form}
                name="basic"
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                onFinish={onFinish}

            >
                <Form.Item
                    label="Name des Fragenkatalogs"
                    name="catalogName"
                    rules={[{ required: true, message: 'Bitte einen Namen für den Fragenkatalog eingeben' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Beschreibung des Fragenkatalogs"
                    name="description"
                >
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default CatalogInputModal;