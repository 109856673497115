import {useQuery, useQueryClient} from "react-query";
import RAQAClient from "../../../network/api/RaqaClient";
import {SET_EDITORS} from "../../../redux/actions/types";
import {Divider, Space, Table} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import React from "react";
import {history} from "../../../utils/history";


const PlayerList = (props) => {

    // Query Client
    const queryClient = useQueryClient()
    const {data, isLoading: isFetching, refetch: refetchPlayers} = useQuery("players",
        () => fetchPlayers()
    );

    // -- Click handler methods -------------------------------------

    // User clicked the 'view' link of a catalog
    const viewPlayer = (playerData) => {
        console.log('Player clicked', playerData);

        history.push({
            pathname: '/player-detail',
            state: {player: playerData}
        });

    };


    // -- Async API methods -------------------------------------
    const fetchPlayers = async () => {

        const response = await RAQAClient.listUsers("player",true);
        console.log("Response Players", response)
        const players = response.data || [];
        console.log("Players: ", players);

        return players.map(u => {
            u.key = u.id;
            return u;
        })
    }

    const columns = [
        {title: 'Vorname', dataIndex: 'firstName', key: 'title',},
        {title: 'Nachname', dataIndex: 'lastName', key: 'description'},
        {title: 'Username', dataIndex: 'username', key: 'numberQuestions'},
        {title: 'Letzter Login', dataIndex: 'lastLoginAt', key: 'lastLogin',  render: (loginTimestamp, index) => {
                if(loginTimestamp === null) {
                    return "Nicht vorhanden"
                }
                var lastLoginAt = new Date(loginTimestamp*1000)
                var result = lastLoginAt.toLocaleDateString("de-DE", { // you can use undefined as first argument
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                });
                return result+ " Uhr"
            }
        },
        {title: 'Account seit', dataIndex: 'createdAt', key: 'createdAt',  render: (createdAtTimestamp, index) => {
                if(createdAtTimestamp === null) {
                    return "Nicht vorhanden"
                }
                var lastLoginAt = new Date(createdAtTimestamp*1000)
                var result = lastLoginAt.toLocaleDateString("de-DE", { // you can use undefined as first argument
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });
                return result
            }
        },
        {
            title: 'Aktionen',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <Space size="middle" split={<Divider type="vertical"/>}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

                    <a onClick={e => {
                        e.stopPropagation();
                        viewPlayer(record);
                    }}>
                        <EyeOutlined/></a>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <h2>Liste alle App Nutzer</h2>
            <Table
            columns={columns}
            dataSource={data}
        /></div>
    )
}

export default PlayerList;
