import {ApiClient} from "./ApiClient";
import store from '../../redux/stores/store'

let client = new ApiClient(process.env.REACT_APP_BASE_URL);

const bearerHeader = () => {

    const accessToken = store.getState().auth?.user?.accessToken;
    if (accessToken) {
        return {Authorization: "Bearer " + accessToken};
    } else {
        const user = JSON.parse(localStorage.getItem("user"));
        //console.log("Getting auth header for item User");
        //console.log(user);
        //console.log("--------");
        if (user && user.accessToken) {
            //console.log(user.accessToken);
            // For Spring Boot back-end
            return {Authorization: "Bearer " + user.accessToken};
        } else {
            return undefined;
        }
    }
}

const RAQAClient = {

    // User API calls
    login(userId, password) {
        console.log("User ID: ", userId, "Passwort: ", password);
        const jsonBody = {'usernameOrEmail': userId, password: password}
        return client.post(`auth/login`, jsonBody);
    },

    // Changes the password for the admin and superuser
    changePassword(newPassword) {
        const jsonBody = { password: newPassword}
        return client.put(`user/password`, jsonBody, {headers: bearerHeader()});
    },


    // Question Categories
    listQuestionsCategories() {
        return client.get("question/categories", {headers: bearerHeader()});
    },

    /*
    ////////////////////////////////
    // Question API Endpoints     //
    ////////////////////////////////
    */

    // Question CRUD - Create
    createQuestion(questionData) {
        return client.post("question", questionData, {headers: bearerHeader()});
    },

    // Question CRUD - Create
    createQuestionWithImages(formData) {
        return client.post(
            "question",
            formData,
            {
                headers: bearerHeader(), "Content-Type": "multipart/form-components-data",
            }
        );
    },


    // Question CRUD - Read
    getQuestion(questionId) {
        return client.get(`question/${questionId}`, {headers: bearerHeader()});
    },

    // Question CRUD - Update
    updateQuestion(questionId, data) {
        return client.put(`question/${questionId}`, data, {headers: bearerHeader()});
    },

    // Question CRUD - Delete
    deleteQuestion(questionId) {
        return client.delete(`question/${questionId}`, {headers: bearerHeader()});
    },

    // Question CRUD - READ all questions
    listQuestions() {
        return client.get(`question/`, {headers: bearerHeader()});
    },


    // Question CRUD - READ all questions
    listGeneralCatalogQuestions(pageNumber, searchString, pageSize, filteredCategories) {

        if(searchString === undefined) {
            searchString = ""
        }
        else if(searchString.length < 3) {
            searchString = ""
        }

        const params = new URLSearchParams([['page', pageNumber], ['pageSize', pageSize],  ['search', searchString]]);

        if(filteredCategories !== undefined) {
            for(const category of filteredCategories) {
                params.append("category", category)
            }
        }

        return client.get(`question/catalog/general/`, {headers: bearerHeader(), params: params} );
    },

    /*
   ////////////////////////////////
   // Search API Endpoints     //
   ////////////////////////////////
   */

    getSearchResults(pageNumber, pageSize, searchString, filteredCategories, filteredCatalogs, includeGeneral, listOfEditors) {


        if(searchString === undefined || searchString.length < 3) {
            searchString = ""
        }

        const jsonBody = {
            page: pageNumber,
            pageSize: pageSize,
            search: searchString,
            categories: filteredCategories,
            catalogUuids: filteredCatalogs,
            isGeneral: includeGeneral,
            editorIds: listOfEditors
        }

        //console.log(jsonBody)

        return client.post('search/question', jsonBody, {headers: bearerHeader()});

    },

    /*
    ////////////////////////////////
    // Catalogs API Endpoints     //
    ////////////////////////////////
    */

    insertCatalog(catalogData) {
        return client.post(`catalog/`, catalogData, {headers: bearerHeader()});
    },

    updateCatalog(catalogId, catalogData) {
        return client.put(`catalog/${catalogId}`, catalogData, {headers: bearerHeader()});
    },

    listCatalogs() {
        return client.get(`catalog/`, {headers: bearerHeader()});
    },

    deleteCatalog(catalogId) {
        return client.delete(`catalog/${catalogId}`, {headers: bearerHeader()});
    },

    /*
    //////////////////////////////////////////////
    // Editor Endpoints (only for Super User    //
    //////////////////////////////////////////////
    */

    // Question CRUD - Create
    createEditor(editorData) {
        return client.post("user/", editorData, {headers: bearerHeader()});
    },

    // Question CRUD - Create
    listUsers(type, withCatalogs) {
        const params = new URLSearchParams([['role', type], ['catalogs', withCatalogs]]);
        return client.get(`user/`, {headers: bearerHeader(), params: params});
    },

    // Question CRUD - Create
    deleteUser(userId) {
        return client.delete(`user/${userId}`, {headers: bearerHeader()});
    },

    // Question CRUD - Create
    updateUser(userId, userData) {
        return client.put(`user/${userId}`, userData, {headers: bearerHeader()});
    },

    /*
   //////////////////////////////////////////////
   // Editor Endpoints for Catalogs            //
   //////////////////////////////////////////////
   */

    // Question CRUD - Create
    listQuestionsForCatalog(catalogUuid) {
        return client.get(`question/catalog/${catalogUuid}`, {headers: bearerHeader()});
    },


    // Question CRUD - Create
    reassignContent(fromUserId, toUserId) {
        const toUserData = {"userId": toUserId};
        return client.patch(`user/${fromUserId}/reassign-content`, toUserData, {headers: bearerHeader()});
    },

    /*
    //////////////////////////////////////////////
    // Statistik Endpoints (Super User)            //
    //////////////////////////////////////////////
    */

    // Stats - GET
    getStatistics() {
        return client.get(`stats`, {headers: bearerHeader()});
    },

    listDifficultQuestions(minSuccessRatio, maxSuccessRatio, page, pageSize, minAnswers, sort) {
        const params = new URLSearchParams([['minSuccessRatio', minSuccessRatio], ['maxSuccessRatio', maxSuccessRatio], ['page', page], ['pageSize', pageSize], ['minAnswers', minAnswers], ['sort', sort]]);
        return client.get(`stats/questions-success-ratio`, {headers: bearerHeader(), params: params});
    },

    /*
   //////////////////////////////////////////////
   // Editor Endpoints for Tans                //
   //////////////////////////////////////////////
   */

    // Tans - List
    listTans() {
        return client.get(`tan`, {headers: bearerHeader()});
    },

    // Tans - Create
    generateTans(count) {
        const body = { "count": count };
        return client.post('tan/generate', body, {headers: bearerHeader()});
    },

    // Tan - Update
    updateTans(tanDataArray) {
        const body = { "items": tanDataArray };
        return client.put('tan', body, {headers: bearerHeader()});
    },

    // Tan - Delete
    deleteTan(tanCode) {
        return client.delete(`tan/${tanCode}`, {headers: bearerHeader()})
    },

    // Tans - User Upload
    uploadUserForTanGeneration(formData) {
        return client.post(`tan/invite-csv`,  formData,{
            headers: bearerHeader(),
        });
    },

    // IMAGES
    getImage(imageUuid) {
        return client.get(`question/image/${imageUuid}`, {
            headers: bearerHeader(),
            responseType: "blob",
           // responseEncoding: "base64",
        });
    },

    deleteImage(questionId, imageUuid) {
        return client.delete(`question/${questionId}/image/${imageUuid}`, {
            headers: bearerHeader(),
        });
    },

    uploadImageForQuestion(questionId, formData) {
        return client.post(`question/${questionId}/image`,  formData,{
            headers: bearerHeader(),
        });
    },
    /*
   //////////////////////////////////////////////
   // Editor Endpoints for Contentgroups       //
   //////////////////////////////////////////////
   */

    // Tans - List
    listContentgroups() {
        return client.get(`contentgroup`, {headers: bearerHeader()});
    },

    getContentgroup(contentgroupId) {
        return client.get(`contentgroup/${contentgroupId}`, {headers: bearerHeader()});
    },

    editContentGroup(contentgroup) {
        const body = { "title": contentgroup.title };
        return client.put(`contentgroup/${contentgroup.id}`, body, {headers: bearerHeader()});
    },

    createContentGroup(contentgroup) {
        return client.post(`contentgroup`, contentgroup, {headers: bearerHeader()});
    },

    removeCatalogsFromContentGroup(contentGroupId, catalogUuids) {
        const body = {"catalogUuids": catalogUuids};
        //console.log("contentGroupId", contentGroupId);
        //console.log(JSON.stringify(body));

        return client.delete(`contentgroup/${contentGroupId}/catalog`,   {
            headers: bearerHeader(),
            data: body
        });
    },

    removePlayersFromContentGroup(contentGroupId, playerIds) {
        const body = {"userIds": playerIds};

        return client.delete(`contentgroup/${contentGroupId}/player`,   {
            headers: bearerHeader(),
            data: body
        });
    },

    addCatalogsToContentGroup(contentGroupId, newCatalogs) {
        const body = {"catalogUuids": newCatalogs};
        return client.put(`contentgroup/${contentGroupId}/catalog`, body, {headers: bearerHeader()});
    },

    addPlayersToContentGroup(contentGroupId, newPlayers) {
        const body = {"userIds": newPlayers};
        return client.put(`contentgroup/${contentGroupId}/player`, body, {headers: bearerHeader()});
    },


    deleteContentGroup(contentgroupId) {
        return client.delete(`contentgroup/${contentgroupId}`, {headers: bearerHeader()});
    },


    getContentGroupUsers(contentgroupId) {
        return client.get(`contentgroup/${contentgroupId}/player`, {headers: bearerHeader()});
    },

    getContentgroupCatalogs(contentgroupId) {
        return client.get(`contentgroup/${contentgroupId}/catalog`, {headers: bearerHeader()});
    },

};

export default RAQAClient;
