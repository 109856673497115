import CatalogAdminList from "../../pages/admin/catalog/catalog.admin.list";
import CatalogEditorsList from "../../pages/admin/catalog/catalog.editors.list";
import GeneralQuestionList from "../question/question.general.list";

const GeneralCatalogView = () => {

    return (
        <GeneralQuestionList/>
    )
}

export default GeneralCatalogView;
