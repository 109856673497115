import {Button, Divider, message, PageHeader, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import RAQAClient from "../../../network/api/RaqaClient";
import {useQuery, useQueryClient} from "react-query";
import CatalogInputModal from "./components/CatalogInputModal";
import {history} from "../../../utils/history";
import {useDispatch, useSelector} from "react-redux";
import {CheckOutlined, EditOutlined, FileAddOutlined} from "@ant-design/icons";


const CatalogAdminList = (props) => {

    // -- MODAL HANDLING ----------------------------------------
    const [inputModalVisible, setInputModalVisible] = useState(false);

    // All catalogs from the redux store
    const currentEditor = useSelector((state) => (state.auth.user.user));
    const catalogs = useSelector((state) => (state.questions.catalogs));

    const [editorCatalogs, setEditorCatalogs] = useState([]);

    // Run only once
    useEffect(() => {
        console.log("Current Editor: "+currentEditor.id);
        const myCatalogs = catalogs.filter(catalog => catalog.userId === currentEditor.id);
        console.log("Meine Kataloge: "+myCatalogs);
        setEditorCatalogs(myCatalogs);
    }, [catalogs]);

    const handleInputModalConfirmed = (values) => {

        // Create the JSON Body for the API call
        const newCatalog = {
            title: values.catalogName,
            description: values.description || "",
            isPublished: false
        }

        setInputModalVisible(false);
        createCatalog(newCatalog).then(r => {
            console.log("Catalog successfully created!")
        });
    };

    const handleInputModalCanceled = () => {
        setInputModalVisible(false);
    };

    const handleCreateButtonClicked = () => {
        console.log("Create catalog clicked !");
        setInputModalVisible(true);
    }

    // User clicked the 'edit' link of a catalog
    const editCatalog = (catalogData) => {
        console.log('Edit Catalog clicked', catalogData);
        history.push({
            pathname: '/catalog-edit',
            state: { catalog: catalogData }
        });
    };

    // User clicked the 'add' link of a catalog
    const addQuestionToCatalog = (catalogId) => {
        console.log('Add Question to Catalog clicked', catalogId);
        history.push({
            pathname: '/question-create',
            state: { catalogId: catalogId }
        });
    };



    const createCatalog = async (catalog) => {
        try {
            await RAQAClient.insertCatalog(catalog);
        } catch (error) {
            console.log('Error during catalog creation', error);
            const response = error.response;
            if (response) {
                const messageContent = (
                    <>
                        <h2>Es ist ein Fehler aufgetreten</h2>
                        <h3>Status: {`${response.status} (${response.statusText})`}</h3>
                        <h3>{`Grund: ${response.data?.userMessage || response.data?.message || response.data?.code || 'nicht verfügbar'}`}</h3>
                    </>
                );
                message.error(messageContent);
            } else {
                message.error(error.toString());
            }
        }
        // await queryClient.invalidateQueries('catalogs');
    };

    const columns = [
        {
            title: 'Katalog', dataIndex: 'title', key: 'title', width: '30%', render: (text, record) => {
                if (record.isPublished === true) {
                    return `${text}`;
                } else {
                    return <span style={{"color": "darkgrey"}}>{text}</span>; // just for decoration
                }
            }
        },
        {
            title: 'Beschreibung',
            dataIndex: 'description',
            key: 'description',
            width: '40%',
            render: (text, record) => {
                if (record.isPublished === true) {
                    return `${text}`;
                } else {
                    return <span style={{"color": "darkgrey"}}>{text}</span>; // just for decoration
                }
            }
        },
        { title: 'Fragen', dataIndex: 'numberQuestions', key: 'numberQuestions' },
        { title: 'Veröffentlicht', dataIndex: 'isPublished', key: 'isPublished', render: isPublished =>
                <span>{isPublished === true ? <CheckOutlined /> : ""} </span>
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'action',
            render: (text, record) => (
                <Space size="middle" split={<Divider type="vertical"/>}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={e => {
                        e.stopPropagation();
                        editCatalog(record)
                    }}><EditOutlined /></a>
                    <a onClick={e => {
                        e.stopPropagation();
                        addQuestionToCatalog(record.uuid)
                    }}><FileAddOutlined /></a>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <PageHeader
                ghost={true}
                title="Fragenkataloge"
                subTitle="Hier sehen Sie alle Ihre bereits erstellten Fragenkataloge"
                extra={[
                    <Button key="1" type="primary" onClick={() => handleCreateButtonClicked()}>Neu</Button>
                ]}>
            </PageHeader>
            <CatalogInputModal
                visible={inputModalVisible}
                onOkClicked={handleInputModalConfirmed}
                onCancelClicked={handleInputModalCanceled}
            />
            <Table
                columns={columns}
                dataSource={editorCatalogs}
                rowKey="title"
            />
        </div>
    )
}

export default CatalogAdminList
