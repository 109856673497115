import {SearchContentProvider} from "./SearchContext";
import SearchPanel from "./SearchPanel";
import SearchResult from "./SearchResult";
import React, {useRef} from "react";
import {PageHeader} from "antd";

const ExtendedSearchView = (props) => {

    const searchPanelComp = useRef(null);

    return (
        <SearchContentProvider
            isGeneral={"undefined"}
        >
            <SearchPanel
                triggerSearch={() => {searchPanelComp.current.startSearch();}}
                showCatalogs={true}
                showGeneralQuestionSelection={true}
            />
            <PageHeader
                title="Suchergebnisse"
                id="pageHeader"
                subTitle="Alle Fragen die auf die Suchparameter zutreffen"
            />
            <SearchResult
                ref={searchPanelComp}
                allowDeleteQuestions={false}
                editorList={[]}
            />
        </SearchContentProvider>
    )
}

export default ExtendedSearchView;
