import {Card, Col, Form, Input, Row} from "antd";
import React, {useContext, useEffect, useState} from "react";
import TextArea from "antd/es/input/TextArea";


const QuestionEditorAdditionalInfo = () => {

    return (
        <Row
            justify="start"
            align="middle"
            gutter={[28, 24]}
            className="todos-container"
            style={{marginTop: "25px"}}
        >

            {/* The additional values section */}
            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}}>
                <Card title="Weitere Werte" style={{borderRadius: 15}}
                      headStyle={{
                          backgroundColor: 'rgba(245, 245, 245, 1)',
                          borderTopLeftRadius: 15,
                          borderTopRightRadius: 15
                      }}
                      bodyStyle={{backgroundColor: 'rgba(255, 255, 255, 0)', border: 1}}
                >

                    {/* Checkbox for additionalInfo value */}
                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}}>
                        <Form.Item
                            label="Zusatzinformationen zur Frage"
                            name="answerInfo"
                        >
                            <TextArea maxLength="1024"/>
                        </Form.Item>
                    </Col>

                    {/* Input for answerUrl value */}
                    <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 24}} xl={{span: 24}}>
                        <Form.Item
                            label='URL zur Frage'
                            name='answerUrl'
                            rules={[{type: "url", message: 'Bitte eine gültige URL eingeben.'}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Card>
            </Col>
        </Row>

    )
}

export default QuestionEditorAdditionalInfo
