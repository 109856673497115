import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Router } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { history } from './utils/history';
import MainLayout from './layout/MainLayout'

import { Login } from './pages/login/login.form';
import logo from './images/RhAppFox.svg';
import './App.css';

// React-Query
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'

//import locale from "antd/es/date-picker/locale/de_DE";
import locale from 'antd/lib/locale/de_DE';

const queryClient = new QueryClient();

function App() {

    const isLoggedIn = useSelector(state => state.auth?.isLoggedIn || false);
    function handleRefresh() {
        //        const user = JSON.parse(localStorage.getItem("user"));
        console.log("handleRefresh");
    }

    if (isLoggedIn) {
        return (
            <ConfigProvider locale={locale}>
                <Router history={history}>
                    <QueryClientProvider client={queryClient}>
                            <MainLayout updateHandler={handleRefresh}/>
                            <ReactQueryDevtools initialIsOpen={false}/>
                    </QueryClientProvider>
                </Router>
            </ConfigProvider>
        )
    } else {
        return (
            <Router history={history}>
                <div className="App">
                    <header className="App-header">
                        <img src={logo} className="App-logo" alt="logo"/>
                        <Login/>
                    </header>
                </div>
            </Router>
        );
    }
}


export default App;
