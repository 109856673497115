import React, {useEffect} from "react";
import {useState} from "react";
import {useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import {Button, Form, Card, Col, Divider, Input, Modal, Space, Row, Spin, Table, Popconfirm, message, Tag} from "antd";
import {CheckOutlined, CrownOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import RAQAClient from '../../network/api/RaqaClient';
import RaqaClient from "../../network/api/RaqaClient";
import PlayerTagList from "./components/player.tag.list";


const ContentGroupAddPlayerModal = ({open, onCancel, onSave, allPlayers, assignedPlayers, contentGroupId}) => {

    const [filteredPlayers, setFilteredPlayers] = useState(allPlayers);
    const [playersAndAssignedRowKeys, setPlayersAndAssignedRowKey] = useState({
        selectedPlayers: [],
        assignedRowKeys: []
    })
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [searchString, setSearchString] = useState("");

    const [form] = Form.useForm();

    useEffect(() => {
        setFilteredPlayers(allPlayers);
    }, [allPlayers]);

    useEffect(() => {
        const searchResult = allPlayers.filter(player => player.lastName.toLowerCase().includes(searchString.toLowerCase()) || player.firstName.toLowerCase().includes(searchString.toLowerCase()))
        setFilteredPlayers(searchResult);

        // Wenn der Search String leer ist
        const rowKeysFromPlayers =  playersAndAssignedRowKeys.selectedPlayers.map((player) => player.id);
        setPlayersAndAssignedRowKey({selectedPlayers: playersAndAssignedRowKeys.selectedPlayers, assignedRowKeys: rowKeysFromPlayers})

    }, [searchString]);


    const handleOk = async () => {

         let playerIds = playersAndAssignedRowKeys.selectedPlayers.map(player => player.id);

         if (playerIds.length > 0) {
             try {
                 const response = await RAQAClient.addPlayersToContentGroup(contentGroupId, playerIds);
                 if (response.status === 200) {
                     message.info("Zuweisung der Spieler erfolgreich");
                 } else {
                     clearSelectionRowsInTables();
                     message.error("Zuweisung der Spieler fehlgeschlagen");
                     console.log("Fehler bei Zuweisen neuer Spieler, Response Status: ", response.status)
                 }
             } catch (error) {
                 message.error("Zuweisung der Spieler fehlgeschlagen");
                 console.log("Fehler bei Zuweisen neuer Spieler, Response Status: ", error)
             } finally {
                 clearSelectionRowsInTables();
                 onSave();
             }

         } else {
             onSave();
             clearSelectionRowsInTables();
         }
    };

    const handleCancel = () => {
        console.log("Modal cancelled with cancel button");
        clearSelectionRowsInTables();
        form.resetFields();
        onCancel();
    };

    function clearSelectionRowsInTables() {
        setPlayersAndAssignedRowKey({selectedPlayers: [], assignedRowKeys: []})
        setOkButtonDisabled(true);
        setSearchString("");
    }


    const rowSelectionAssigned = {
        selectedRowKeys: playersAndAssignedRowKeys.assignedRowKeys,
        onChange: (selectedRowKeys, rows) => {
            console.log("==> playersAndAssignedRowKeys.assignedRowKeys", playersAndAssignedRowKeys.assignedRowKeys);
            console.log("==> selectedPlayers", playersAndAssignedRowKeys.selectedPlayers);
            console.log("-");
            console.log("==> rowSelectionAssigned selectedRowKeys", selectedRowKeys);
            console.log("==> rowSelectionAssigned rows", rows);
            // Enable or disable the save button
            if (selectedRowKeys.length > 0) {
                setOkButtonDisabled(false);
            } else {
                setOkButtonDisabled(true);
            }

            // We have to check here if the result list is a search based result list
            // If true we have to union the existing results with the selected itmes in the search filtered list
            if(filteredPlayers.length !== allPlayers.length || searchString.length>2) {
                // Get all players which are NOT selected from filteredSearch
                const unselectedPlayers = filteredPlayers.filter((item) => !rows.includes(item));
                // Now remove the players from already selectedPlayers
                const currentPlayerMinusUnselected = playersAndAssignedRowKeys.selectedPlayers.filter((item) => !unselectedPlayers.includes(item));
                // Now add the selected rows to the new array
                const unionSelectedPlayers = [...new Set([...currentPlayerMinusUnselected, ...rows])];
                setPlayersAndAssignedRowKey({selectedPlayers: [...unionSelectedPlayers], assignedRowKeys: selectedRowKeys})
            }
            else {
                setPlayersAndAssignedRowKey({selectedPlayers: [...rows], assignedRowKeys: selectedRowKeys})
            }
        },
        hideSelectAll: true,

    };

    const columns = [
        {title: 'Vorname', dataIndex: 'firstName'},
        {title: 'Nachname', dataIndex: 'lastName'},
        {title: 'E-Mail', dataIndex: 'email'},
        {
            title: 'Letzter Login', dataIndex: 'lastLoginAt', render: (loginTimestamp, index) => {
                if (loginTimestamp === null) {
                    return "Nicht vorhanden"
                }
                const lastLoginAt = new Date(loginTimestamp * 1000);
                const result = lastLoginAt.toLocaleDateString("de-DE", { // you can use undefined as first argument
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                });
                return result + " Uhr"
            }
        },
        {
            title: 'Account seit', dataIndex: 'createdAt', render: (createdAtTimestamp, index) => {
                if (createdAtTimestamp === null) {
                    return "Nicht vorhanden"
                }
                const lastLoginAt = new Date(createdAtTimestamp * 1000);
                const result = lastLoginAt.toLocaleDateString("de-DE", { // you can use undefined as first argument
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });
                return result
            }
        },
    ];

    const onSearchChange = (e) => {
        const inputText = e.target.value;

        if (inputText.length > 2) {
            setSearchString(inputText);
        } else {
            setFilteredPlayers(allPlayers);
            setSearchString("");
        }
    }

    console.log("Search String: ", searchString);
    return (
        <>
            <Modal title="Player hinzufügen"
                   width="60%"
                   style={{height: "90%"}}
                   open={open}
                   okButtonProps={{disabled: okButtonDisabled}}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   okText="Spieler übernehmen"
            >

                <Form
                    form={form}
                    initialValues={{ searchString: "" }}
                >
                    <Form.Item
                        label="Suche nach Namen:"
                        name="searchString"
                    >
                    <Input
                        placeholder="Nach Namen suchen (mind. 3 Zeichen)"
                        onChange={onSearchChange}
                        allowClear
                    />
                    </Form.Item>
                </Form>
                <div style={{"marginBottom": "20px"}}/>
                {playersAndAssignedRowKeys.selectedPlayers.length>0 ? <h4>Hinzugefügte Spieler</h4> : <></>}

                <PlayerTagList
                    onRemove={(item) => {
                        console.log("Tag removed", item);
                        console.log("rowSelectionAssigned", rowSelectionAssigned);
                        console.log("playersAndAssignedRowKeys.selectedPlayers", playersAndAssignedRowKeys.selectedPlayers);

                        const newRowSelection = rowSelectionAssigned.selectedRowKeys.filter((player) => player !== item);
                        const newPlayers = playersAndAssignedRowKeys.selectedPlayers.filter((player) => player.id !== item);

                        console.log("newRowSelection", newRowSelection);
                        setPlayersAndAssignedRowKey({selectedPlayers: newPlayers, assignedRowKeys: newRowSelection})

                    }}
                    addedPlayersList={playersAndAssignedRowKeys.selectedPlayers}
                />
                <h4>{filteredPlayers.length } Spieler</h4>

                <Table size="small"
                       columns={columns}
                       rowSelection={rowSelectionAssigned}
                       dataSource={filteredPlayers}
                       pagination={{
                           defaultPageSize: 10,
                           showSizeChanger: false,
                       }}
                       rowKey={(record) => record.id}

                />

            </Modal>
        </>
    );
};

export default ContentGroupAddPlayerModal;
