import React from "react";
import { useState } from "react";
import {useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import {Button, Form, Card, Col, Divider, Input, Modal, Space, Row, Spin, Table} from "antd";
import {CheckOutlined, DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons";
import RAQAClient from '../../network/api/RaqaClient';


const ContentGroupCreateModal = ({open, onCancel, onSave}) => {

    const [form] = Form.useForm();
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);

    const handleOk = (formData) => {
        onSave(formData);
    };

    const handleCancel = () => {
        onCancel();
    };

    const onFormValuesChange = (changedValues, allValues) => {
        const { title } = changedValues;
        const titleLen = title?.length || 0;
        setOkButtonDisabled(titleLen === 0);
    };

    return (
        <>
            <Modal title="Lerngruppe erstellen" 
                open={open}
                okButtonProps={{disabled: okButtonDisabled}}
                onOk={() => {form.submit()}} 
                onCancel={handleCancel}>
                <Form
                    labelCol={{span: 4}}
                    wrapperCol={{span: 20}}
                    form={form}
                    onFinish={handleOk}
                    onValuesChange={onFormValuesChange}
                    //initialValues={contentgroup}
                >
                    <Form.Item name={"title"} label={"Name"}>
                        <Input placeholder="Namen hier eingeben" allowClear />
                    </Form.Item>

                </Form>
            </Modal>
        </>
     );
};

export default ContentGroupCreateModal;