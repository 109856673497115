import CatalogAdminList from "./catalog.admin.list";
import {useDispatch} from "react-redux";
import {useQuery, useQueryClient} from "react-query";
import RAQAClient from "../../../network/api/RaqaClient";
import CatalogEditorsList from "./catalog.editors.list";

const CatalogAllView = () => {

    // Redux useDispatch, used for setting catalog state
    const dispatch = useDispatch();

    // Query Client
    const queryClient = useQueryClient()
    const { data, isLoading, isSuccess, error } = useQuery("catalogs", () => fetchCatalogs());

    // -- Async API methods -------------------------------------

    const fetchCatalogs = async () => {

        const response = await RAQAClient.listCatalogs();
        console.log("Response Catalogs" , response)
        const catalogs = response.data || [];

        dispatch({
            type: "SET_CATALOGS",
            payload: catalogs,
        });

        return catalogs.map(u => {
            u.key = u.uuid;
            u.numberQuestions = u.questionUuids.length;
            return u;
        });
    }

    return (
        <div>
            <CatalogAdminList/>
            <CatalogEditorsList/>
        </div>

    )
}

export default CatalogAllView;