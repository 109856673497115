import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Col, Divider,
    Form,
    Input,
    Modal,
    Result,
    Row,
    Space
} from 'antd';


import RAQAClient from '../../../network/api/RaqaClient';
import {useLocation} from "react-router-dom";
import CatalogCardListView from "../../../components/catalog/CatalogCardListView";
import {useSelector} from "react-redux";

const EditorForm = ({history, match}) => {

    // Get parameter if set with push-history
    // Need when a question should be created for catalog
    // Catalog is set in useEffect
    const location = useLocation();

    // ID of the user to update
    let userId = undefined;

    // Get the form-components
    const [form] = Form.useForm();

    const [emailAddress, setEMailAddress] = useState("");

    // Redux editors
    const reduxEditors = useSelector(state => state.administration?.editors);

    // Get the parameter URL values and set isAddMode to true
    let isAddMode = true;
    //const [emailAddress, setEmailAddress] = useState();

    if (location.state !== undefined) {
        //setEmailAddress(location.state.editor.email);
        isAddMode = false;
        console.log("Location object: ", location.state);
        userId = location.state.editor.id;

        form.setFieldsValue({
            firstName: [location.state.editor.firstName],
            lastName: [location.state.editor.lastName],
            email: [location.state.editor.email],
            username: [location.state.editor.username]
        })
    }

    const pageHeaderTitle = isAddMode ? "Neuen Editor erstellen" : "Editor bearbeiten";
    const [isLoading, setLoading] = useState(false)
    const [didUpload, setUploadState] = useState(false)

    useEffect(() => {
        if (isAddMode) {
            form.resetFields();
        }
        else {
            setEMailAddress(location.state.editor.email);
        }
    },[]);


    // HANDLERS
    // User clicked the 'edit' link of a questions
    function onClickUserList() {
        console.log('onClickUserList');
        history.push(`/editor-list`);
    }

    function newClickNewEditor() {
        form.resetFields();
        setLoading(false);
        setUploadState(false);
    }

    const onFinish = (values: any) => {
        console.log("Editor values: ", values);

        if (isAddMode) {

            // Create the JSON object for the API Call
            const newEditorUser = {
                username: values.username,
                role: "admin",
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                password: values.password,
            }

            RAQAClient.createEditor(newEditorUser).then(response => {
                    setLoading(false);
                    setUploadState(true);
                }
            )
                .catch(error => {
                    setLoading(false);
                    Modal.error({
                        title: 'Fehler beim Speichern',
                        content: `Der Datensatz konnte nicht gespeichert werden`,
                    });
                    console.log("Catch error");
                })
        } else {
            const updatedUser = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email
            }

            console.log("Updating User ID: ", userId);
            console.log("With values: ", updatedUser);

            RAQAClient.updateUser(userId, updatedUser).then(response => {
                    setLoading(false);
                    setUploadState(true);
                }
            )
                .catch(error => {
                    setLoading(false);
                    Modal.error({
                        title: 'Fehler beim Speichern',
                        content: `Der Datensatz konnte nicht gespeichert werden`,
                    });
                    console.log("Catch error");
                })
        }
    }

    if (didUpload) {
        return (
            <Result
                status="success"
                title="Änderungen durchgeführt!"
                subTitle="Die von von Ihnen durchgeführten Änderungen wurden am Server gespeichert und der der Katalog wurde erfolgreich aktualisiert."
                extra={[

                    <Button type="primary" key="console" onClick={newClickNewEditor}>
                        Neuen Editor anlegen
                    </Button>,

                    <Button key="list" onClick={e => {
                        e.stopPropagation();
                        onClickUserList();
                    }}>Zur Liste</Button>,
                ]}
            />
        )
    }


    return (

        <div>
            <Card>
                <Form
                    form={form}
                    name="editor"
                    layout="vertical"
                    autoComplete="off"
                    onFinish={onFinish}
                    fields={[
                        {
                            name: ['email'],
                            value: emailAddress,
                        },
                    ]}

                >
                    <fieldset disabled={isLoading}>

                        <h1 style={{paddingBottom: 20, fontSize: 32}}>{pageHeaderTitle}</h1>

                        <Input.Group size="normal">
                            <Row gutter={12}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Vorname"
                                        name="firstName"
                                        rules={[{required: true, message: 'Bitte geben Sie einen Vornamen ein'}]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="Nachname"
                                        name="lastName"
                                        rules={[{required: true, message: 'Bitte geben Sie einen Nachnamen ein'}]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Input.Group>


                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item
                                    name="username"
                                    label="Benutzername"
                                    rules={[

                                        {
                                            required: true,
                                            message: 'Bitte geben Sie einen Nutzernamen ein',
                                        },
                                        ({getFieldValue}) => ({
                                            validator(rule, value) {
                                                if (reduxEditors.filter(e => e.username === value && e.id !== userId).length > 0) {
                                                    return Promise.reject("Dieser Username wird bereits verwendet")
                                                } else {
                                                    return Promise.resolve()
                                                }
                                            }
                                        })
                                    ]}
                                >
                                    <Input disabled={!isAddMode}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="email"
                                    label="E-Mail"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Bitte geben Sie eine E-Mail Adresse ein',
                                        },
                                        {
                                            type: 'email',
                                            message: 'Die eingegebene E-Mail ist keine gültige E-Mail Adresse',
                                        },
                                        ({getFieldValue}) => ({
                                            validator(rule, value) {
                                                if (reduxEditors.filter(e => e.email === value && e.id !== userId).length > 0) {
                                                    return Promise.reject("Diese E-Mail Adresse wird bereits verwendet")
                                                } else {
                                                    return Promise.resolve()
                                                }
                                            }
                                        })
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                        {isAddMode &&
                        <Input.Group size="normal">
                            <Row gutter={12}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Passwort"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Bitte geben Sie ein Passwort ein',
                                            },
                                            {
                                                min: 4,
                                                message: 'Passwort muss mindestens 4 Zeichen haben'
                                            },
                                        ]}
                                    >
                                        <Input.Password disabled={!isAddMode} placeholder="Passwort eingeben"/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="confirm"
                                        label="Passwort bestätigen"
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Bitte bestätigen Sie das Passwort!',
                                            },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Die beiden Passwörter sind nicht identisch'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Input.Group>

                        }
                        <Divider/>
                        <Row
                            justify="center"
                            align="right"
                            gutter={10}
                        >
                            <Col span={12} align="left">
                                <Space size="large">
                                    <Button key="buttonSave" type="primary" htmlType="submit">
                                        Editor speichern
                                    </Button>
                                </Space>
                            </Col>

                            <Col span={12} align="right">
                                <Space size="large">
                                    <Button key="buttonList"
                                            type="secondary" onClick={e => {
                                        e.stopPropagation();
                                        onClickUserList();
                                    }}>
                                        Zur Liste
                                    </Button>

                                </Space>
                            </Col>

                        </Row>
                    </fieldset>
                </Form>
            </Card>
            {isAddMode === false &&
            <div>
                <div style={{paddingBottom: 45}}/>
                <h2>Vorhandene Fragenkataloge</h2>
                <div style={{paddingBottom: 10}}/>
                <CatalogCardListView catalogs={location.state.editor.catalogs} editor={location.state.editor}/>
            </div>
            }

        </div>

    );
}


export default EditorForm;
