import { Button, Row, Col, Descriptions, Divider, PageHeader, Modal } from "antd";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import { useQuery } from "react-query";
import RAQAClient from "../../../network/api/RaqaClient";
import UserDetailView from "../../../components/user/UserDetailView";
import CatalogInputModal from "../catalog/components/CatalogInputModal";
import PasswordChangeModal from "../../../components/user/PasswordChangeModal";
import DifficultQuestionsTable from "../../../components/question/DifficultQuestionsTable";

const ProfileAdminView = (props) => {

    const user = useSelector(state => state.auth?.user.user);

    // useQuery Hook
    const { data: stats, refetch, isLoading, isSuccess, error } = useQuery(['stats'],
        () => fetchStatistics());

    // -- MODAL HANDLING ----------------------------------------
    const [inputModalVisible, setInputModalVisible] = useState(false);

    const handleInputModalSuccess = () => {
        setInputModalVisible(false);

        Modal.success({
            title: "Passwort erfolgreich geändert",
            content: "Das Passwort wurde erfolgreich geändert",
            onOK: () => {
                Modal.destroyAll();
            }
        });
    };

    const handleInputModalError = (values) => {
        Modal.error({
            title: 'Fehler beim Speichern',
            content: `Das Passwort konnte nicht geändert werden`,
        });
        setInputModalVisible(false);
    };

    const handleInputModalCancelled = () => {
        console.log("handleInputModalCanceled");
        setInputModalVisible(false);
    }

    const handlePasswordChangeButtonClicked = () => {
        console.log("Create catalog clicked !");
        setInputModalVisible(true);
    }

    const fetchStatistics = async () => {
        return RAQAClient.getStatistics()
            .then(response => {
                console.log("Stats", response)
                return response.data;
            }).catch(error => {
                console.log("Error", error);
            });
    };

    return (
        <div>

            <Row>
                <Col span={18}>
                    <h2>Profildaten</h2>
                </Col>
                <Col style={{ textAlign: "right" }} span={6}>
                    <Button key="1" type="primary" onClick={() => handlePasswordChangeButtonClicked()}>Passwort ändern</Button>
                </Col>
            </Row>

            <UserDetailView user={user} />

            <div style={{ paddingBottom: 10 }} />
            <Divider dashed />
            <div style={{ paddingBottom: 10 }} />

            {stats !== undefined &&

                <div>
                    <h2>Fragen und Kataloge</h2>

                    <Descriptions labelStyle={{ fontSize: 15, fontWeight: 500, marginBottom: -20 }}
                        contentStyle={{ fontSize: 15, fontWeight: 300 }}
                        layout="vertical"
                        size="medium"
                        bordered={true}
                        column={4}
                    >
                        <Descriptions.Item label="Fragen insgesamt">{stats.questionsTotal}</Descriptions.Item>
                        <Descriptions.Item label="Fragen unveröffentlicht">{stats.unpublishedQuestions}</Descriptions.Item>
                        <Descriptions.Item label="Kataloge insgesamt">{stats.catalogsTotal}</Descriptions.Item>
                        <Descriptions.Item label="Kataloge unveröffentlicht">{stats.unpublishedCatalogs}</Descriptions.Item>

                    </Descriptions>
                </div>
            }

            <div style={{ paddingBottom: 10 }} />
            <Divider dashed />
            <div style={{ paddingBottom: 10 }} />

            <PasswordChangeModal
                visible={inputModalVisible}
                onSuccess={handleInputModalSuccess}
                onError={handleInputModalError}
                onCancel={handleInputModalCancelled}
            />

        </div>
    )
}

export default ProfileAdminView;
